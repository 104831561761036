import React from "react";
import styles from "./MultiplePickerOptionList.module.scss";
import PickerOption, {
  PickerOptionData,
  PickerOptionMode,
} from "../PickerOption/PickerOption";
import _ from "lodash";

export interface MultiplePickerOptionListProps<T> {
  options: PickerOptionData<T>[];
  selectedIndices: number[];
  setSelectedIndices: (indices: number[]) => void;
  noOptionsText?: string;
  extraPadding?: boolean;
  noPadding?: boolean;
  mode?: PickerOptionMode.V | PickerOptionMode.CHECKBOX;
}

export default function MultiplePickerOptionList<T>(
  props: MultiplePickerOptionListProps<T>,
) {
  if (props.options.length === 0) {
    return <div className={styles.noOptions}>{props.noOptionsText}</div>;
  }

  const isSelected = (option: PickerOptionData<T>) =>
    _.find(
      props.selectedIndices,
      (index) => props.options[index].value === option.value,
    ) !== undefined;

  const onClick = (clickedOption: PickerOptionData<T>) => {
    const res: number[] = [];
    props.options.forEach((option, index) => {
      let selected: boolean;
      if (option.value === clickedOption.value) {
        selected = !isSelected(option);
      } else {
        selected = isSelected(option);
      }

      if (selected) {
        res.push(index);
      }
    });

    props.setSelectedIndices(res);
  };

  return (
    <div className={styles.options}>
      {props.options.map((option) => {
        return (
          <PickerOption
            key={option.key}
            option={option}
            onSelected={() => onClick(option)}
            selected={isSelected(option)}
            extraPadding={props.extraPadding}
            noPadding={props.noPadding}
            mode={props.mode}
          />
        );
      })}
    </div>
  );
}
