"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockNow = exports.getDaysUntilWarrantyVoids = exports.getMonthsUntilWarrantyVoids = exports.hasItemExpired = exports.getWarrantyNotificationDateMillis = exports.getWarrantyVoidDateMillis = exports.formatDate = exports.formatMillis = exports.getStartOfDay = exports.Today = void 0;
const types_1 = require("../types");
const luxon_1 = require("luxon");
let now = () => luxon_1.DateTime.now();
function Today() {
    return now().startOf("day");
}
exports.Today = Today;
function getStartOfDay(date) {
    return luxon_1.DateTime.fromJSDate(date).startOf("day").toMillis();
}
exports.getStartOfDay = getStartOfDay;
function formatMillis(millis, dateFormat, fullYear = false) {
    if (!millis) {
        return "";
    }
    const date = luxon_1.DateTime.fromMillis(millis);
    return formatDate(date, dateFormat, fullYear);
}
exports.formatMillis = formatMillis;
function formatDate(date, dateFormat, fullYear = false) {
    if (!date) {
        return "";
    }
    let format = dateFormat === types_1.DateFormat.MMDDYYYY ? "M/d/yy" : "d/M/yy";
    if (fullYear) {
        format += "yy";
    }
    return date.toFormat(format);
}
exports.formatDate = formatDate;
function getWarrantyVoidDateMillis(purchaseDateMillis, timezone, warrantyMonths) {
    return luxon_1.DateTime.fromMillis(purchaseDateMillis)
        .setZone(timezone)
        .startOf("day")
        .plus({ months: warrantyMonths })
        .toMillis();
}
exports.getWarrantyVoidDateMillis = getWarrantyVoidDateMillis;
function getWarrantyNotificationDateMillis(purchaseDateMillis, warrantyMonths, timezone, notificationDays) {
    return luxon_1.DateTime.fromMillis(getWarrantyVoidDateMillis(purchaseDateMillis, timezone, warrantyMonths))
        .setZone(timezone)
        .minus({ day: notificationDays })
        .toMillis();
}
exports.getWarrantyNotificationDateMillis = getWarrantyNotificationDateMillis;
function hasItemExpired(item) {
    return item.warrantyVoidsDateMillis < Today().toMillis();
}
exports.hasItemExpired = hasItemExpired;
function getMonthsUntilWarrantyVoids(warrantyVoidDateMillis) {
    const voidDate = luxon_1.DateTime.fromMillis(warrantyVoidDateMillis);
    const interval = luxon_1.Interval.fromDateTimes(Today(), voidDate);
    return Math.floor(interval.length("month"));
}
exports.getMonthsUntilWarrantyVoids = getMonthsUntilWarrantyVoids;
function getDaysUntilWarrantyVoids(warrantyVoidDateMillis) {
    const voidDate = luxon_1.DateTime.fromMillis(warrantyVoidDateMillis);
    const interval = luxon_1.Interval.fromDateTimes(Today(), voidDate);
    return Math.floor(interval.length("day"));
}
exports.getDaysUntilWarrantyVoids = getDaysUntilWarrantyVoids;
function mockNow(year, month, day, hour, minute, second) {
    now = () => luxon_1.DateTime.fromObject({ year, month, day, hour, minute, second });
}
exports.mockNow = mockNow;
