import { useState } from "react";
import styles from "./ResetPasswordScreen.module.scss";
import authStyles from "./Authentication.module.css";
import { Input } from "../../components/basic/Input";
import Button from "../../components/basic/Button";
import Screen from "../../components/basic/Screen/Screen";
import { ReactComponent as EnvelopeIcon } from "../../assets/icons/envelope.svg";
import TextButton from "../../components/basic/TextButton";
import { Variant } from "@warranty-keeper/common";
import Illustration from "../../components/basic/Illustration/Illustration";
import ResetEmailLight from "../../assets/illustrations/reset-password-light.svg";
import ResetEmailDark from "../../assets/illustrations/reset-password-dark.svg";
import ResetEmailSuccessLight from "../../assets/illustrations/reset-password-success-light.svg";
import ResetEmailSuccessDark from "../../assets/illustrations/reset-password-success-dark.svg";
import { translate } from "../../locale/Locale";

export interface ResetPasswordScreenProps {
  onResetPassword: (
    email: string,
    onSuccess: () => void,
    setError: (message: string) => void,
  ) => void;
  goBack: () => void;
}

export default function ResetPasswordScreen(props: ResetPasswordScreenProps) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onEmailError = (emailError: string) => {
    setError(emailError);
    setIsLoading(false);
    setSuccess(false);
  };

  const onSuccess = () => {
    setIsLoading(false);
    setSuccess(true);
  };

  const onResetPassword = () => {
    const e = email.trim();
    if (!e) {
      onEmailError(translate("authenticationErrors.invalidEmail"));
      return;
    }

    setIsLoading(true);
    props.onResetPassword(e, onSuccess, onEmailError);
  };

  if (success) {
    return (
      <div className={styles.successScreenContainer}>
        <Illustration
          lightPath={ResetEmailSuccessLight}
          darkPath={ResetEmailSuccessDark}
        />

        <div className={styles.successTitle}>
          {translate("signIn.checkEmail")}
        </div>
        <div className={styles.successSubtitle}>
          {translate("signIn.weSentInstructions")}
        </div>

        <Button
          className={styles.continueButton}
          title={translate("common.goBack")}
          onClick={props.goBack}
        />
      </div>
    );
  }

  return (
    <Screen headerTitle={translate("signIn.resetPassword")}>
      <div className={authStyles.screenContainer}>
        <div className={styles.subtitle}>
          {translate("signIn.resetPasswordDetails")}
        </div>

        <div className={styles.image}>
          <Illustration lightPath={ResetEmailLight} darkPath={ResetEmailDark} />
        </div>

        <div className={styles.content}>
          <div className={styles.inputField}>
            <Input
              label={translate("common.email")}
              value={email}
              onChangeText={(email: string) => {
                setEmail(email);
                setError("");
                setSuccess(false);
              }}
              error={error}
              onSubmit={onResetPassword}
              type="email"
              icon={<EnvelopeIcon className={styles.icon} />}
            />
          </div>

          <Button
            className={authStyles.actionButton}
            title={translate("signIn.resetPassword")}
            onClick={onResetPassword}
            loading={isLoading}
            analyticTag={"resetPassword"}
          />

          <div className={authStyles.alternativeText}>
            {translate("signIn.knowYourPassword") + " "}
            <TextButton
              title={translate("signIn.signIn")}
              variant={Variant.Primary}
              onClick={props.goBack}
              className={authStyles.alternativeButton}
            />
          </div>
        </div>
      </div>
    </Screen>
  );
}
