import { useState } from "react";
import Screen from "../../components/basic/Screen/Screen";
import styles from "./AccountSettingsScreen.module.css";
import { Input } from "../../components/basic/Input";
import { ReactComponent as PersonIcon } from "../../assets/icons/person.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import Button from "../../components/basic/Button";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { useNavigate } from "react-router-dom";
import { isPrivateAppleUser } from "../../firebase/AuthenticationUtils";
import { StringUtils, Variant } from "@warranty-keeper/common";
import Popup from "../../components/basic/Popup";
import ErrorLight from "../../assets/illustrations/error-light.svg";
import ErrorDark from "../../assets/illustrations/error-dark.svg";
import Illustration from "../../components/basic/Illustration/Illustration";
import { reportClick } from "../../firebase/FirebaseAnalytics";
import { translate } from "../../locale/Locale";

export interface AccountSettingsScreenProps {
  name: string;
  email: string;
  updateName: (newName: string, onError: (e: string) => void) => void;
  updatePasswordEnabled: boolean;
  updatePassword: (
    oldPassword: string,
    newPassword: string,
    onSuccess: () => void,
    currentPasswordError: (message: string) => void,
    newPasswordError: (message: string) => void,
  ) => void;
  onDeleteAccount: () => void;
}

export default function AccountSettingsScreen(
  props: AccountSettingsScreenProps,
) {
  const [name, setName] = useState(props.name);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [nameError, setNameError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteAccountPopupOpen, setDeleteAccountPopupOpen] = useState(false);

  const navigate = useNavigate();

  const onSave = () => {
    if (!StringUtils.isEmptyString(name) && name !== props.name) {
      props.updateName(name.trim(), setNameError);
    }

    clearPasswordErrors();
    if (!currentPassword && !password && !confirmedPassword) {
      return;
    }

    if (!currentPassword) {
      setCurrentPasswordError(translate("common.required"));
      return;
    }

    if (!password) {
      setPasswordError(translate("common.required"));
      return;
    }

    if (!confirmedPassword) {
      setConfirmPasswordError(translate("common.required"));
      return;
    }

    if (password !== confirmedPassword) {
      setConfirmPasswordError(
        translate("authenticationErrors.passwordsDoNotMatch"),
      );
      return;
    }

    setIsLoading(true);
    props.updatePassword(
      currentPassword,
      password,
      () => navigate(-1),
      (error) => {
        setCurrentPasswordError(error);
        setIsLoading(false);
      },
      (error) => {
        setConfirmPasswordError(error);
        setIsLoading(false);
      },
    );
  };

  const clearPasswordErrors = () => {
    setCurrentPasswordError("");
    setPasswordError("");
    setConfirmPasswordError("");
  };

  const showEmail = !isPrivateAppleUser(props.email);

  return (
    <Screen headerTitle={translate("account.account")}>
      <div className={styles.screen}>
        {showEmail && <div className={styles.email}>{props.email}</div>}

        <Input
          label={translate("common.name")}
          value={name}
          onChangeText={(name) => {
            setNameError("");
            setName(name);
          }}
          error={nameError}
          icon={<PersonIcon className={styles.personIcon} />}
        />

        {props.updatePasswordEnabled && (
          <>
            <div className={styles.updatePasswordTitle}>
              {translate("account.updatePassword")}
            </div>

            <Input
              label={translate("account.currentPassword")}
              value={currentPassword}
              onChangeText={(password: string) => {
                clearPasswordErrors();
                setCurrentPassword(password);
              }}
              type="password"
              error={currentPasswordError}
              icon={<LockIcon className={styles.lockIcon} />}
            />

            <Input
              label={translate("account.newPassword")}
              value={password}
              onChangeText={(password: string) => {
                clearPasswordErrors();
                setPassword(password);
              }}
              type="password"
              error={passwordError}
              icon={<LockIcon className={styles.lockIcon} />}
            />
            <Input
              label={translate("account.confirmPassword")}
              value={confirmedPassword}
              onChangeText={(confirmedPassword: string) => {
                clearPasswordErrors();
                setConfirmedPassword(confirmedPassword);
              }}
              type="password"
              error={confirmPasswordError}
              onSubmit={onSave}
              icon={<LockIcon className={styles.lockIcon} />}
            />
          </>
        )}

        <div className={styles.button}>
          <Button
            title={translate("common.save")}
            onClick={onSave}
            loading={isLoading}
            analyticTag={"account/save"}
          />
        </div>

        <div
          className={styles.deleteAccount}
          onClick={() => {
            if (!isLoading) {
              reportClick("account/deleteAccount");
              setDeleteAccountPopupOpen(true);
            }
          }}
        >
          <TrashIcon className={styles.deleteAccountIcon} />
          <div className={styles.deleteAccountText}>
            {translate("account.deleteAccount")}
          </div>
        </div>

        <Popup
          title={translate("account.deleteAccountExplenationTitle")}
          content={translate("account.deleteAccountExplenation")}
          open={deleteAccountPopupOpen}
          onClose={() => setDeleteAccountPopupOpen(false)}
          illustration={
            <Illustration lightPath={ErrorLight} darkPath={ErrorDark} />
          }
          buttons={[
            {
              label: translate("common.cancel"),
              variant: Variant.Info,
              onClick: () => setDeleteAccountPopupOpen(false),
            },
            {
              label: translate("common.delete"),
              variant: Variant.Primary,
              onClick: props.onDeleteAccount,
            },
          ]}
        />
      </div>
    </Screen>
  );
}
