import {
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  OAuthCredential,
  OAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { SocialSignInMethod } from "../components/SocialSignIn/SocialSignInButton";
import { logError, logWarning } from "../utils/ErrorUtils";
import { deleteFirebaseUser } from "./FirebaseAuthentication";
import { getPlatform, isNativePlatform } from "./UserFunctions";
import { auth } from "./FirebaseInitializer";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { reportSignUp } from "./FirebaseAnalytics";
import { translate } from "../locale/Locale";

export async function socialSignIn(
  method: SocialSignInMethod,
  setError: (message: string[]) => void,
  locale: string,
): Promise<void> {
  if (isNativePlatform()) {
    console.log("SSI", method);
  }
  auth.languageCode = locale;

  try {
    const userCredential = await signIn(method);
    if (!userCredential) {
      setError([translate("error.somethingWentWrong")]);
      return;
    }

    // User disabled their email permissions
    if (!userCredential.user?.email) {
      await deleteFirebaseUser();
      setError([
        translate("authenticationErrors.emailPermissionIsRequired"),
        translate("authenticationErrors.tryAgain"),
      ]);
      return;
    }

    reportSignUp(method);
  } catch (firebaseError) {
    if (
      firebaseError.code === "auth/popup-closed-by-user" ||
      firebaseError.message.startsWith("Sign in canceled")
    ) {
      setError([]);
      return;
    }
    if (
      firebaseError.code === "auth/account-exists-with-different-credential"
    ) {
      const email = firebaseError.customData.email;
      let methods: string[] = [];
      try {
        methods = await fetchSignInMethodsForEmail(auth, email);
      } catch (methodsFetchError) {
        logError(
          methodsFetchError,
          "Error fetching sign in methods for " + email,
        );
      }

      if (methods.length === 0) {
        setError([translate("error.somethingWentWrong")]);
        return;
      }

      let method: string;
      switch (methods[0]) {
        case "password":
          method = "Email and Password";
          break;
        case "google.com":
          method = "Google";
          break;
        case "facebook.com":
          method = "Facebook";
          break;
        case "apple.com":
          method = "Apple";
          break;
        default:
          logWarning("Unexpected previously logged in method: " + methods[0]);
          setError([translate("error.somethingWentWrong")]);
          return;
      }

      setError([
        translate("authenticationErrors.otherSocialAccountExists", {
          socialNetwork: method,
        }),
        translate("authenticationErrors.pleaseUseIt"),
      ]);
      return;
    }

    logError(
      firebaseError,
      "Error signing in to firebase: " + firebaseError.message,
    );
    setError([translate("error.somethingWentWrong")]);
  }
}

async function signIn(method: SocialSignInMethod) {
  // https://github.com/robingenz/capacitor-firebase-authentication/blob/main/docs/firebase-js-sdk.md
  let credential: OAuthCredential;
  switch (method) {
    case SocialSignInMethod.Google:
      const google = await FirebaseAuthentication.signInWithGoogle();
      credential = GoogleAuthProvider.credential(google.credential?.idToken);
      break;
    case SocialSignInMethod.Apple:
      const apple = await FirebaseAuthentication.signInWithApple();
      credential = OAuthProvider.credentialFromJSON({
        providerId: "apple.com",
        signInMethod: "apple.com",
        idToken: apple.credential?.idToken,
        nonce: apple.credential?.nonce,
        pendingToken: null,
      });
      break;

    default:
      throw new Error("Invalid social sign in method: " + method);
  }
  return await signInWithCredential(auth, credential);
}

export function appleLoginSupported() {
  return getPlatform() !== "android";
}
