import { CategoryKey, IState, IUser } from "@warranty-keeper/common";
import { getCategoryName } from "../../utils/CategoryUtil";
import { PickerOptionData } from "../../components/picker/PickerOption/PickerOption";

export function getUser(state: IState): IUser {
  return state.user;
}

export function getUserName(state: IState) {
  return getUser(state).name;
}

export function getEmail(state: IState) {
  return getUser(state).email;
}

export function getNotificationDays(state: IState) {
  return getUser(state).notificationDays;
}

export function getNotificationSettings(state: IState) {
  const settings = getUser(state).notificationSettings;
  return {
    pushNotificationsEnabled: settings.disablePushNotifications !== true,
    emailNotificationsEnabled: settings.disableEmailNotifications !== true,
  };
}

export function getGeneralSettings(state: IState) {
  return getUser(state).generalSettings;
}

export function getDefaultWarrantyMonths(state: IState) {
  return getGeneralSettings(state).defaultWarrantyMonths;
}

export function getFirstDayOfWeek(state: IState) {
  return getGeneralSettings(state).firstDayOfWeek;
}

export function getDateFormat(state: IState) {
  return getGeneralSettings(state).dateFormat;
}

export function getCategories(state: IState) {
  return getUser(state).categories;
}

export function getCategoryPickerOptions(
  state: IState,
): PickerOptionData<CategoryKey>[] {
  const categories = getUser(state).categories;
  return categories.map<PickerOptionData<CategoryKey>>((categoryKey) => {
    return {
      value: categoryKey,
      key: categoryKey,
      text: getCategoryName(categoryKey),
    };
  });
}

export function getSortingMethod(state: IState) {
  return getUser(state).sortingMethod;
}
