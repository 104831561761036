import * as UserFunctions from "../firebase/UserFunctions";
import { LocaleUtils } from "@warranty-keeper/common";
import * as FirebaseAnalytics from "../firebase/FirebaseAnalytics";
import { TranslateOptions } from "i18n-js";
// TODO When imported regularly, app tests fail.
// Check if there are updates here: https://github.com/fnando/i18n/issues/26
const { I18n } = require("i18n-js/dist/require/index");

// Visible for testing
export const WK_LOCALE_KEY = "WK_LOCALE_KEY";
const i18n = new I18n(LocaleUtils.localesObject);

export function translate(key: string, options?: TranslateOptions) {
  return i18n.t(key, options);
}

export const readLocaleFromMemory = () => {
  const locale = localStorage.getItem(WK_LOCALE_KEY);
  return locale || navigator.language;
};

export const selectLocale = (newLocale: string, initialRead: boolean) => {
  i18n.locale = newLocale;
  localStorage.setItem(WK_LOCALE_KEY, newLocale);
  if (!initialRead) {
    UserFunctions.updateUser({
      updatedLocale: newLocale,
    });
    FirebaseAnalytics.reportCustom(`updatedLocale-${newLocale}`);
  }
};

export function getTranslations() {
  return i18n.translations;
}

export function getCurrentLocale() {
  return i18n.locale;
}

export const getDirection = (locale: string) => {
  return isRTL(locale) ? "rtl" : "ltr";
};

export const isRTL = (locale: string) => {
  return locale.startsWith("he") || locale.startsWith("ar");
};

i18n.defaultLocale = "en-US";
i18n.locale = readLocaleFromMemory();
i18n.enableFallback = true;
// i18n.missingBehavior = "guess";
// i18n.missingTranslation = (scope: string) => {
//   logWarning("No translation found for " + scope + " in " + getCurrentLocale());
//   return "";
// };
