import React from "react";
import styles from "./PickerOption.module.scss";
import { ReactComponent as V } from "../../../assets/icons/V.svg";
import { logWarning } from "../../../utils/ErrorUtils";
import classNames from "classnames";
import Checkbox from "../../basic/Checkbox/Checkbox";
import { IconColorField } from "../../basic/IconButton/IconButton";
import { Icon } from "../../../utils/IconUtils";

export const PICKER_OPTION_HEIGHT = 62;

export enum PickerOptionMode {
  V,
  RADIO,
  CHECKBOX,
}

export type PickerOptionData<T> = {
  text: string;
  key: string;
  value: T;
  icon?: Icon;
  iconColorField?: IconColorField;
};

export interface PickerOptionProps<T> {
  option: PickerOptionData<T>;
  onSelected: () => void;
  selected?: boolean;
  mode?: PickerOptionMode;
  extraPadding?: boolean;
  noPadding?: boolean;
}

export default function PickerOption<T>(props: PickerOptionProps<T>) {
  const mode = props.mode || PickerOptionMode.V;

  let colorClass;
  switch (props.option.iconColorField) {
    case IconColorField.FILL:
      colorClass = styles.iconFill;
      break;
    case IconColorField.STROKE:
      colorClass = styles.iconStroke;
      break;

    default:
      colorClass = undefined;
      break;
  }
  const iconClasses = [colorClass, styles.icon];

  return (
    <div
      style={{ height: PICKER_OPTION_HEIGHT }}
      className={classNames({
        [styles.option]: true,
        [styles.extraPadding]: props.extraPadding,
        [styles.noPadding]: props.noPadding,
        [styles.selectedText]: props.selected,
      })}
      onClick={() => {
        props.onSelected();
      }}
    >
      <div className={styles.iconAndText}>
        {props.option.icon && (
          <props.option.icon className={classNames(iconClasses)} />
        )}

        {props.option.text}
      </div>

      <OptionRightIcon
        selected={props.selected}
        mode={mode}
        onSelected={props.onSelected}
      />
    </div>
  );
}

function OptionRightIcon(props: {
  selected?: boolean;
  mode: PickerOptionMode;
  onSelected: () => void;
}) {
  switch (props.mode) {
    case PickerOptionMode.V:
      if (props.selected) {
        return (
          <div className={styles.selectedV}>
            <V className={styles.V} />
          </div>
        );
      } else {
        return null;
      }

    case PickerOptionMode.RADIO:
      if (props.selected) {
        return <div className={styles.radioCircleSelected} />;
      } else {
        return <div className={styles.radioCircle} />;
      }

    case PickerOptionMode.CHECKBOX:
      return (
        <Checkbox checked={!!props.selected} onChange={props.onSelected} />
      );

    default:
      logWarning("Invalid picker option mode: " + props.mode);
      return null;
  }
}

export const pickerOptionContainerClassName = styles.option;
