import { MediaFileMethod, RawMediaFile } from "./MediaFileUtils";
import * as Storage from "../firebase/Storage";
import uuid from "uuid-js";
import { FileType, IReceipt } from "@warranty-keeper/common";

export async function uploadReceipts(
  itemId: string,
  files: RawMediaFile[],
): Promise<IReceipt[]> {
  if (files.length === 0) {
    return [];
  }

  if (files[0].method === MediaFileMethod.UPLOAD_PDF) {
    const pdfUrl = await Storage.uploadPdf(itemId, files[0].localUri);
    return [
      {
        receiptUrl: pdfUrl,
        receiptType: FileType.PDF,
        fileName: itemId,
      },
    ];
  }

  // Receipts are images
  const uploadPromises = files.map(async (file) => {
    const fileName = getNextReceiptName(itemId);
    const url = await Storage.uploadImage(fileName, file.localUri);
    return {
      fileName,
      url,
    };
  });

  const uploads = await Promise.all(uploadPromises);
  return uploads.map<IReceipt>((upload) => ({
    receiptUrl: upload.url,
    receiptType: FileType.Image,
    fileName: upload.fileName,
  }));
}

function getNextReceiptName(itemId: string) {
  return itemId + "___" + uuid.create(4).toString();
}
