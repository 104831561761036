import { getNotificationDays, getNotificationSettings } from "../Selectors";
import * as UserFunctions from "../../../firebase/UserFunctions";
import * as actionTypes from "../ActionTypes";
import { IDispatch, IGetState } from "../../../types";
import { reportCustom } from "../../../firebase/FirebaseAnalytics";

export function updateNotificationSettings(
  notificationDays: number,
  enablePushNotifications: boolean,
  enableEmailNotifications: boolean,
) {
  return function (dispatch: IDispatch, getState: IGetState) {
    const settings = getNotificationSettings(getState());

    if (
      getNotificationDays(getState()) === notificationDays &&
      settings.pushNotificationsEnabled === enablePushNotifications &&
      settings.emailNotificationsEnabled === enableEmailNotifications
    ) {
      return;
    }

    if (getNotificationDays(getState()) !== notificationDays) {
      reportCustom(`updatedNotificationDays-${notificationDays}`);
    }
    if (settings.pushNotificationsEnabled !== enablePushNotifications) {
      reportCustom(`updatedEnablePushNotifications-${enablePushNotifications}`);
    }
    if (settings.emailNotificationsEnabled !== enableEmailNotifications) {
      reportCustom(
        `updated#nableEmailNotifications-${enableEmailNotifications}`,
      );
    }

    dispatch(
      updateNotificationDaysAction(
        notificationDays,
        enablePushNotifications,
        enableEmailNotifications,
      ),
    );
    UserFunctions.updateUser({
      updatedNotificationDays: notificationDays,
      disablePushNotifications: !enablePushNotifications,
      disableEmailNotifications: !enableEmailNotifications,
    });
  };
}

function updateNotificationDaysAction(
  notificationDays: number,
  enablePushNotifications: boolean,
  enableEmailNotifications: boolean,
) {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_SETTINGS,
    notificationDays: notificationDays,
    notificationSettings: {
      disablePushNotifications: !enablePushNotifications,
      disableEmailNotifications: !enableEmailNotifications,
    },
  };
}
