import React from "react";
import styles from "./style.module.scss";
import InputWrapper from "./InputWrapper";

export interface MultiLineInputProps {
  value?: string;
  label?: string;
  onChangeText: (text: string) => void;
  placeholder?: string;
  error?: string;
  extraLabel?: string;
  maxLength?: number;
  maxLines?: number;
}

const MIN_ROWS = 1;
const MAX_ROWS = 4;

export default function MultiLineInput({
  value,
  label,
  onChangeText,
  placeholder,
  error,
  extraLabel,
  maxLength,
  maxLines = MAX_ROWS,
}: MultiLineInputProps) {
  const [rows, setRows] = React.useState(MIN_ROWS);

  React.useEffect(() => {
    if (!value) {
      setRows(MIN_ROWS);
      return;
    }
    const rowLen = value.split("\n").length;
    if (rowLen > maxLines) {
      setRows(maxLines);
    } else {
      setRows(rowLen);
    }
  }, [value, maxLines]);

  const content = (
    <textarea
      data-test-id={"input-" + label}
      className={styles.input}
      value={value}
      onChange={(e) => onChangeText(e.target.value)}
      maxLength={maxLength}
      rows={rows}
      placeholder={placeholder}
      // For some reason, when tabIndex is positive, and we render OnlineImageSelector on top of EditItemFields (that has a Notes textarea),
      // We can't listen to ENTER clicked, and can't start a new image search when the search query changes.
      // Setting this tabIndex to -1 makes it so there's no other field to jump to when clicking ENTER, thus making the image search work again.
      tabIndex={-1}
    />
  );

  return (
    <InputWrapper label={label} error={error} extraLabel={extraLabel}>
      {content}
    </InputWrapper>
  );
}
