import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getCategoryPickerOptions,
  getDateFormat,
  getDefaultWarrantyMonths,
  getFirstDayOfWeek,
} from "../../store/user/Selectors";
import { CategoryKey } from "@warranty-keeper/common";
import {
  SaveItemArguments,
  saveNewItem,
} from "../../store/items/actions/SaveNewItemAction";
import NewItemScreen from "./NewItemScreen";
import { AppRoutes } from "../../router/Routes";
import { useNavigate } from "react-router-dom";

export default function NewItemScreenContainer() {
  const { categoryKey } = useParams<{ categoryKey?: CategoryKey }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categoryOptions = useSelector(getCategoryPickerOptions);
  const defaultWarrantyMonths = useSelector(getDefaultWarrantyMonths);
  const firstDayOfWeek = useSelector(getFirstDayOfWeek);
  const dateFormat = useSelector(getDateFormat);

  const onSave = (saveItemArgs: SaveItemArguments) => {
    dispatch(
      saveNewItem(saveItemArgs, (itemId) =>
        navigate(AppRoutes.Success + "/" + itemId),
      ),
    );
  };

  return (
    <NewItemScreen
      initialCategoryKey={categoryKey}
      categoryOptions={categoryOptions}
      defaultWarrantyMonths={defaultWarrantyMonths}
      onSave={onSave}
      firstDayOfWeek={firstDayOfWeek}
      dateFormat={dateFormat}
    />
  );
}
