import {
  CategoryKey,
  DateFormat,
  FirstDayOfWeek,
  StringUtils,
  Variant,
} from "@warranty-keeper/common";
import { PickerOptionData } from "../../components/picker/PickerOption/PickerOption";
import { SaveItemArguments } from "../../store/items/actions/SaveNewItemAction";
import { useCallback, useEffect, useState } from "react";
import { MediaFileMethod, RawMediaFile } from "../../utils/MediaFileUtils";
import { Input, MultiLineInput } from "../../components/basic/Input";
import MediaField from "../../components/item/MediaField/MediaField";
import CategoryPicker from "../../components/category/CategoryPicker/CategoryPicker";
import Button from "../../components/basic/Button";
import DatePicker from "../../components/basic/DatePicker/DatePicker";
import Screen from "../../components/basic/Screen/Screen";
import Popup from "../../components/basic/Popup";
import { useNavigate } from "react-router";
import { MAX_RECEIPTS_PER_ITEM } from "../receipt/ReceiptScreen";
import Illustration from "../../components/basic/Illustration/Illustration";
import UnsavedChangesLight from "../../assets/illustrations/unsaved-changes-light.svg";
import UnsavedChangesDark from "../../assets/illustrations/unsaved-changes-dark.svg";
import { registerBackHandler } from "../../utils/BackUtils";
import { DateTime } from "luxon";
import { translate } from "../../locale/Locale";

export interface EditItemFieldsProps {
  name: string;
  purchaseDateMillis: number;
  itemImageUrl: string | undefined;
  category: string | undefined;
  warrantyMonths: number;
  notes: string;

  firstDayOfWeek: FirstDayOfWeek;
  dateFormat: DateFormat;
  headerTitle: string;
  showReceiptsField: boolean;
  categoryOptions: PickerOptionData<CategoryKey>[];
  onSave: (args: SaveItemArguments) => void;
}

export default function EditItemFields(props: EditItemFieldsProps) {
  const [name, setName] = useState(props.name);
  const [purchaseDate, setPurchaseDate] = useState(
    DateTime.fromMillis(props.purchaseDateMillis),
  );
  const [itemImage, setItemImage] = useState<RawMediaFile[]>(
    props.itemImageUrl
      ? [
          {
            localUri: props.itemImageUrl,
            method: MediaFileMethod.PREVIOUSLY_SELECTED,
          },
        ]
      : [],
  );
  const [receipts, setReceipts] = useState<RawMediaFile[]>([]);
  const [categoryKey, setCategoryKey] = useState(props.category);
  const [warrantyMonths, setWarrantyMonths] = useState<number | undefined>(
    props.warrantyMonths,
  );
  const [notes, setNotes] = useState(props.notes);

  const [loading, setLoading] = useState(false);
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
  const navigate = useNavigate();

  // Errors
  const [hasNameError, setNameError] = useState(false);
  const [hasCategoryError, setCategoryError] = useState(false);
  const [hasWarrantyMonthsError, setWarrantyMonthsError] = useState(false);

  // Category Picker
  const selectedCategoryOptions = props.categoryOptions.filter(
    (option) => option.value === categoryKey,
  );
  const selectedCategoryOption =
    selectedCategoryOptions.length > 0 ? selectedCategoryOptions[0] : undefined;

  const onSave = () => {
    let shouldSave = true;
    if (StringUtils.isEmptyString(name)) {
      setNameError(true);
      shouldSave = false;
    }

    if (StringUtils.isEmptyString(categoryKey)) {
      setCategoryError(true);
      shouldSave = false;
    }

    if (!warrantyMonths) {
      setWarrantyMonthsError(true);
      shouldSave = false;
    }

    if (shouldSave) {
      setLoading(true);
      props.onSave({
        name: name.trim(),
        categoryKey: categoryKey!,
        purchaseDateMillis: purchaseDate!.toMillis(),
        warrantyMonths: warrantyMonths!,
        notes: notes.trim(),
        itemImage: itemImage.length > 0 ? itemImage[0] : undefined,
        receipts,
      });
    }
  };

  const hasChanges =
    name !== props.name ||
    purchaseDate.toMillis() !== props.purchaseDateMillis ||
    (itemImage.length === 0 && props.itemImageUrl) ||
    (itemImage.length === 1 && itemImage[0].localUri !== props.itemImageUrl) ||
    receipts.length > 0 ||
    categoryKey !== props.category ||
    warrantyMonths !== props.warrantyMonths ||
    notes !== props.notes;

  const onBack = useCallback(() => {
    if (hasChanges) {
      setDiscardChangesOpen(true);
    } else {
      navigate(-1);
    }
  }, [navigate, hasChanges]);

  useEffect(() => {
    return registerBackHandler(onBack);
  }, [onBack]);

  return (
    <Screen headerTitle={props.headerTitle} onBack={onBack}>
      <div>
        <Input
          label={translate("editItem.itemName")}
          value={name}
          onChangeText={(value) => {
            setName(value);
            setNameError(false);
          }}
          error={hasNameError ? translate("common.required") : undefined}
        />

        {props.showReceiptsField && (
          <MediaField
            label={translate("item.receipt")}
            methods={[
              MediaFileMethod.TAKE_PHOTO,
              MediaFileMethod.UPLOAD_IMAGE,
              MediaFileMethod.UPLOAD_PDF,
            ]}
            value={receipts}
            setValue={setReceipts}
            maxItems={MAX_RECEIPTS_PER_ITEM}
          />
        )}

        <CategoryPicker
          categoryOptions={props.categoryOptions}
          initialOption={selectedCategoryOption}
          error={hasCategoryError ? translate("common.required") : undefined}
          onCategorySelected={(categoryKey) => {
            setCategoryKey(categoryKey);
            setCategoryError(false);
          }}
        />

        <DatePicker
          label={translate("item.purchaseDate")}
          value={purchaseDate}
          onChange={setPurchaseDate}
          firstDayOfWeek={props.firstDayOfWeek}
          dateFormat={props.dateFormat}
          testId="DatePicker"
        />

        <Input
          label={translate("item.warrantyMonths")}
          type="number"
          value={warrantyMonths ? warrantyMonths.toString() : ""}
          onChangeText={(value) => {
            if (value === "") {
              setWarrantyMonths(undefined);
              setWarrantyMonthsError(false);
              return;
            }

            const number = parseInt(value);
            setWarrantyMonths(number);
            setWarrantyMonthsError(false);
          }}
          error={
            hasWarrantyMonthsError ? translate("common.required") : undefined
          }
          maxLength={3}
          maxValue={999}
        />

        <MediaField
          label={translate("editItem.itemImage")}
          methods={[
            MediaFileMethod.SEARCH_ONLINE,
            MediaFileMethod.TAKE_PHOTO,
            MediaFileMethod.UPLOAD_IMAGE,
          ]}
          value={itemImage}
          setValue={setItemImage}
          hintText={name}
          maxItems={1}
        />

        <MultiLineInput
          label={translate("item.notes")}
          onChangeText={setNotes}
          value={notes}
          maxLength={200}
        />

        <Button
          title={translate("common.save")}
          onClick={onSave}
          loading={loading}
          analyticTag={"editItem/Save"}
        />
      </div>

      <Popup
        open={discardChangesOpen}
        title={translate("alerts.unsavedChanges")}
        content={translate("alerts.areYouSureYouWantToLeave")}
        illustration={
          <Illustration
            lightPath={UnsavedChangesLight}
            darkPath={UnsavedChangesDark}
          />
        }
        buttons={[
          {
            label: translate("alerts.stayHere"),
            variant: Variant.Info,
            onClick: () => setDiscardChangesOpen(false),
            analyticTag: "editItem/stayHere",
          },
          {
            label: translate("alerts.discardChanges"),
            variant: Variant.Primary,
            onClick: () => navigate(-1),
            analyticTag: "editItem/discardChanges",
          },
        ]}
        onClose={() => setDiscardChangesOpen(false)}
      />
    </Screen>
  );
}
