import { StringUtils } from "@warranty-keeper/common";

export function isPrivateAppleUser(email: string) {
  return email.endsWith("@privaterelay.appleid.com");
}

export function isValidEmail(email: string) {
  if (StringUtils.isEmptyString(email)) {
    return false;
  }

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
