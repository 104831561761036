import { FunctionsApi, IReceipt } from "@warranty-keeper/common";
import { getCallableFunction } from "./FunctionUtils";
import { reportClick, reportCustom } from "./FirebaseAnalytics";

export async function deleteItem(itemId: string) {
  const callableFunction = getCallableFunction(FunctionsApi.DeleteItemFunction);

  const request: FunctionsApi.DeleteItemRequest = {
    itemId,
  };

  await callableFunction(request);
}

export async function createItem(
  request: FunctionsApi.CreateItemRequest,
): Promise<FunctionsApi.CreateItemResponse> {
  const callableFunction = getCallableFunction(FunctionsApi.CreateItemFunction);

  const response = await callableFunction(request);
  return response.data as FunctionsApi.CreateItemResponse;
}

export async function updateItem(
  request: FunctionsApi.UpdateItemRequest,
): Promise<FunctionsApi.UpdateItemResponse> {
  const callableFunction = getCallableFunction(FunctionsApi.UpdateItemFunction);

  const response = await callableFunction(request);
  return response.data as FunctionsApi.UpdateItemResponse;
}

export async function removeReceipt(
  itemId: string,
  receiptFileName: string,
): Promise<FunctionsApi.UpdateItemResponse> {
  const callableFunction = getCallableFunction(
    FunctionsApi.RemoveReceiptFunction,
  );

  const request: FunctionsApi.RemoveReceiptRequest = {
    itemId,
    receiptFileName,
  };

  const response = await callableFunction(request);
  return response.data as FunctionsApi.RemoveReceiptResponse;
}

export async function addReceipt(
  itemId: string,
  receipt: IReceipt,
): Promise<void> {
  const callableFunction = getCallableFunction(FunctionsApi.AddReceiptFunction);

  const request: FunctionsApi.AddReceiptRequest = {
    itemId,
    receipt,
  };

  await callableFunction(request);
}

export async function searchOnlineImages(
  query: string,
  searchIndex: number,
): Promise<string[]> {
  const startTime = new Date().getTime();
  const callableFunction = getCallableFunction(
    FunctionsApi.SearchOnlineImagesFunction,
  );

  reportClick("searchOnlineImage");
  reportCustom("searchOnlineImage/" + query);

  const request: FunctionsApi.SearchOnlineImagesRequest = {
    query,
    searchIndex,
  };

  const response = await callableFunction(request);
  console.log("ST", new Date().getTime() - startTime);
  const data = response.data as FunctionsApi.SearchOnlineImagesResponse;
  return data.images;
}
