import React, { useEffect, useState } from "react";
import { CustomImage } from "../../components/basic/CustomImage";
import styles from "./OnlineImageSelectorScreen.module.css";
import Button from "../../components/basic/Button";
import SearchBar from "../../components/basic/SearchBar/SearchBar";
import Screen from "../../components/basic/Screen/Screen";
import _ from "lodash";
import Spinner from "../../components/basic/Spinner/Spinner";
import Illustration from "../../components/basic/Illustration/Illustration";
import ImageSearchLight from "../../assets/illustrations/image-search-light.svg";
import ImageSearchDark from "../../assets/illustrations/image-search-dark.svg";
import { translate } from "../../locale/Locale";

export interface OnlineImageSelectorScreenProps {
  initialSearchText: string;
  onSelectedImage: (imageUrl: string) => void;
  newSearch: (searchText: string) => Promise<string[]>;
  getMoreImages: (searchText: string, pressIndex: number) => Promise<string[]>;
  onBack: () => void;
}

const MAX_MORE_PRESSES = 2;

export default function OnlineImageSelectorScreen(
  props: OnlineImageSelectorScreenProps,
) {
  const [searchText, setSearchText] = useState(props.initialSearchText);
  const [searchMorePressedTimes, setSearchMorePressedTimes] = useState(0);
  const [urls, setUrls] = useState<string[]>([]);
  const [wasFirstSearchRun, setWasFirstSearchRun] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const startNewSearch = async () => {
    setLoading(true);
    setWasFirstSearchRun(true);
    setUrls([]);
    setSearchMorePressedTimes(0);
    const res = await props.newSearch(searchText);
    setUrls(res);
    setLoading(false);
  };

  const getMoreImages = async () => {
    setButtonLoading(true);
    const pressIndex = searchMorePressedTimes + 1;
    setSearchMorePressedTimes(pressIndex);
    const newUrls = await props.getMoreImages(searchText, pressIndex);
    setButtonLoading(false);
    setUrls(_.concat(urls, newUrls));
  };

  useEffect(() => {
    if (props.initialSearchText) {
      startNewSearch();
    }
    // eslint-disable-next-line
  }, [props.initialSearchText]);

  let Content: React.ReactNode;
  if (loading) {
    Content = (
      <div className={styles.loading}>
        <Spinner />
      </div>
    );
  } else if (!wasFirstSearchRun) {
    Content = (
      <div className={styles.firstSearch}>
        {translate("search.startSearching")}
        <Illustration
          lightPath={ImageSearchLight}
          darkPath={ImageSearchDark}
          className={styles.illustration}
        />
      </div>
    );
  } else if (urls.length === 0) {
    Content = (
      <div className={styles.noImages}>
        <div className={styles.noImagesTitle}>
          {translate("searchImages.noResultsFound")}
        </div>
        {translate("searchImages.noResultsFoundSubtitle")}
      </div>
    );
  } else {
    Content = (
      <>
        <div className={styles.imageList}>
          {urls.map((url, index) => (
            <div className={styles.singleImage} key={url + index}>
              <CustomImage
                imageUrl={url}
                onClick={() => props.onSelectedImage(url)}
                className={styles.image}
              />
            </div>
          ))}
        </div>

        {(buttonLoading || searchMorePressedTimes < MAX_MORE_PRESSES) && (
          <div className={styles.buttonContainer}>
            <div className={styles.button}>
              <Button
                title={translate("searchImages.showMore")}
                onClick={getMoreImages}
                loading={buttonLoading}
                analyticTag={"searchImages.showMore"}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <Screen
      headerTitle={translate("searchImages.selectImage")}
      onBack={props.onBack}
    >
      <SearchBar
        value={searchText}
        onTextChange={setSearchText}
        onSearch={startNewSearch}
      />

      {Content}
    </Screen>
  );
}
