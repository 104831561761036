import * as actionTypes from "../ActionTypes";
import { UPDATE_USER_FROM_DB } from "../ActionTypes";
import { IUser } from "@warranty-keeper/common";
import { setUserInCache } from "../../../firebase/LocalCache";

export function userLoggedOut() {
  return {
    type: actionTypes.LOGGED_OUT,
  };
}

export function updateUserFromDb(
  user: IUser,
  email: string,
  skipCacheUpdate?: boolean,
) {
  if (!skipCacheUpdate) {
    setUserInCache(user);
  }

  return {
    type: UPDATE_USER_FROM_DB,
    user: {
      ...user,
      email,
    },
  };
}
