import {
  CategoryKey,
  DateFormat,
  FirstDayOfWeek,
  IItem,
} from "@warranty-keeper/common";
import { PickerOptionData } from "../../components/picker/PickerOption/PickerOption";
import { SaveItemArguments } from "../../store/items/actions/SaveNewItemAction";
import EditItemFields from "./EditItemFields";
import { translate } from "../../locale/Locale";

export interface EditItemScreenProps {
  item: IItem;
  categoryOptions: PickerOptionData<CategoryKey>[];
  dateFormat: DateFormat;
  firstDayOfWeek: FirstDayOfWeek;
  onSave: (args: SaveItemArguments) => void;
}

export default function EditItemScreen(props: EditItemScreenProps) {
  const item = props.item;

  return (
    <EditItemFields
      headerTitle={translate("editItem.editItem", { itemName: item.name })}
      name={item.name}
      purchaseDateMillis={item.purchaseDateMillis}
      category={item.category}
      warrantyMonths={item.warrantyMonths}
      itemImageUrl={item.imageUrl}
      notes={item.notes}
      showReceiptsField={false}
      categoryOptions={props.categoryOptions}
      dateFormat={props.dateFormat}
      firstDayOfWeek={props.firstDayOfWeek}
      onSave={props.onSave}
    />
  );
}
