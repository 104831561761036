import { useState } from "react";
import styles from "./RegisterScreen.module.scss";
import authStyles from "./Authentication.module.css";
import { Input } from "../../components/basic/Input";
import Button from "../../components/basic/Button";
import Screen from "../../components/basic/Screen/Screen";
import TextButton from "../../components/basic/TextButton";
import { Variant } from "@warranty-keeper/common";
import Divider from "../../components/basic/Divider/Divider";
import SocialSignInButtons from "../../components/SocialSignIn/SocialSignInButtons";
import { SocialSignInMethod } from "../../components/SocialSignIn/SocialSignInButton";
import { ReactComponent as PersonIcon } from "../../assets/icons/person.svg";
import { ReactComponent as EnvelopeIcon } from "../../assets/icons/envelope.svg";
import classNames from "classnames";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { translate } from "../../locale/Locale";

export interface RegisterScreenProps {
  onClickSocial: (
    method: SocialSignInMethod,
    setError: (message: string[]) => void,
  ) => void;
  onRegister: (
    name: string,
    email: string,
    password: string,
    emailError: (message: string) => void,
    passwordError: (message: string) => void,
  ) => void;
  goToSignIn: () => void;
  enableAppleLogin: boolean;
}

export default function RegisterScreen(props: RegisterScreenProps) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nameError, setNameError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onEmailError = (emailError: string) => {
    setEmailError(emailError);
    setIsLoading(false);
  };

  const onPasswordError = (passwordError: string) => {
    setPasswordError(passwordError);
    setIsLoading(false);
  };

  const clearErrors = () => {
    setEmailError("");
    setPasswordError("");
    setNameError("");
  };

  const onRegister = () => {
    if (name.length === 0) {
      setNameError(translate("authenticationErrors.invalidName"));
      return;
    }

    if (password !== confirmedPassword) {
      setPasswordError(translate("authenticationErrors.passwordsDoNotMatch"));
      return;
    }

    setIsLoading(true);
    props.onRegister(
      name.trim(),
      email.trim(),
      password,
      onEmailError,
      onPasswordError,
    );
  };

  return (
    <Screen headerTitle={translate("signIn.createAccount")}>
      <div className={authStyles.screenContainer}>
        <div className={styles.title}>{translate("signIn.enterDetails")}</div>

        <div className={authStyles.inputFields}>
          <Input
            label={translate("common.name")}
            value={name}
            onChangeText={(name) => {
              clearErrors();
              setName(name);
            }}
            error={nameError}
            icon={<PersonIcon className={styles.icon} />}
          />

          <Input
            label={translate("common.email")}
            value={email}
            onChangeText={(email: string) => {
              clearErrors();
              setEmail(email);
            }}
            type="email"
            error={emailError}
            icon={<EnvelopeIcon className={styles.icon} />}
          />

          <Input
            label={translate("common.password")}
            value={password}
            onChangeText={(password: string) => {
              clearErrors();
              setPassword(password);
            }}
            type="password"
            icon={<LockIcon className={styles.lockIcon} />}
          />

          <Input
            label={translate("account.confirmPassword")}
            value={confirmedPassword}
            onChangeText={(confirmedPassword: string) => {
              clearErrors();
              setConfirmedPassword(confirmedPassword);
            }}
            type="password"
            error={passwordError}
            onSubmit={onRegister}
            icon={<LockIcon className={styles.lockIcon} />}
          />
        </div>

        <Button
          className={authStyles.actionButton}
          title={translate("signIn.register")}
          onClick={onRegister}
          loading={isLoading}
          disabled={!email || !password}
        />

        <div
          className={classNames(authStyles.alternativeText, authStyles.flex1)}
        >
          {translate("signIn.alreadyHaveAnAccount") + " "}
          <TextButton
            title={translate("signIn.signIn")}
            variant={Variant.Primary}
            onClick={props.goToSignIn}
            className={authStyles.alternativeButton}
          />
        </div>

        <Divider text={translate("common.or")} className={authStyles.divider} />

        <SocialSignInButtons
          onClickSocial={props.onClickSocial}
          enableAppleLogin={props.enableAppleLogin}
        />
      </div>
    </Screen>
  );
}
