import { IItem } from "@warranty-keeper/common";
import { CustomImage } from "../../components/basic/CustomImage";
import Screen from "../../components/basic/Screen/Screen";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import styles from "./ItemImageScreen.module.css";

export interface ItemImageScreenProps {
  item: IItem;
}

export default function ItemImageScreen(props: ItemImageScreenProps) {
  return (
    <Screen headerTitle={props.item.name}>
      <TransformWrapper>
        <TransformComponent
          wrapperClass={styles.transformerWrapper}
          contentStyle={{ padding: 20 }}
        >
          <CustomImage
            imageUrl={props.item.imageUrl}
            className={styles.image}
          />
        </TransformComponent>
      </TransformWrapper>
    </Screen>
  );
}
