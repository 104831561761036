import SignInScreen from "./SignInScreen";
import { useNavigate } from "react-router-dom";
import { AuthenticationRoutes } from "../../router/Routes";
import {
  appleLoginSupported,
  socialSignIn,
} from "../../firebase/SocialAuthentication";
import { useContext, useEffect } from "react";
import { LocaleContext } from "../../locale/LocaleContext";
import { registerBackHandler } from "../../utils/BackUtils";
import { App as CapacitorApp } from "@capacitor/app";
import { isProduction } from "../../firebase/FirebaseConfig";

export default function SignInScreenContainer() {
  const navigate = useNavigate();
  const [locale] = useContext(LocaleContext);

  useEffect(() => {
    return registerBackHandler(() => CapacitorApp.exitApp());
  }, []);

  return (
    <SignInScreen
      goToSignInWithEmail={() => navigate(AuthenticationRoutes.EmailSignIn)}
      goToRegister={() => navigate(AuthenticationRoutes.Register)}
      gotToFacebookHelpPage={() => navigate(AuthenticationRoutes.FacebookHelp)}
      contactSupport={() =>
        (window.location.href = "mailto:app@warranty-keeper.com")
      }
      onClickSocial={(method, setError) =>
        socialSignIn(method, setError, locale)
      }
      enableAppleLogin={appleLoginSupported()}
      isProd={isProduction()}
    />
  );
}
