import styles from "./ItemList.module.css";
import {
  DateFormat,
  DateUtils,
  IItem,
  SortMethod,
} from "@warranty-keeper/common";
import { ItemPreview } from "../ItemPreview";
import _ from "lodash";
import { translate } from "../../../locale/Locale";

export interface ItemListProps {
  items: IItem[];
  dateFormat: DateFormat;
  sortingMethod: SortMethod;
  onClickOnItem: (item: IItem) => void;
}

export default function ItemList(props: ItemListProps) {
  let activeItems: IItem[] = [];
  let expiredItems: IItem[] = [];

  switch (props.sortingMethod) {
    default:
    case SortMethod.Name:
      activeItems = _.orderBy(props.items, (x) => x.name.toLocaleLowerCase());
      break;

    case SortMethod.LastChange:
      activeItems = _.orderBy(props.items, (x) => x.lastUpdateMillis, "desc");
      break;

    case SortMethod.ExpiryDate:
      activeItems = _.orderBy(
        _.filter(props.items, (i) => !DateUtils.hasItemExpired(i)),
        (x) => x.warrantyVoidsDateMillis,
      );
      expiredItems = _.orderBy(
        _.filter(props.items, DateUtils.hasItemExpired),
        (x) => -x.warrantyVoidsDateMillis,
      );
      break;

    case SortMethod.PurchaseDate:
      activeItems = _.orderBy(props.items, (x) => x.purchaseDateMillis, "desc");
      expiredItems = [] as IItem[];
      break;
  }

  return (
    <div data-test-id={"itemsList"}>
      {activeItems.map((item) => (
        <ItemPreview
          key={item.id}
          item={item}
          dateFormat={props.dateFormat}
          onClick={() => props.onClickOnItem(item)}
        />
      ))}

      {expiredItems.length > 0 && activeItems.length > 0 && (
        <div className={styles.expiredItemsLabel}>
          {translate("itemPreview.expiredItems")}
        </div>
      )}

      {expiredItems.map((item) => (
        <ItemPreview
          key={item.id}
          dateFormat={props.dateFormat}
          item={item}
          onClick={() => props.onClickOnItem(item)}
        />
      ))}
    </div>
  );
}
