import React, { ChangeEventHandler, useState } from "react";
import styles from "./style.module.scss";
import InputWrapper from "./InputWrapper";
import { ReactComponent as EyeOpen } from "../../../assets/icons/eye-open.svg";
import { ReactComponent as EyeClosed } from "../../../assets/icons/eye-closed.svg";

export interface InputProps {
  value?: string;
  label?: string;
  type?: "number" | "email" | "password";
  maxValue?: number;
  onChangeText: (text: string) => void;
  onSubmit?: () => void;
  error?: string;
  extraLabel?: string;
  icon?: React.ReactNode;
  maxLength?: number;
}

export default function Input(props: InputProps) {
  const isPasswordType = props.type === "password";
  const [showContent, setShowContent] = useState(!isPasswordType);
  const [focused, setFocused] = useState(false);

  let type = props.type;
  let endIcon: React.ReactNode | undefined;
  if (isPasswordType) {
    if (showContent) {
      endIcon = (
        <EyeClosed
          onClick={() => setShowContent(false)}
          className={styles.eyeIcon}
        />
      );
      type = undefined;
    } else {
      endIcon = (
        <EyeOpen
          onClick={() => setShowContent(true)}
          className={styles.eyeIcon}
        />
      );
      type = "password";
    }
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    handleInputChange(
      newValue,
      props.value,
      props.onChangeText,
      props.type,
      props.maxValue,
    );
  };

  return (
    <InputWrapper
      label={props.label}
      startIcon={props.icon}
      endIcon={endIcon}
      error={props.error}
      extraLabel={props.extraLabel}
      showBigLabel={!props.value && !focused}
    >
      <input
        data-test-id={"input-" + props.label}
        className={styles.input}
        value={props.value}
        onChange={onChange}
        onSubmit={props.onSubmit}
        type={type}
        max={props.maxValue}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        maxLength={props.maxLength || 35}
        onKeyPress={(event) => {
          if (props.onSubmit && event.key === "Enter") {
            props.onSubmit();
          }
        }}
      />
    </InputWrapper>
  );
}

export function handleInputChange(
  newValue: string,
  value: string | undefined,
  onChangeText: (text: string) => void,
  type?: "number" | "email" | "password",
  maxValue?: number,
) {
  if (type !== "number") {
    onChangeText(newValue);
    return;
  }

  // Support empty
  if (newValue === "") {
    onChangeText("");
    return;
  }

  const reg = new RegExp("^[0-9]+$"); // Accept only digits
  if (!newValue.match(reg)) {
    // onChangeText("");
    return;
  }

  const number = parseInt(newValue);
  if (isNaN(number)) {
    return;
  }

  if (maxValue && number > maxValue) {
    return;
  }

  onChangeText(newValue);
}
