import styles from "./AllItemsTab.module.css";
import { DateFormat, IItem, SortMethod } from "@warranty-keeper/common";
import ItemList from "../../components/item/ItemList/ItemList";
import Illustration from "../../components/basic/Illustration/Illustration";
import NoItemsLight from "../../assets/illustrations/all-items-no-items-light.svg";
import NoItemsDark from "../../assets/illustrations/all-items-no-items-dark.svg";
import _ from "lodash";
import { useState } from "react";
import SearchBar from "../../components/basic/SearchBar/SearchBar";
import SearchItemsLight from "../../assets/illustrations/search-items-light.svg";
import SearchItemsDark from "../../assets/illustrations/search-items-dark.svg";
import Fab from "../../components/basic/Fab/Fab";
import { translate } from "../../locale/Locale";

export interface AllItemsTabProps {
  items: IItem[];
  onAddItem: () => void;
  sortingMethod: SortMethod;
  dateFormat: DateFormat;
  onClickOnItem: (item: IItem) => void;
}

export default function AllItemsTab(props: AllItemsTabProps) {
  const [searchText, setSearchText] = useState("");
  if (props.items.length === 0) {
    return (
      <div className={styles.content}>
        <div className={styles.noItems} onClick={props.onAddItem}>
          <div>
            <Illustration
              lightPath={NoItemsLight}
              darkPath={NoItemsDark}
              className={styles.illustration}
            />
            <div>{translate("allItems.noItemsYet")}</div>
            <div>{translate("allItems.addItemsTip")}</div>
          </div>
        </div>

        <Fab onClick={props.onAddItem} />
      </div>
    );
  }

  const filteredItems = _.filter(props.items, (item) =>
    item.name.toLowerCase().includes(searchText.trim().toLowerCase()),
  );

  // TODO make search bar sticky to top.
  return (
    <div className={styles.content}>
      <div className={styles.searchBar}>
        <SearchBar
          value={searchText}
          onTextChange={setSearchText}
          startFocused={false}
        />
      </div>
      {filteredItems.length === 0 ? (
        <div className={styles.searchNoItems}>
          <Illustration
            lightPath={SearchItemsLight}
            darkPath={SearchItemsDark}
            className={styles.noItemsIllustration}
          />
          <div>{translate("search.noItemsFound")}</div>
        </div>
      ) : (
        <ItemList
          items={filteredItems}
          dateFormat={props.dateFormat}
          sortingMethod={props.sortingMethod}
          onClickOnItem={props.onClickOnItem}
        />
      )}

      <Fab onClick={props.onAddItem} />
    </div>
  );
}
