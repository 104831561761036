import classNames from "classnames";
import styles from "./Drawer.module.scss";

export interface DrawerProps {
  open: boolean;
  close: () => void;
  children: React.ReactNode;
}

export default function Drawer(props: DrawerProps) {
  return (
    <>
      <div
        className={classNames({
          [styles.background]: true,
          [styles.backgroundOpen]: props.open,
        })}
        onClick={props.close}
      />
      <div
        className={classNames({
          [styles.drawer]: true,
          [styles.drawerOpen]: props.open,
        })}
      >
        <div>{props.children}</div>
      </div>
    </>
  );
}
