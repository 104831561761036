"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.firebaseDevConfig = void 0;
exports.firebaseDevConfig = {
    apiKey: "AIzaSyAKUmf_Jzmbu_-GFfK9UrKBZ-KES0RoJN4",
    authDomain: "warranty-keeper-dev.firebaseapp.com",
    databaseURL: "https://warranty-keeper-dev.firebaseio.com",
    projectId: "warranty-keeper-dev",
    storageBucket: "warranty-keeper-dev.appspot.com",
    messagingSenderId: "910926263319",
    appId: "1:910926263319:web:958107880302b58c2a5852",
    measurementId: "G-ZX22XKG5EE",
};
