import _ from "lodash";
import { CategoryKey, IItemsMap, IState } from "@warranty-keeper/common";

export function getItemsMap(state: IState): IItemsMap {
  return state.items;
}

export function getItemsCount(state: IState) {
  return _.size(getItemsMap(state));
}

export function getItemById(state: IState) {
  return (itemId?: string) => (itemId ? getItemsMap(state)[itemId] : undefined);
}

export function getAllItems(state: IState) {
  return _.values(getItemsMap(state));
}

export function getCategoryItemsCount(state: IState) {
  return (categoryKey: CategoryKey) => {
    const categoryItems = getItemsByCategory(state)(categoryKey);
    return categoryItems.length;
  };
}

export function getItemsByCategory(state: IState) {
  return (categoryKey?: CategoryKey) => {
    if (!categoryKey) {
      return [];
    }
    let items = getAllItems(state);

    items = _.filter(items, (x) => x.category === categoryKey);

    return items;
  };
}
