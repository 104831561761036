import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCategoryPickerOptions,
  getDateFormat,
  getFirstDayOfWeek,
} from "../../store/user/Selectors";
import { SaveItemArguments } from "../../store/items/actions/SaveNewItemAction";
import { getItemById } from "../../store/items/Selectors";
import EditItemScreen from "./EditItemScreen";
import { updateItem } from "../../store/items/actions/UpdateItemAction";
import { AppRoutes } from "../../router/Routes";

export default function EditItemScreenContainer() {
  const { itemId } = useParams<{ itemId: string }>();
  const item = useSelector(getItemById)(itemId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoryOptions = useSelector(getCategoryPickerOptions);
  const firstDayOfWeek = useSelector(getFirstDayOfWeek);
  const dateFormat = useSelector(getDateFormat);

  if (!itemId || !item) {
    navigate(AppRoutes.AllItems);
    return null;
  }

  const onSave = (saveItemArgs: SaveItemArguments) => {
    dispatch(updateItem(itemId, saveItemArgs, () => navigate(-1)));
  };

  return (
    <EditItemScreen
      item={item}
      categoryOptions={categoryOptions}
      firstDayOfWeek={firstDayOfWeek}
      dateFormat={dateFormat}
      onSave={onSave}
    />
  );
}
