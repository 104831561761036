import { useNavigate } from "react-router";
import GeneralSettingsScreen from "./GeneralSettingsScreen";
import { useDispatch, useSelector } from "react-redux";
import { updateGeneralSettings } from "../../store/user/actions/UpdateGeneralSettingsAction";
import {
  getGeneralSettings,
  getSortingMethod,
} from "../../store/user/Selectors";
import {
  DateFormat,
  FirstDayOfWeek,
  SortMethod,
} from "@warranty-keeper/common";

export default function GeneralSettingsScreenContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generalSettings = useSelector(getGeneralSettings);
  const sortingMethod = useSelector(getSortingMethod);
  return (
    <GeneralSettingsScreen
      generalSettings={generalSettings}
      sortingMethod={sortingMethod}
      onSave={(
        firstDayOfWeek: FirstDayOfWeek,
        dateFormat: DateFormat,
        defaultWarrantyMonths: number,
        sortingMethod: SortMethod,
      ) => {
        dispatch(
          updateGeneralSettings(
            firstDayOfWeek,
            dateFormat,
            defaultWarrantyMonths,
            sortingMethod,
          ),
        );
        navigate(-1);
      }}
    />
  );
}
