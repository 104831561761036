import { DateUtils } from "@warranty-keeper/common";

export function getLeftTimeString(
  warrantyVoidDateMillis: number,
  translate: (key: string, options?: { count: number }) => string,
) {
  const monthsDiff = DateUtils.getMonthsUntilWarrantyVoids(
    warrantyVoidDateMillis,
  );
  if (monthsDiff > 6)
    return translate("item.monthsLeft", { count: monthsDiff });

  return translate("item.daysLeft", {
    count: DateUtils.getDaysUntilWarrantyVoids(warrantyVoidDateMillis),
  });
}
