import { useState } from "react";
import authStyles from "./Authentication.module.css";
import styles from "./EmailSignInScreen.module.scss";
import AppLogo from "../../components/basic/AppLogo/AppLogo";
import { Input } from "../../components/basic/Input";
import Button from "../../components/basic/Button";
import Divider from "../../components/basic/Divider/Divider";
import Screen from "../../components/basic/Screen/Screen";
import TextButton from "../../components/basic/TextButton";
import { Variant } from "@warranty-keeper/common";
import SocialSignInButtons from "../../components/SocialSignIn/SocialSignInButtons";
import { SocialSignInMethod } from "../../components/SocialSignIn/SocialSignInButton";
import { ReactComponent as EnvelopeIcon } from "../../assets/icons/envelope.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import classNames from "classnames";
import { translate } from "../../locale/Locale";

export interface EmailSignInScreenProps {
  onClickSocial: (
    method: SocialSignInMethod,
    setError: (message: string[]) => void,
  ) => Promise<void>;
  onSignIn: (
    email: string,
    password: string,
    emailError: (message: string) => void,
    passwordError: (message: string) => void,
  ) => void;
  goToRegister: () => void;
  goToResetPassword: () => void;
  enableAppleLogin: boolean;
}

export default function EmailSignInScreen(props: EmailSignInScreenProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onEmailError = (emailError: string) => {
    setEmailError(emailError);
    setPassword("");
    setIsLoading(false);
  };

  const onPasswordError = (passwordError: string) => {
    setPasswordError(passwordError);
    setIsLoading(false);
  };

  const signIn = () => {
    setIsLoading(true);
    props.onSignIn(email.trim(), password, onEmailError, onPasswordError);
  };

  return (
    <Screen headerTitle={translate("signIn.signIn")}>
      <div className={authStyles.screenContainer}>
        <div className={styles.logoContainer}>
          <AppLogo className={authStyles.logo} />
        </div>
        <div className={styles.appName}>
          Warranty Keeper
          <span className={styles.appNamePeriod}>.</span>
        </div>
        <div className={authStyles.inputFields}>
          <Input
            label={translate("common.email")}
            value={email}
            onChangeText={(email: string) => {
              setEmail(email);
              setEmailError("");
              setPasswordError("");
            }}
            type="email"
            error={emailError}
            icon={<EnvelopeIcon className={styles.icon} />}
          />

          <Input
            label={translate("common.password")}
            value={password}
            onChangeText={(password: string) => {
              setPassword(password);
              setEmailError("");
              setPasswordError("");
            }}
            type="password"
            error={passwordError}
            onSubmit={signIn}
            icon={<LockIcon className={styles.lockIcon} />}
          />

          <div className={styles.resetPassword}>
            <TextButton
              title={translate("signIn.forgotPassword")}
              onClick={props.goToResetPassword}
            />
          </div>
        </div>
        <Button
          className={authStyles.actionButton}
          title={translate("signIn.login")}
          onClick={signIn}
          loading={isLoading}
          disabled={!email || !password}
        />
        <div
          className={classNames(authStyles.alternativeText, authStyles.flex1)}
        >
          {translate("signIn.dontHaveAnAccount") + " "}
          <TextButton
            title={translate("signIn.signUp")}
            variant={Variant.Primary}
            onClick={props.goToRegister}
            className={authStyles.alternativeButton}
          />
        </div>
        <Divider text={translate("common.or")} className={styles.divider} />
        <SocialSignInButtons
          onClickSocial={props.onClickSocial}
          enableAppleLogin={props.enableAppleLogin}
        />
      </div>
    </Screen>
  );
}
