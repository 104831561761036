import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { firebaseAnalytics } from "./FirebaseInitializer";
import { SocialSignInMethod } from "../components/SocialSignIn/SocialSignInButton";
import { getAppInfo, isNativePlatform } from "./UserFunctions";

const shouldLogReporst = () => isNativePlatform();

export function reportScreen(pathName: string) {
  if (!firebaseAnalytics) {
    return;
  }
  const key = getScreenName(pathName);
  shouldLogReporst() && console.log("screen/" + key);
  logEvent(firebaseAnalytics, "screen/" + key);
}

export function reportClick(key: string) {
  if (!firebaseAnalytics) {
    return;
  }

  shouldLogReporst() && console.log("click/" + key);
  logEvent(firebaseAnalytics, "click/" + key);
}

export function reportCustom(key: string) {
  if (!firebaseAnalytics) {
    return;
  }

  shouldLogReporst() && console.log("custom -", key);
  logEvent(firebaseAnalytics, key);
}

export function setAnalyticsUserId(userId: string) {
  if (!firebaseAnalytics) {
    return;
  }

  shouldLogReporst() && console.log("setting user id -", userId);
  setUserId(firebaseAnalytics, userId);
  setUserProperties(firebaseAnalytics, getAppInfo());
}

export function reportLogin(method: SocialSignInMethod | "email") {
  if (!firebaseAnalytics) {
    return;
  }

  shouldLogReporst() && console.log("Login -", method);
  logEvent(firebaseAnalytics, "login", { method });
}

export function reportSignUp(method: SocialSignInMethod | "email") {
  if (!firebaseAnalytics) {
    return;
  }

  shouldLogReporst() && console.log("SignUp -", method);
  logEvent(firebaseAnalytics, "sign_up", { method });
}

export function reportSignOut() {
  if (!firebaseAnalytics) {
    return;
  }

  shouldLogReporst() && console.log("Sign out");
  logEvent(firebaseAnalytics, "sign_out");
}

export function getScreenName(pathName: string) {
  return pathName.split("/")[1];
}
