import { FileType, IItem, IReceipt } from "@warranty-keeper/common";
import { getItemById } from "../Selectors";
import { updateItemInMap } from "./ItemsMapActions";
import * as ItemFunctions from "../../../firebase/ItemFunctions";
import { MediaFileMethod, RawMediaFile } from "../../../utils/MediaFileUtils";
import * as ReceiptUtils from "../../../utils/ReceiptUtils";
import { logWarning } from "../../../utils/ErrorUtils";
import { IDispatch, IGetState } from "../../../types";

export function addReceiptToItem(itemId: string, rawMediaFile: RawMediaFile) {
  return function (dispatch: IDispatch, getState: IGetState) {
    const item = getItemById(getState())(itemId);
    if (!item) {
      logWarning("No item to add receipt to: " + itemId);
      return;
    }

    const ephemeralReceipt: IReceipt = {
      receiptUrl: rawMediaFile.localUri,
      receiptType:
        rawMediaFile.method === MediaFileMethod.UPLOAD_PDF
          ? FileType.PDF
          : FileType.Image,
      fileName: rawMediaFile.localUri,
    };

    const ephemeralItem: IItem = {
      ...item,
      receipts: [...item.receipts, ephemeralReceipt],
    };
    dispatch(updateItemInMap(ephemeralItem));

    return ReceiptUtils.uploadReceipts(itemId, [rawMediaFile]).then(
      (uploadedReceipt) => {
        if (uploadedReceipt.length !== 1) {
          logWarning(
            "Unexpeted number of additional receipts: " +
              uploadedReceipt.length,
          );
          dispatch(updateItemInMap(item));
          return;
        }
        const updatedItem: IItem = {
          ...item,
          receipts: [...item.receipts, uploadedReceipt[0]],
        };
        dispatch(updateItemInMap(updatedItem));

        ItemFunctions.addReceipt(itemId, uploadedReceipt[0]);
      },
    );
  };
}
