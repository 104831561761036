import NotificationSettingsScreen from "./NotificationSettingsScreen";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationDays,
  getNotificationSettings,
} from "../../store/user/Selectors";
import { updateNotificationSettings } from "../../store/user/actions/UpdateNotificationDaysAction";
import { useNavigate } from "react-router";
import { translate } from "../../locale/Locale";

export default function NotificationSettingsScreenContainer() {
  const dispatch = useDispatch();
  const notificationDays = useSelector(getNotificationDays);
  const notificationSettings = useSelector(getNotificationSettings);
  const navigate = useNavigate();

  const onSave = (
    days: number | undefined,
    enablePushNotifications: boolean,
    enableEmailNotifications: boolean,
    onNotificationDaysError: (error: string) => void,
  ) => {
    if (!days || days < -1 || days > 999) {
      onNotificationDaysError(translate("notifications.invalidNumberOfDays"));
      return;
    }
    dispatch(
      updateNotificationSettings(
        days,
        enablePushNotifications,
        enableEmailNotifications,
      ),
    );
    navigate(-1);
  };

  return (
    <NotificationSettingsScreen
      notificationDays={notificationDays}
      pushNotificationsEnabled={notificationSettings.pushNotificationsEnabled}
      emailNotificationsEnabled={notificationSettings.emailNotificationsEnabled}
      onSave={onSave}
    />
  );
}
