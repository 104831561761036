import styles from "./UpdateAppScreen.module.css";
import React from "react";
import Screen from "../../components/basic/Screen/Screen";
import Button from "../../components/basic/Button";
import { Variant } from "@warranty-keeper/common";
import Illustration from "../../components/basic/Illustration/Illustration";
import UpdateAppLight from "../../assets/illustrations/update-app-light.svg";
import UpdateAppDark from "../../assets/illustrations/update-app-dark.svg";
import { translate } from "../../locale/Locale";

export interface UpdateAppScreenProps {
  updateApp: () => void;
}

export default function UpdateAppScreen(props: UpdateAppScreenProps) {
  return (
    <Screen headerHideBack>
      <div className={styles.updateAppScreen}>
        <Illustration lightPath={UpdateAppLight} darkPath={UpdateAppDark} />
        <div className={styles.title}>
          {translate("updateApp.timeToUpdate")}
        </div>
        <div className={styles.content}>{translate("updateApp.subtitle")}</div>

        <div className={styles.button}>
          <Button
            title={translate("updateApp.updateApp")}
            onClick={props.updateApp}
            variant={Variant.Primary}
            analyticTag={"updateApp"}
          />
        </div>
      </div>
    </Screen>
  );
}
