import React, { TouchEventHandler, useState } from "react";

export interface SwippableComponentProps {
  onClick: () => void;
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
  className?: string;
  children: React.ReactNode;
}

const SWIPE_WIDTH = 80;
export default function SwippableComponent(props: SwippableComponentProps) {
  const [touchStart, setTouchStart] = useState(0);

  const handleTouchStart: TouchEventHandler = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove: TouchEventHandler = (e) => {
    const current = e.targetTouches[0].clientX;
    if (touchStart - current > SWIPE_WIDTH) {
      props.onSwipeLeft();
    } else if (touchStart - current < -SWIPE_WIDTH) {
      props.onSwipeRight();
    }
  };

  return (
    <div
      className={props.className}
      onClick={props.onClick}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      {props.children}
    </div>
  );
}
