import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getItemById } from "../../store/items/Selectors";
import { useParams } from "react-router";
import SuccessScreen from "./SuccessScreen";
import { getNotificationDays } from "../../store/user/Selectors";
import * as PushNotificationUtils from "../../utils/PushNotificationUtils";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/Routes";

export default function SuccessScreenContainer() {
  const { itemId } = useParams<{ itemId: string }>();
  const item = useSelector(getItemById)(itemId);
  const navigate = useNavigate();
  const notificationDays = useSelector(getNotificationDays);
  const [popupOpen, setPopupOpen] = useState(false);

  if (!itemId || !item) {
    navigate(AppRoutes.AllItems);
    return null;
  }

  const onContinue = async () => {
    const ask =
      await PushNotificationUtils.shouldAskForNotificationPermissions();
    if (ask) {
      setPopupOpen(true);
    } else {
      goBack();
    }
  };

  const onNotify = async () => {
    await PushNotificationUtils.askForNotificationPermissions();
    goBack();
  };

  const goBack = () => {
    navigate(-2); // Move back 2 screens, skip the "New Item" screen
  };

  return (
    <SuccessScreen
      itemName={item.name}
      imageUrl={item.imageUrl}
      notificationDays={notificationDays}
      onContinue={onContinue}
      showNotificationPopup={popupOpen}
      onClosePopup={() => setPopupOpen(false)}
      onNotifyMe={onNotify}
      onNotNow={goBack}
    />
  );
}
