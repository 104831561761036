import * as actionTypes from "./ActionTypes";
import { LOGGED_OUT } from "../user/ActionTypes";
import { IItemsMap } from "@warranty-keeper/common";

const emptyObject: IItemsMap = {};

const reducer = (state = emptyObject, action: any = {}) => {
  switch (action.type) {
    case actionTypes.SET_ITEMS:
      return action.items;

    case LOGGED_OUT:
      return emptyObject;

    default:
      return state;
  }
};

export default reducer;
