import { IItem, IReceipt } from "@warranty-keeper/common";
import { getItemById } from "../Selectors";
import { updateItemInMap } from "./ItemsMapActions";
import * as ItemFunctions from "../../../firebase/ItemFunctions";
import { IDispatch, IGetState } from "../../../types";
import { logWarning } from "../../../utils/ErrorUtils";

export function removeReceipt(itemId: string, receipt: IReceipt) {
  return function (dispatch: IDispatch, getState: IGetState) {
    const item = getItemById(getState())(itemId);
    if (!item) {
      logWarning("No item to remove receipt from: " + itemId);
      return;
    }
    const updatedItem: IItem = {
      ...item,
      receipts: item.receipts.filter((r) => r.fileName !== receipt.fileName),
    };

    dispatch(updateItemInMap(updatedItem));

    ItemFunctions.removeReceipt(itemId, receipt.fileName);
  };
}
