import { getUserName } from "../Selectors";
import * as UserFunctions from "../../../firebase/UserFunctions";
import * as actionTypes from "../ActionTypes";
import { IDispatch, IGetState } from "../../../types";
import { reportCustom } from "../../../firebase/FirebaseAnalytics";

export function updateName(newName: string) {
  return function (dispatch: IDispatch, getState: IGetState) {
    if (getUserName(getState()) === newName) {
      return;
    }

    dispatch(updateNameAction(newName));
    UserFunctions.updateUser({
      updatedName: newName,
    });
    reportCustom(`updatedName`);
  };
}

function updateNameAction(newName: string) {
  return {
    type: actionTypes.UPDATE_NAME,
    name: newName,
  };
}
