import styles from "./Stepper.module.scss";
import classNames from "classnames";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import React from "react";

export interface StepperProps {
  steps: number;
  activeStep: number;
  onNext: () => void;
  onPrevious: () => void;
  className?: string;
}

export default function Stepper(props: StepperProps) {
  const circles = [];
  for (let i = 0; i < props.steps; i++) {
    circles.push(i === props.activeStep);
  }

  const hasPrevious = props.activeStep > 0;
  const hasNext = props.activeStep < props.steps - 1;

  return (
    <div className={classNames(styles.container)}>
      <div
        data-test-id={"stepper-previous"}
        onClick={hasPrevious ? props.onPrevious : undefined}
        className={classNames({
          [styles.button]: true,
          [styles.previous]: true,
          [styles.applicable]: hasPrevious,
          [styles.disabled]: !hasPrevious,
        })}
      >
        <ArrowLeft
          className={classNames({
            [styles.chevron]: true,
            [styles.applicable]: hasPrevious,
            [styles.disabled]: !hasPrevious,
          })}
        />
      </div>
      <div className={styles.circles}>
        {circles.map((isActive, index) => (
          <div
            key={"" + index + isActive}
            className={classNames({
              [styles.circle]: true,
              [styles.notActive]: !isActive,
              [styles.active]: isActive,
            })}
          />
        ))}
      </div>
      <div
        data-test-id={"stepper-next"}
        onClick={hasNext ? props.onNext : undefined}
        className={classNames({
          [styles.button]: true,
          [styles.next]: true,
          [styles.applicable]: hasNext,
          [styles.disabled]: !hasNext,
        })}
      >
        <ArrowRight
          className={classNames({
            [styles.chevron]: true,
            [styles.chevronNext]: true,
            [styles.applicable]: hasNext,
            [styles.disabled]: !hasNext,
          })}
        />
      </div>
    </div>
  );
}
