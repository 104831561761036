import { useState } from "react";
import Screen from "../../components/basic/Screen/Screen";
import styles from "./FeedbackScreen.module.css";
import { Input, MultiLineInput } from "../../components/basic/Input";
import Button from "../../components/basic/Button";
import { Variant } from "@warranty-keeper/common";
import Popup from "../../components/basic/Popup";
import Illustration from "../../components/basic/Illustration/Illustration";
import FeedbackLight from "../../assets/illustrations/feedback-light.svg";
import FeedbackDark from "../../assets/illustrations/feedback-dark.svg";
import FeedbackHeartLight from "../../assets/illustrations/feedback-heart-light.svg";
import FeedbackHeartDark from "../../assets/illustrations/feedback-heart-dark.svg";
import { useNavigate } from "react-router-dom";
import {
  isPrivateAppleUser,
  isValidEmail,
} from "../../firebase/AuthenticationUtils";
import { translate } from "../../locale/Locale";

export interface FeedbackScreenProps {
  email: string;
  sendFeedback: (feedback: string, curatedEmail: string) => void;
}

export default function FeedbackScreen(props: FeedbackScreenProps) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [curatedEmail, setCuratedEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const requireEmail = isPrivateAppleUser(props.email);
  const saveEnabled = feedback && (requireEmail ? curatedEmail : true);

  const onSave = () => {
    const email = curatedEmail.trim();
    if (requireEmail && !isValidEmail(email)) {
      setEmailError(translate("authenticationErrors.invalidEmail"));
      return;
    }

    setOpen(true);
    props.sendFeedback(feedback.trim(), email.trim());
  };

  return (
    <Screen headerTitle={translate("feedback.sendFeedback")}>
      <div>
        <div className={styles.title}>{translate("feedback.subtitle")}</div>

        <Illustration
          lightPath={FeedbackLight}
          darkPath={FeedbackDark}
          className={styles.image}
        />

        {requireEmail ? (
          <Input
            value={curatedEmail}
            onChangeText={(newValue) => {
              setCuratedEmail(newValue);
              setEmailError("");
            }}
            label={translate("common.myEmail")}
            error={emailError}
          />
        ) : (
          <div className={styles.email}>
            {translate("common.email")}:{" "}
            <span className={styles.emailAddress}>{props.email}</span>
          </div>
        )}

        <MultiLineInput
          value={feedback}
          onChangeText={setFeedback}
          maxLines={10}
          placeholder={translate("feedback.iWantedToSay")}
        />

        <Button
          title={translate("common.send")}
          variant={Variant.Primary}
          onClick={onSave}
          disabled={!saveEnabled}
          analyticTag={"sendFeedback"}
        />
      </div>

      <Popup
        open={open}
        title={translate("common.thankYou")}
        illustration={
          <Illustration
            lightPath={FeedbackHeartLight}
            darkPath={FeedbackHeartDark}
          />
        }
        buttons={[
          {
            label: translate("common.ok"),
            onClick: () => navigate(-1),
            variant: Variant.Primary,
          },
        ]}
        onClose={() => navigate(-1)}
      >
        <div className={styles.image} />
        <div className={styles.popupText}>{translate("feedback.thanks")}</div>
      </Popup>
    </Screen>
  );
}
