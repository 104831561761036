import React from "react";
import UpdateAppScreen from "./UpdateAppScreen";
import { logWarning } from "../../utils/ErrorUtils";
import { getAppInfo } from "../../firebase/UserFunctions";

export const APP_STORE_LINK =
  "https://apps.apple.com/us/app/warranty-keeper-app/id1459400698";
export const PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.warranty_keeper";

export default function UpdateAppScreenContainer() {
  const updateApp = async () => {
    const appInfo = getAppInfo();
    switch (appInfo.os) {
      case "ios":
        window.open(APP_STORE_LINK);
        break;
      case "android":
        window.open(PLAY_STORE_LINK);
        break;
      case "web":
        logWarning("Update required on web app");
        break;
      default:
        logWarning("Unexpected os for update app: " + appInfo.os);
    }
  };

  return <UpdateAppScreen updateApp={updateApp} />;
}
