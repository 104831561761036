import React from "react";
import AccountSettingsScreen from "./AccountSettingsScreen";
import { useDispatch, useSelector } from "react-redux";
import { getEmail, getUserName } from "../../store/user/Selectors";
import {
  isLoggedInWithEmailAndPassword,
  signOut,
  updateUserPassword,
} from "../../firebase/FirebaseAuthentication";
import { updateName } from "../../store/user/actions/UpdateNameAction";
import { useNavigate } from "react-router";
import * as UserFunctions from "../../firebase/UserFunctions";

export default function AccountSettingsScreenContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = useSelector(getEmail);
  const name = useSelector(getUserName);

  return (
    <AccountSettingsScreen
      email={email}
      name={name}
      updatePasswordEnabled={isLoggedInWithEmailAndPassword()}
      updateName={(newName) => {
        dispatch(updateName(newName));
        navigate(-1);
      }}
      updatePassword={updateUserPassword}
      onDeleteAccount={() => {
        UserFunctions.deleteAccount();
        signOut(dispatch);
      }}
    />
  );
}
