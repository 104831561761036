import { getCallableFunction } from "./FunctionUtils";
import {
  FunctionsApi,
  IItemsMap,
  IUser,
  StringUtils,
} from "@warranty-keeper/common";
import { logError } from "../utils/ErrorUtils";
import packageJson from "../../package.json";
import { Capacitor } from "@capacitor/core";
import { setUserInCache } from "./LocalCache";
import { DateTime } from "luxon";
import { getCurrentLocale } from "../locale/Locale";

export async function loadInitialData(
  onError: () => void,
  setUpdateRequired: (required: boolean) => void,
  onInitialDataLoaded: (
    user: IUser,
    items: IItemsMap,
    updatesPopup?: FunctionsApi.UpdatesPopup,
  ) => void,
): Promise<string | undefined> {
  try {
    const callableFunction = getCallableFunction(
      FunctionsApi.LoadInitialDataFunction,
    );

    const request: FunctionsApi.LoadInitialDataRequest = {
      ...getAppInfo(),
      timezone: DateTime.now().zone.name,
    };

    const startTime = new Date().getTime();
    const response = await callableFunction(request);
    console.log("t", new Date().getTime() - startTime);
    const data = response.data as FunctionsApi.LoadInitialDataResponse;
    if (data.appUpdateRequired) {
      setUpdateRequired(true);
      return;
    }

    onInitialDataLoaded(data.user, data.items, data.popup);
    return data.user.notificationSettings.pushToken;
  } catch (e) {
    logError(e, "Error fetching initial data");
    await onError();
    return;
  }
}

export async function checkVersion(): Promise<FunctionsApi.CheckVersionResponse> {
  try {
    const callableFunction = getCallableFunction(
      FunctionsApi.CheckVersionFunction,
    );

    const userData = getAppInfo();

    const request: FunctionsApi.CheckVersionRequest = {
      ...userData,
    };

    const response = await callableFunction(request);
    return response.data as FunctionsApi.CheckVersionResponse;
  } catch (e) {
    logError(e, "Error checking version");
    return {
      appUpdateRequired: false,
    };
  }
}

export async function createUser(
  name: string | null,
): Promise<FunctionsApi.CreateNewUserResponse> {
  const callableFunction = getCallableFunction(
    FunctionsApi.CreateNewUserFunction,
  );

  const userData = getAppInfo();

  const request: FunctionsApi.CreateNewUserRequest = {
    ...userData,
    name,
    timezone: DateTime.now().zone.name,
  };

  const response = await callableFunction(request);
  return response.data as FunctionsApi.CreateNewUserResponse;
}

export async function updateUser(
  request: FunctionsApi.UpdateUserDataRequest,
): Promise<void> {
  const callableFunction = getCallableFunction(FunctionsApi.UpdateUserFunction);

  const response = await callableFunction(request);
  const data = response.data as FunctionsApi.UpdateUserDataResponse;

  setUserInCache(data.updatedUser);
}

export async function updatePushNotificationToken(
  pushToken: string,
): Promise<void> {
  const callableFunction = getCallableFunction(
    FunctionsApi.UpdatePushTokenFunction,
  );

  const request: FunctionsApi.UpdatePushTokenRequest = {
    pushToken,
  };

  await callableFunction(request);
}

export async function sendExportDataEmail(
  curatedEmail?: string,
): Promise<void> {
  const callableFunction = getCallableFunction(FunctionsApi.ExportDataFunction);

  const request: FunctionsApi.ExportDataRequest = {};

  if (!StringUtils.isEmptyString(curatedEmail)) {
    request.curatedEmail = curatedEmail;
  }
  await callableFunction(request);
}

export async function sendFeedback(
  feedback: string,
  curatedEmail: string,
): Promise<void> {
  const callableFunction = getCallableFunction(
    FunctionsApi.SendFeedbackFunction,
  );

  const request: FunctionsApi.SendFeedbackRequest = {
    feedback,
  };

  if (curatedEmail) {
    request.curatedEmail = curatedEmail;
  }

  await callableFunction(request);
}

export async function deleteAccount() {
  const callableFunction = getCallableFunction(
    FunctionsApi.DeleteAccountFunctionName,
  );

  const request: FunctionsApi.DeleteAccountRequest = {};

  await callableFunction(request);
}

export async function recoverFacebookAccount(email: string) {
  const callableFunction = getCallableFunction(
    FunctionsApi.RecoverFacebookAccountFunction,
  );

  const request: FunctionsApi.RecoverFacebookAccountRequest = {
    email,
  };

  await callableFunction(request);
}

export function getPlatform() {
  return Capacitor.getPlatform();
}

export function isNativePlatform() {
  return Capacitor.isNativePlatform();
}

export function getAppInfo() {
  return {
    os: getPlatform(),
    version: packageJson.version,
    locale: getCurrentLocale(),
  };
}
