import styles from "./SplashScreen.module.css";
import AppLogo from "../../components/basic/AppLogo/AppLogo";
import Spinner from "../../components/basic/Spinner/Spinner";
import Screen from "../../components/basic/Screen/Screen";
import { translate } from "../../locale/Locale";

export default function SplashScreen(props: {
  showNetworkError: boolean;
  version: string;
}) {
  return (
    <Screen headerHideBack noPadding>
      <div className={styles.splashContainer}>
        <div className={styles.splashContent}>
          <AppLogo className={styles.logo} />
          <div className={styles.spinner}>
            <Spinner />
          </div>
        </div>

        {props.showNetworkError && (
          <div className={styles.error}>
            {translate("authenticationErrors.networkRequestFailed")}
          </div>
        )}

        <div className={styles.versionNumber}>
          {translate("settings.version") + " " + props.version}
        </div>
      </div>
    </Screen>
  );
}
