import React, { useContext } from "react";
import LogoImage from "../../../assets/app-icon/app-icon.svg";
import LogoImageDark from "../../../assets/app-icon/app-icon-dark.svg";
import { Theme, ThemeContext } from "../../../theme/ThemeHelper";

export default function AppLogo(props: { className?: string }) {
  const { theme } = useContext(ThemeContext);

  const image = theme === Theme.DARK ? LogoImageDark : LogoImage;

  return (
    <img src={image} alt={"Warranty Keeper"} className={props.className} />
  );
}
