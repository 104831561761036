import React from "react";
import styles from "./TextButton.module.css";
import classNames from "classnames";
import { Variant } from "@warranty-keeper/common";

export interface TextButtonProps {
  title: string;
  onClick: () => void;
  className?: string;
  variant?: Variant;
}

export default function TextButton(props: TextButtonProps) {
  const classes = [props.className, styles.button];

  classes.push(styles.clickable);

  switch (props.variant) {
    default:
    case Variant.Primary:
      classes.push(styles.primary);
      break;
    case Variant.Secondary:
      classes.push(styles.secondary);
      break;
    case Variant.Info:
      classes.push(styles.info);
      break;
  }

  return (
    <div className={classNames(classes)} onClick={props.onClick}>
      {props.title}
    </div>
  );
}
