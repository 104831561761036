import * as UserFunctions from "../../../firebase/UserFunctions";
import * as actionTypes from "../ActionTypes";
import { IDispatch, IGetState } from "../../../types";
import {
  DateFormat,
  FirstDayOfWeek,
  SortMethod,
} from "@warranty-keeper/common";
import {
  getDateFormat,
  getDefaultWarrantyMonths,
  getFirstDayOfWeek,
  getSortingMethod,
} from "../Selectors";
import { reportCustom } from "../../../firebase/FirebaseAnalytics";

export function updateGeneralSettings(
  firstDayOfWeek: FirstDayOfWeek,
  dateFormat: DateFormat,
  defaultWarrantyMonths: number,
  sortingMethod: SortMethod,
) {
  return function (dispatch: IDispatch, getState: IGetState) {
    // Must report before state changes
    if (firstDayOfWeek !== getFirstDayOfWeek(getState())) {
      reportCustom(`updatedFirstDayOfWeek-${firstDayOfWeek}`);
    }
    if (dateFormat !== getDateFormat(getState())) {
      reportCustom(`updatedDateFormat-${dateFormat}`);
    }
    if (defaultWarrantyMonths !== getDefaultWarrantyMonths(getState())) {
      reportCustom(`updatedDefaultWarrantyMonths-${defaultWarrantyMonths}`);
    }
    if (sortingMethod !== getSortingMethod(getState())) {
      reportCustom(`updatedSortingMethod-${sortingMethod}`);
    }

    dispatch({
      type: actionTypes.UPDATE_GENERAL_SETTINGS,
      defaultWarrantyMonths,
      dateFormat,
      firstDayOfWeek,
      sortingMethod,
    });
    UserFunctions.updateUser({
      updatedDefaultWarrantyMonths: defaultWarrantyMonths,
      updatedFirstDayOfWeek: firstDayOfWeek,
      updatedDateFormat: dateFormat,
      updatedSortingMethod: sortingMethod,
    });
  };
}
