import { useEffect, useState } from "react";
import {
  initFirebase,
  LoginStatus,
  registerAuthChange,
} from "../firebase/FirebaseInitializer";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Navigate, useLocation } from "react-router";
import { AppRoutes, AuthenticationRoutes } from "./Routes";
import SignInScreenContainer from "../screens/authentication/SignInScreenContainer";
import EmailSignInScreenContainer from "../screens/authentication/EmailSignInScreenContainer";
import RegisterScreenContainer from "../screens/authentication/RegisterScreenContainer";
import ResetPasswordScreenContainer from "../screens/authentication/ResetPasswordScreenContainer";
import { useDispatch } from "react-redux";
import CategoryScreenContainer from "../screens/category/CategoryScreenContainer";
import ItemScreenContainer from "../screens/item/ItemScreenContainer";
import NewItemScreenContainer from "../screens/item/NewItemScreenContainer";
import UpdateAppScreenContainer from "../screens/update/UpdateAppScreenContainer";
import NotificationSettingsScreenContainer from "../screens/settings/NotificationSettingsScreenContainer";
import LanguageScreenContainer from "../screens/settings/LanguageScreenContainer";
import AccountSettingsScreenContainer from "../screens/settings/AccountSettingsScreenContainer";
import FeedbackScreenContainer from "../screens/settings/FeedbackScreenContainer";
import ReceiptScreenContainer from "../screens/receipt/ReceiptScreenContainer";
import SuccessScreenContainer from "../screens/success/SuccessScreenContainer";
import ExportDataScreenContainer from "../screens/settings/ExportDataScreenContainer";
import EditItemScreenContainer from "../screens/item/EditItemScreenContainer";
import { getPerformance } from "firebase/performance";
import { reportScreen } from "../firebase/FirebaseAnalytics";
import AboutScreenContainer from "../screens/settings/AboutScreenContainer";
import SplashScreenContainer from "../screens/splash/SplashScreenContainer";
import OpenSourceCreditsScreen from "../screens/settings/OpenSourceCreditsScreen";
import { FunctionsApi } from "@warranty-keeper/common";
import AppUrlListener from "./UrlListener";
import GeneralSettingsScreenContainer from "../screens/settings/GeneralSettingsScreenContainer";
import HomeScreenContainer from "../screens/home/HomeScreenContainer";
import ItemImageScreenContainer from "../screens/itemImage/ItemImageScreenContainer";
import FacebookRecoverScreenContainer from "../screens/authentication/FacebookRecoverScreenContainer";

export default function AppRouter() {
  return (
    <HashRouter>
      <AppContent />
      <AppUrlListener />
    </HashRouter>
  );
}

function AppContent() {
  const [loginStatus, setLoginStatus] = useState(LoginStatus.UNKNOWN);
  const [isFetching, setIsFetching] = useState(false);
  const [updateRequired, setUpdateRequired] = useState(false);
  const [updatesPopup, setUpdatesPopup] = useState<FunctionsApi.UpdatesPopup>();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const app = initFirebase();
    registerAuthChange(
      setLoginStatus,
      setIsFetching,
      setUpdateRequired,
      setUpdatesPopup,
      dispatch,
    );
    getPerformance(app);
  }, [dispatch]);

  useEffect(() => {
    if (loginStatus === LoginStatus.UNKNOWN) {
      return;
    }
    reportScreen(location.pathname);
  }, [location, loginStatus]);

  if (updateRequired) {
    reportScreen(AppRoutes.UpdateApp);
    return <UpdateAppScreenContainer />;
  }

  if (loginStatus === LoginStatus.UNKNOWN || isFetching) {
    return <SplashScreenContainer />;
  }

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return (
      <Routes>
        <Route
          path={AuthenticationRoutes.SignIn}
          element={<SignInScreenContainer />}
        />
        <Route
          path={AuthenticationRoutes.EmailSignIn}
          element={<EmailSignInScreenContainer />}
        />
        <Route
          path={AuthenticationRoutes.Register}
          element={<RegisterScreenContainer />}
        />
        <Route
          path={AuthenticationRoutes.ResetPassword}
          element={<ResetPasswordScreenContainer />}
        />
        <Route
          path={AuthenticationRoutes.FacebookHelp}
          element={<FacebookRecoverScreenContainer />}
        />
        <Route
          path="*"
          element={<Navigate to={AuthenticationRoutes.SignIn} />}
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path={AppRoutes.Home}
        element={<Navigate to={AppRoutes.AllItems} />}
      />
      {[AppRoutes.AllItems, AppRoutes.Categories].map((route) => (
        <Route
          key={route}
          path={route}
          element={
            <HomeScreenContainer
              updatesPopup={updatesPopup}
              closeUpdatesPopup={() => setUpdatesPopup(undefined)}
            />
          }
        />
      ))}
      <Route
        path={AppRoutes.Category + "/:categoryKey"}
        element={<CategoryScreenContainer />}
      />
      <Route
        path={AppRoutes.Item + "/:itemId"}
        element={<ItemScreenContainer />}
      />
      <Route
        path={AppRoutes.ItemImage + "/:itemId"}
        element={<ItemImageScreenContainer />}
      />
      <Route
        path={AppRoutes.Receipt + "/:itemId"}
        element={<ReceiptScreenContainer />}
      />
      <Route
        path={AppRoutes.EditItem + "/:itemId"}
        element={<EditItemScreenContainer />}
      />
      <Route path={AppRoutes.NewItem}>
        <Route path={""} element={<NewItemScreenContainer />} />
        <Route path={":categoryKey"} element={<NewItemScreenContainer />} />
      </Route>
      <Route
        path={AppRoutes.Success + "/:itemId"}
        element={<SuccessScreenContainer />}
      />
      <Route
        path={AppRoutes.Account}
        element={<AccountSettingsScreenContainer />}
      />
      <Route
        path={AppRoutes.GeneralSettings}
        element={<GeneralSettingsScreenContainer />}
      />
      <Route path={AppRoutes.Language} element={<LanguageScreenContainer />} />
      <Route
        path={AppRoutes.ExportData}
        element={<ExportDataScreenContainer />}
      />
      <Route
        path={AppRoutes.Notifications}
        element={<NotificationSettingsScreenContainer />}
      />
      <Route path={AppRoutes.Feedback} element={<FeedbackScreenContainer />} />
      <Route path={AppRoutes.About} element={<AboutScreenContainer />} />
      <Route path={AppRoutes.Credits} element={<OpenSourceCreditsScreen />} />
      <Route path="*" element={<Navigate to={AppRoutes.AllItems} />} />
    </Routes>
  );
}
