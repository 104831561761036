import Illustration from "../../components/basic/Illustration/Illustration";
import ErrorLight from "../../assets/illustrations/error-light.svg";
import ErrorDark from "../../assets/illustrations/error-dark.svg";
import Screen from "../../components/basic/Screen/Screen";
import styles from "./ErrorScreen.module.css";
import { useNavigate } from "react-router";
import Button from "../../components/basic/Button";
import { translate } from "../../locale/Locale";

export default function ErrorScreen() {
  const navigate = useNavigate();
  return (
    <Screen headerTitle={translate("error.error")} headerHideBack>
      <div className={styles.container}>
        <Illustration lightPath={ErrorLight} darkPath={ErrorDark} />
        <div className={styles.title}> {translate("error.oops")}</div>
        <div className={styles.subtitle}>
          {translate("error.somethingWentWrong")}
        </div>
        <Button
          title={translate("common.goBack")}
          onClick={() => navigate(-1)}
          className={styles.button}
          analyticTag={"error/goBack"}
        />
      </div>
    </Screen>
  );
}
