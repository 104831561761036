import React from "react";
import OnlineImageSelectorScreen from "./OnlineImageSelectorScreen";
import { logError } from "../../utils/ErrorUtils";
import * as ItemFunctions from "../../firebase/ItemFunctions";

export interface OnlineImageSelectorScreenContainerProps {
  itemName: string;
  onSelectedImage: (imageUrl: string) => void;
  onBack: () => void;
}

export default function OnlineImageSelectorScreenContainer(
  props: OnlineImageSelectorScreenContainerProps,
) {
  const search = async (searchText: string, index: number) => {
    try {
      return await ItemFunctions.searchOnlineImages(searchText, index);
    } catch (e) {
      logError(e, "Error searching images: " + searchText);
      return [];
    }
  };

  return (
    <OnlineImageSelectorScreen
      initialSearchText={props.itemName}
      onSelectedImage={props.onSelectedImage}
      newSearch={(searchText) => search(searchText, 0)}
      getMoreImages={(searchText, pressIndex) => search(searchText, pressIndex)}
      onBack={props.onBack}
    />
  );
}
