import { DateUtils } from "@warranty-keeper/common";
import styles from "./ItemProgressBar.module.css";
import classNames from "classnames";
import { DateTime } from "luxon";

export interface ItemProgressBarProps {
  purchaseDateMillis: number;
  warrantyVoidsDateMillis: number;
  className?: string;
}

export default function ItemProgressBar(props: ItemProgressBarProps) {
  const progress = getProgress(
    props.purchaseDateMillis,
    props.warrantyVoidsDateMillis,
  );

  let widthPercentage: number;
  const classes = [styles.common];

  if (progress === 1) {
    // Expires today
    widthPercentage = 100;
    classes.push(styles.expiresToday);
  } else if (progress > 1) {
    widthPercentage = 100;
    classes.push(styles.expired);
  } else if (progress >= 0.9) {
    widthPercentage = Math.max((1 - progress) * 100, 2); // Without this, 1 day left looks really bad
    classes.push(styles.aboutToExpire);
  } else if (progress >= 0.75) {
    widthPercentage = (1 - progress) * 100;
    classes.push(styles.expireSoon);
  } else {
    widthPercentage = (1 - progress) * 100;
    classes.push(styles.normal);
  }

  return (
    <div className={styles.container}>
      <div className={classNames(styles.common, styles.barBackground)} />
      <div
        className={classNames(classes)}
        style={{ width: widthPercentage + "%" }}
      />
    </div>
  );
}

function getProgress(
  purchaseDateMillis: number,
  warrantyVoidsDateMillis: number,
): number {
  const today = DateUtils.Today();

  if (today < DateTime.fromMillis(purchaseDateMillis)) {
    // Item was not purchased yet
    return 0;
  }
  if (today.equals(DateTime.fromMillis(warrantyVoidsDateMillis))) {
    return 1;
  }

  if (today.toMillis() > warrantyVoidsDateMillis) return 2;

  const totalWarrantyTime = warrantyVoidsDateMillis - purchaseDateMillis;
  const pastWarrantyTime = today.toMillis() - purchaseDateMillis;
  return roundToFourDigits(pastWarrantyTime / totalWarrantyTime);
}

// This makes tests more deterministic
function roundToFourDigits(value: number) {
  const str = (value + "").substr(0, 5);
  return parseFloat(str);
}
