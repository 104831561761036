import { IItemsMap, IUser } from "@warranty-keeper/common";
import { Preferences } from "@capacitor/preferences";
import { getAppInfo } from "./UserFunctions";

const USER_AND_ITEMS_CACHE_KEY = "WKAppDataCache";
const PRINT_CACHE_LOGS = false;

type LocalCache = {
  user: IUser;
  items: IItemsMap;
  version: string;
  timestamp: number;
};

export async function loadFromCache(
  setData: (user: IUser, items: IItemsMap) => void,
) {
  const startTime = new Date().getTime();
  const cacheData = await readFromCache();
  const shouldUse = shouldUseCache(cacheData);
  if (shouldUse) {
    setData(cacheData!.user, cacheData!.items);
  }
  console.log("c", new Date().getTime() - startTime, shouldUse ? "c" : "x");
}

function shouldUseCache(cacheData?: LocalCache) {
  if (!cacheData) {
    return false;
  }

  return (
    new Date().getTime() - (cacheData.timestamp || 0) < 14 * 24 * 60 * 60 * 1000
  );
}

export function setCacheData(user: IUser, items: IItemsMap) {
  const data: LocalCache = {
    user,
    items,
    version: getAppInfo().version,
    timestamp: new Date().getTime(),
  };

  log("Setting cache", data);
  log("Setting cache name", user.name);
  Preferences.set({
    key: USER_AND_ITEMS_CACHE_KEY,
    value: JSON.stringify(data),
  });
}

export function clearCacheData() {
  log("Clearing cache");
  Preferences.remove({
    key: USER_AND_ITEMS_CACHE_KEY,
  });
}

async function readFromCache() {
  const cacheData = await Preferences.get({
    key: USER_AND_ITEMS_CACHE_KEY,
  });

  if (!cacheData.value) {
    log("No cache");
    return undefined;
  }

  const res = JSON.parse(cacheData.value) as LocalCache;
  log("Read from cache", res);

  return res;
}

function log(message?: any, ...optionalParams: any[]) {
  if (PRINT_CACHE_LOGS) {
    const time = new Date().toLocaleTimeString("he", {
      second: "2-digit",
      fractionalSecondDigits: 3,
    });
    console.log(time, message, optionalParams);
  }
}

// Called when items have changed, update cache only if exists already
export function setItemsInCache(items: IItemsMap) {
  log("Request to update items cache");
  readFromCache().then((cacheData) => {
    if (cacheData) {
      setCacheData(cacheData.user, items);
    }
  });
}

// Called when user fields have changed, update cache only if exists already
export function setUserInCache(user: IUser) {
  log("Request to update user cache");
  readFromCache().then((cacheData) => {
    if (cacheData) {
      setCacheData(user, cacheData.items);
    }
  });
}
