import * as actionTypes from "./ActionTypes";
import { UPDATE_USER_FROM_DB } from "./ActionTypes";
import {
  CategoryKey,
  DateFormat,
  FirstDayOfWeek,
  IUser,
  SortMethod,
} from "@warranty-keeper/common";

const emptyState: IUser = {
  email: "",
  name: "",
  categories: [] as CategoryKey[],
  notificationDays: 30,
  sortingMethod: SortMethod.LastChange,
  notificationSettings: {},
  generalSettings: {
    dateFormat: DateFormat.DDMMYYYY,
    defaultWarrantyMonths: 12,
    firstDayOfWeek: FirstDayOfWeek.Sunday,
  },
};

const reducer = (state = emptyState, action: any = {}) => {
  switch (action.type) {
    case UPDATE_USER_FROM_DB:
      return {
        ...state,
        email: action.user.email,
        name: action.user.name,
        categories: action.user.categories,
        notificationDays: action.user.notificationDays,
        notificationSettings: action.user.notificationSettings,
        generalSettings: action.user.generalSettings,
        sortingMethod: action.user.sortingMethod
          ? action.user.sortingMethod
          : SortMethod.LastChange,
      };

    case actionTypes.UPDATE_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };

    case actionTypes.UPDATE_NAME:
      return {
        ...state,
        name: action.name,
      };

    case actionTypes.UPDATE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationDays: action.notificationDays,
        notificationSettings: action.notificationSettings,
      };

    case actionTypes.UPDATE_GENERAL_SETTINGS:
      return {
        ...state,
        generalSettings: {
          defaultWarrantyMonths: action.defaultWarrantyMonths,
          dateFormat: action.dateFormat,
          firstDayOfWeek: action.firstDayOfWeek,
        },
        sortingMethod: action.sortingMethod,
      };

    case actionTypes.LOGGED_OUT:
      return emptyState;

    default:
      return state;
  }
};

export default reducer;
