import React from "react";
import styles from "./Fab.module.scss";
import { ReactComponent as FabIcon } from "../../../assets/icons/fab.svg";
import classNames from "classnames";
import { isProduction } from "../../../firebase/FirebaseConfig";

export interface FabProps {
  onClick: () => void;
  className?: string;
  nonFixedPosition?: boolean;
}

export default function Fab(props: FabProps) {
  const FabElement = (
    <div
      data-test-id={"fab"}
      className={classNames(styles.fabContainer, props.className)}
      onClick={props.onClick}
    >
      <FabIcon
        className={isProduction() ? styles.iconColorProd : styles.iconColorDev}
      />
    </div>
  );

  if (props.nonFixedPosition) {
    return FabElement;
  }

  return <div className={styles.fixedContainer}>{FabElement}</div>;
}
