import {
  CategoryKey,
  ICategoryPreview,
  IItem,
  DateFormat,
  FunctionsApi,
  Variant,
  SortMethod,
} from "@warranty-keeper/common";
import UpdatesLight from "../../assets/illustrations/updates-light.svg";
import UpdatesDark from "../../assets/illustrations/updates-dark.svg";
import Popup from "../../components/basic/Popup";
import Drawer from "../../components/basic/Drawer/Drawer";
import Screen from "../../components/basic/Screen/Screen";
import Illustration from "../../components/basic/Illustration/Illustration";
import Tabs from "../../components/basic/Tabs/Tabs";
import { AppRoutes } from "../../router/Routes";
import AllItemsTab from "../allItems/AllItemsTab";
import CategoriesTab from "../categories/CategoriesTab";
import SettingsMenu from "../settings/SettingsMenu";
import { useState } from "react";
import IconButton from "../../components/basic/IconButton";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { IconColorField } from "../../components/basic/IconButton/IconButton";
import styles from "./HomeScreen.module.scss";
import { translate } from "../../locale/Locale";

export interface HomeScreenProps {
  route: string;
  navigate: (route: AppRoutes) => void;
  userName: string;
  items: IItem[];
  updatesPopup?: FunctionsApi.UpdatesPopup;
  closeUpdatesPopup: () => void;
  sortingMethod: SortMethod;
  dateFormat: DateFormat;
  onClickOnItem: (item: IItem) => void;

  categories: ICategoryPreview[];
  onAddItem: () => void;
  onAddCategory: (
    categoryKey: CategoryKey,
    onSuccess: () => void,
    onError: (m: string) => void,
  ) => void;
  onClickCategory: (categoryKey: CategoryKey) => void;
  onDeleteCategory: (categoryKey: CategoryKey) => void;
  rateApp: () => void;
  onSignOut: () => void;
}

export default function HomeScreen(props: HomeScreenProps) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const allItemsTitle =
    props.items.length > 0
      ? translate("bottomMenu.allItems") + " (" + props.items.length + ")"
      : translate("bottomMenu.allItems");

  const MenuButton = (
    <IconButton
      onClick={() => setDrawerOpen(true)}
      icon={MenuIcon}
      iconColorField={IconColorField.STROKE}
      className={styles.menuButton}
      analyticTag={"home/menu"}
      testId="menuButton"
    />
  );

  return (
    <Screen
      noPadding
      headerTitle={translate("allItems.welcome", { name: props.userName })}
      headerHideBack
      leftButton={MenuButton}
      noBottomPadding
    >
      <Drawer open={drawerOpen} close={() => setDrawerOpen(false)}>
        <SettingsMenu
          onRateApp={props.rateApp}
          onSignOut={props.onSignOut}
          userHasItems={props.items.length > 0}
        />
      </Drawer>

      <Tabs
        route={props.route as AppRoutes}
        onTabPressed={props.navigate}
        tabs={[
          {
            title: allItemsTitle,
            route: AppRoutes.AllItems,
            children: (
              <AllItemsTab
                dateFormat={props.dateFormat}
                items={props.items}
                onAddItem={props.onAddItem}
                onClickOnItem={props.onClickOnItem}
                sortingMethod={props.sortingMethod}
              />
            ),
          },
          {
            title: translate("bottomMenu.categories"),
            route: AppRoutes.Categories,
            children: (
              <CategoriesTab
                categories={props.categories}
                onAddCategory={props.onAddCategory}
                onClickCategory={props.onClickCategory}
                onDeleteCategory={props.onDeleteCategory}
              />
            ),
          },
        ]}
      />

      <Popup
        open={!!props.updatesPopup}
        title={props.updatesPopup?.title || ""}
        content={props.updatesPopup?.content}
        onClose={props.closeUpdatesPopup}
        illustration={
          <Illustration lightPath={UpdatesLight} darkPath={UpdatesDark} />
        }
        buttons={[
          {
            label: props.updatesPopup?.buttonText || translate("common.ok"),
            onClick: props.closeUpdatesPopup,
            variant: Variant.Primary,
            analyticTag: "updatesPopup/ok",
          },
        ]}
      />
    </Screen>
  );
}
