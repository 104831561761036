import React from "react";
import ExportDataScreen from "./ExportDataScreen";
import * as UserFunctions from "../../firebase/UserFunctions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getEmail } from "../../store/user/Selectors";

export default function ExportDataScreenContainer() {
  const navigate = useNavigate();
  const email = useSelector(getEmail);
  return (
    <ExportDataScreen
      sendExportDataEmail={(curatedEmail) => {
        UserFunctions.sendExportDataEmail(curatedEmail);
      }}
      email={email}
      goBack={() => navigate(-1)}
    />
  );
}
