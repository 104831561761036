import React from "react";
import Header from "../Header/Header";
import styles from "./Screen.module.css";
import classNames from "classnames";

export interface ScreenProps {
  headerTitle?: string;
  headerHideBack?: boolean;
  children?: React.ReactNode;
  showBottomMenu?: boolean;
  onBack?: () => void;
  stickyComponent?: React.ReactNode;
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
  noPadding?: boolean;
  noBottomPadding?: boolean;
}

export default function Screen(props: ScreenProps) {
  return (
    <div className={styles.screenContainer}>
      <Header
        title={props.headerTitle}
        hideBack={props.headerHideBack}
        onBack={props.onBack}
        leftButton={props.leftButton}
        rightButton={props.rightButton}
      />

      {props.stickyComponent && (
        <div className={styles.horizontalPadding}>{props.stickyComponent}</div>
      )}

      <div
        className={classNames({
          [styles.screenContent]: true,
          [styles.horizontalPadding]: !props.noPadding,
          [styles.bottomPadding]: !props.noBottomPadding,
        })}
      >
        {props.children}
      </div>
    </div>
  );
}
