import React from "react";
import styles from "./Button.module.css";
import classNames from "classnames";
import Spinner from "../Spinner/Spinner";
import { Variant } from "@warranty-keeper/common";
import { reportClick } from "../../../firebase/FirebaseAnalytics";

export interface ButtonProps {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
  loading?: boolean;
  variant?: Variant;
  analyticTag?: string;
}

export default function Button(props: ButtonProps) {
  const disabled = props.disabled || props.loading;

  const classes = [props.className, styles.button];

  if (disabled) {
    classes.push(styles.disabled);
  } else {
    classes.push(styles.clickable);

    switch (props.variant) {
      case Variant.Primary:
        classes.push(styles.primary);
        break;
      case Variant.Secondary:
      default:
        classes.push(styles.secondary);
        break;
      case Variant.Info:
        classes.push(styles.info);
        break;
    }
  }

  const onClick = () => {
    if (disabled) {
      return;
    }

    props.onClick();

    if (props.analyticTag) {
      reportClick(props.analyticTag);
    }
  };

  return (
    <div className={styles.buttonContainer}>
      <div
        className={classNames(classes)}
        onClick={onClick}
        data-test-id={"button-" + props.title}
      >
        {props.loading ? <Spinner /> : props.title}
      </div>
    </div>
  );
}
