import { useState } from "react";
import { CategoryKey } from "@warranty-keeper/common";
import SearchBar from "../../basic/SearchBar/SearchBar";
import styles from "./CategoryPicker.module.scss";
import InputWrapper from "../../basic/Input/InputWrapper";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import classNames from "classnames";
import commonStyles from "../../basic/Input/style.module.scss";
import { PickerOptionData } from "../../picker/PickerOption/PickerOption";
import PickerModal from "../../picker/PickerModal/PickerModal";
import { translate } from "../../../locale/Locale";

export interface CategoryPickerProps {
  initialOption?: PickerOptionData<CategoryKey>;
  categoryOptions: PickerOptionData<CategoryKey>[];
  onCategorySelected: (selectedOption: CategoryKey) => void;
  error?: string;
}

export default function CategoryPicker(props: CategoryPickerProps) {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(props.initialOption);
  const [searchText, setSearchText] = useState("");

  const CategoryPickerHeader = (
    <>
      <div className={styles.title}>{translate("item.category")}</div>
      <div className={styles.searchBar}>
        <SearchBar
          value={searchText}
          onTextChange={setSearchText}
          onSearch={setSearchText}
        />
      </div>
    </>
  );

  const filteredOptions = searchText
    ? props.categoryOptions.filter(
        (option) =>
          option.text
            .toLocaleLowerCase()
            .indexOf(searchText.toLocaleLowerCase().trim()) !== -1,
      )
    : props.categoryOptions;

  return (
    <>
      {PickerInput(props, () => setShowModal(true), selected)}

      <PickerModal
        header={CategoryPickerHeader}
        options={filteredOptions}
        selected={selected}
        onSelected={(selectedOption) => {
          setSelected(selectedOption);
          props.onCategorySelected(selectedOption.value);
          setSearchText("");
        }}
        open={showModal}
        onClose={() => setShowModal(false)}
        noOptionsText={translate("categories.noCategoriesFound")}
        heightNumberOfRows={props.categoryOptions.length + 3}
        hideDivider
      />
    </>
  );
}

function PickerInput(
  props: CategoryPickerProps,
  openModal: () => void,
  selected?: PickerOptionData<CategoryKey>,
) {
  return (
    <InputWrapper
      label={translate("item.category")}
      endIcon={<ArrowRight onClick={openModal} className={styles.icon} />}
      error={props.error}
      showBigLabel={!selected}
    >
      <div
        className={classNames(styles.categoryInputField, commonStyles.input)}
        onClick={openModal}
        data-test-id="categoryPickerInput"
      >
        {selected?.text}
      </div>
    </InputWrapper>
  );
}
