import React from "react";

const WK_THEME_KEY = "WK_THEME_KEY";
export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

export const ThemeContext = React.createContext({
  theme: Theme.LIGHT,
  setTheme: (theme: Theme) => {},
});

export function getThemeFromLocalStorage(): Theme {
  return localStorage.getItem(WK_THEME_KEY) === Theme.DARK
    ? Theme.DARK
    : Theme.LIGHT;
}

export const setThemeInLocalStorage = (theme: Theme) => {
  localStorage.setItem(WK_THEME_KEY, theme);
};
