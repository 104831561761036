import PickerModal from "../../picker/PickerModal/PickerModal";
import React, { useState } from "react";
import { MediaFileMethod, RawMediaFile } from "../../../utils/MediaFileUtils";
import { PickerOptionData } from "../../picker/PickerOption/PickerOption";
import * as MediaFileUtils from "../../../utils/MediaFileUtils";
import { logWarning } from "../../../utils/ErrorUtils";
import styles from "./MediaField.module.scss";
import OnlineImageSelectorScreenContainer from "../../../screens/itemImage/OnlineImageSelectorScreenContainer";
import { ReactComponent as CameraIcon } from "../../../assets/icons/camera.svg";
import { ReactComponent as PictureIcon } from "../../../assets/icons/picture.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/icons/document.svg";
import { ReactComponent as GlobeIcon } from "../../../assets/icons/globe.svg";
import { translate } from "../../../locale/Locale";

interface MediaPickerModalProps {
  open: boolean;
  onClose: () => void;
  methods: MediaFileMethod[];
  onLocalFilePicked: (localFile: RawMediaFile | undefined) => void;
  setError: (error: string) => void;
  hintText?: string; // Probably the item name, to be used by the online image selector
}

const MAX_PDF_SIZE_MB = 2;

export default function MediaPickerModal(props: MediaPickerModalProps) {
  const [showImageSelector, setShowImageSelector] = useState(false);

  const hiddenFileInput = React.useRef(null);
  // @ts-ignore
  const clearFileInput = () => (hiddenFileInput.current.value = "");

  const setError = (error: string) => {
    if (props.setError) {
      props.setError(error);
    }
  };

  const onMethodSelected = async (method: MediaFileMethod) => {
    setError("");
    if (method === MediaFileMethod.SEARCH_ONLINE) {
      setShowImageSelector(true);
      return;
    }
    if (method === MediaFileMethod.UPLOAD_PDF) {
      // @ts-ignore
      hiddenFileInput.current.click();
      return;
    }

    const localFile = await MediaFileUtils.getRawImageFile(method);
    props.onLocalFilePicked(localFile);
  };

  return (
    <>
      <PickerModal
        options={props.methods.map(getMethodOption)}
        onSelected={(selectedOption) => onMethodSelected(selectedOption.value)}
        open={props.open}
        onClose={props.onClose}
      />

      {/*Input for PDF file uploading*/}
      <input
        data-test-id={"input-PDF-Picker"}
        ref={hiddenFileInput}
        type={"file"}
        accept={"application/pdf"}
        style={{ display: "none" }}
        onChange={(e) => {
          const files = e.target.files;
          if (!files || files.length === 0) {
            clearFileInput();
            return;
          }

          if (files.length > 1) {
            clearFileInput();
            logWarning("User tried uploading multiple PDF files");
            return;
          }

          const file = files[0];
          if (!file.name.toLowerCase().endsWith(".pdf")) {
            clearFileInput();
            setError(translate("filePicker.invalidFile"));
            return;
          }

          const sizeMb = file.size / 1_000_000;
          if (sizeMb > MAX_PDF_SIZE_MB) {
            clearFileInput();
            setError(
              translate("filePicker.maxFileSize", { X: MAX_PDF_SIZE_MB }),
            );
            return;
          }

          const localUri = URL.createObjectURL(files[0]);
          props.onLocalFilePicked({
            method: MediaFileMethod.UPLOAD_PDF,
            localUri: localUri,
          });
        }}
      />

      {/*Online image selector*/}
      {showImageSelector && (
        <div className={styles.onlineImageSelector}>
          <OnlineImageSelectorScreenContainer
            itemName={props.hintText || ""}
            onSelectedImage={(url) => {
              props.onLocalFilePicked({
                method: MediaFileMethod.SEARCH_ONLINE,
                localUri: url,
              });
              setShowImageSelector(false);
            }}
            onBack={() => setShowImageSelector(false)}
          />
        </div>
      )}
    </>
  );
}

function getMethodOption(
  method: MediaFileMethod,
): PickerOptionData<MediaFileMethod> {
  const res: PickerOptionData<MediaFileMethod> = {
    text: "",
    key: method,
    value: method,
  };

  switch (method) {
    case MediaFileMethod.SEARCH_ONLINE:
      res.text = translate("filePicker.searchImageOnline");
      res.icon = GlobeIcon;
      break;
    case MediaFileMethod.TAKE_PHOTO:
      res.text = translate("filePicker.takeAPhoto");
      res.icon = CameraIcon;
      break;
    case MediaFileMethod.UPLOAD_IMAGE:
      res.text = translate("filePicker.pickFromGallery");
      res.icon = PictureIcon;
      break;
    case MediaFileMethod.UPLOAD_PDF:
      res.text = translate("filePicker.uploadPDF");
      res.icon = DocumentIcon;
      break;
    case MediaFileMethod.PREVIOUSLY_SELECTED:
      throw Error("Invalid file method");
  }

  return res;
}
