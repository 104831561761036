import { getFunctions, httpsCallable } from "firebase/functions";
import { logError } from "../utils/ErrorUtils";

export function getCallableFunction(
  functionName: string,
): (request: any) => Promise<any> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, functionName);
  return async (request: any) => {
    try {
      if (process.env.NODE_ENV === "development") {
        console.log(`Calling ${functionName} with ${JSON.stringify(request)}`);
      }
      return await callable(request);
    } catch (e) {
      const newError = new Error("Failed calling " + functionName);
      logError(
        newError,
        `Error calling ${functionName} with ${JSON.stringify(
          request,
        )} due to ${e}`,
      );
      throw newError;
    }
  };
}
