import "./locale/Locale";
import * as Sentry from "@sentry/react";
import Div100vh from "react-div-100vh";
import "./theme/variables.scss";
import AppRouter from "./router/AppRouter";
import { Provider } from "react-redux";
import { store } from "./store/StoreCreator";
import LocaledComponent from "./locale/LocaledComponent";
import React from "react";
import ErrorScreen from "./screens/error/ErrorScreen";
import packageJson from "../package.json";
import ThemeContainer from "./theme/ThemeContainer";

Sentry.init({
  dsn: "https://d4559cc8008a45d0834a54243787a4f8@o222204.ingest.sentry.io/5792355",
  environment: process.env.NODE_ENV,
  release: packageJson.version,
});

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
      <Provider store={store}>
        <ThemeContainer>
          <LocaledComponent>
            <Div100vh>
              <AppRouter />
            </Div100vh>
          </LocaledComponent>
        </ThemeContainer>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
