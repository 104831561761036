import { DateUtils, IItem, FunctionsApi } from "@warranty-keeper/common";
import { getItemById } from "../Selectors";
import { updateItemInMap } from "./ItemsMapActions";
import * as ItemFunctions from "../../../firebase/ItemFunctions";
import { SaveItemArguments } from "./SaveNewItemAction";
import * as ItemImageUtils from "../../../utils/ItemImageUtils";
import { DateTime } from "luxon";
import { IDispatch, IGetState } from "../../../types";
import { logWarning } from "../../../utils/ErrorUtils";

export function updateItem(
  id: string,
  saveItemArgs: SaveItemArguments,
  onSuccess: () => void,
) {
  return async function (dispatch: IDispatch, getState: IGetState) {
    const item = getItemById(getState())(id);
    if (!item) {
      logWarning("No item to update: " + id);
      return;
    }

    // This knows how to handle empty strings and online selected images
    const itemImageUrl = await ItemImageUtils.getItemImageUrl(
      id,
      saveItemArgs.itemImage,
    );

    const request: FunctionsApi.UpdateItemRequest = {
      id: id,
      name: saveItemArgs.name,
      category: saveItemArgs.categoryKey,
      purchaseDateMillis: saveItemArgs.purchaseDateMillis,
      warrantyMonths: saveItemArgs.warrantyMonths,
      notes: saveItemArgs.notes,
      imageUrl: itemImageUrl,
    };

    const warrantyVoidsDateMillis = DateUtils.getWarrantyVoidDateMillis(
      request.purchaseDateMillis,
      DateTime.now().zone.name,
      request.warrantyMonths,
    );

    ItemFunctions.updateItem(request);

    const updatedItem: IItem = {
      id: id,
      name: saveItemArgs.name,
      category: saveItemArgs.categoryKey,
      purchaseDateMillis: saveItemArgs.purchaseDateMillis,
      warrantyMonths: saveItemArgs.warrantyMonths,
      notes: saveItemArgs.notes,
      lastUpdateMillis: new Date().getTime(),
      warrantyVoidsDateMillis: warrantyVoidsDateMillis,
      imageUrl: request.imageUrl,
      receipts: item.receipts,
    };

    dispatch(updateItemInMap(updatedItem));
    onSuccess();
  };
}
