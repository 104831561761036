import React, { useEffect, useState } from "react";
import {
  getThemeFromLocalStorage,
  setThemeInLocalStorage,
  Theme,
  ThemeContext,
} from "./ThemeHelper";
import { StatusBar, Style } from "@capacitor/status-bar";
import { logError } from "../utils/ErrorUtils";
import { getPlatform } from "../firebase/UserFunctions";

export default function ThemeContainer(props: { children: React.ReactNode }) {
  const [theme, setTheme] = useState(getThemeFromLocalStorage());

  useEffect(() => {
    document.body.classList.toggle("dark", theme === Theme.DARK);
    setThemeInLocalStorage(theme);
    if (getPlatform() === "ios") {
      StatusBar.setStyle({
        style: theme === Theme.DARK ? Style.Dark : Style.Light,
      }).catch((e) => logError(e, "Error changing status bar style"));
    }
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
