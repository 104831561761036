import { Variant } from "@warranty-keeper/common";
import styles from "./SuccessScreen.module.css";
import Screen from "../../components/basic/Screen/Screen";
import Button from "../../components/basic/Button";
import Illustration from "../../components/basic/Illustration/Illustration";
import SaveSuccessLight from "../../assets/illustrations/save-success-light.svg";
import SaveSuccessDark from "../../assets/illustrations/save-success-dark.svg";
import Popup from "../../components/basic/Popup";
import NotificationLight from "../../assets/illustrations/notification-light.svg";
import NotificationDark from "../../assets/illustrations/notification-dark.svg";
import { reportClick } from "../../firebase/FirebaseAnalytics";
import { CustomImage } from "../../components/basic/CustomImage";
import { translate } from "../../locale/Locale";

export interface SuccessScreenProps {
  itemName: string;
  imageUrl?: string;
  notificationDays: number;
  onContinue: () => void;
  showNotificationPopup: boolean;
  onClosePopup: () => void;
  onNotifyMe: () => void;
  onNotNow: () => void;
}

export default function SuccessScreen(props: SuccessScreenProps) {
  return (
    <Screen
      headerTitle={translate("saveSuccess.itemSaved")}
      onBack={props.onContinue}
    >
      <div className={styles.successScreen}>
        {props.imageUrl ? (
          <div className={styles.imageContainer}>
            <CustomImage
              imageUrl={props.imageUrl}
              className={styles.itemImage}
            />
          </div>
        ) : (
          <Illustration
            lightPath={SaveSuccessLight}
            darkPath={SaveSuccessDark}
            className={styles.illustration}
          />
        )}

        <div>
          <div className={styles.itemName}>{props.itemName}</div>

          <div className={styles.subtitle}>
            {translate("saveSuccess.savedSuccessfully")}
          </div>
          {props.notificationDays >= 0 && (
            <div className={styles.notificationDays}>
              {translate("saveSuccess.weWillNotifyYou", {
                count: props.notificationDays,
              })}
            </div>
          )}

          <Button
            title={translate("saveSuccess.okButton")}
            onClick={props.onContinue}
            variant={Variant.Primary}
            analyticTag={"saveSuccess/ok"}
          />
        </div>
      </div>

      <Popup
        open={props.showNotificationPopup}
        title={translate("saveSuccess.getNotifications")}
        content={translate("saveSuccess.getNotificationsContent")}
        illustration={
          <Illustration
            lightPath={NotificationLight}
            darkPath={NotificationDark}
          />
        }
        buttons={[
          {
            label: translate("saveSuccess.noThanks"),
            variant: Variant.Info,
            onClick: props.onNotNow,
            analyticTag: "notificationPopup/no",
          },
          {
            label: translate("saveSuccess.notifyMe"),
            variant: Variant.Primary,
            onClick: props.onNotifyMe,
            analyticTag: "notificationPopup/yes",
          },
        ]}
        onClose={() => {
          props.onClosePopup();
          reportClick("notificationPopup/close");
        }}
      />
    </Screen>
  );
}
