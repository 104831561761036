import { useState } from "react";
import Screen from "../../components/basic/Screen/Screen";
import PickerOptionList from "../../components/picker/PickerOptionList/PickerOptionList";
import {
  PickerOptionData,
  PickerOptionMode,
} from "../../components/picker/PickerOption/PickerOption";
import styles from "./NotificationSettingsScreen.module.css";
import Button from "../../components/basic/Button";
import { Input } from "../../components/basic/Input";
import Toggle from "../../components/basic/Toggle/Toggle";
import Divider from "../../components/basic/Divider/Divider";
import { translate } from "../../locale/Locale";

export interface NotificationSettingsScreenProps {
  notificationDays: number;
  pushNotificationsEnabled: boolean;
  emailNotificationsEnabled: boolean;
  onSave: (
    days: number | undefined,
    enablePushNotifications: boolean,
    enableEmailNotifications: boolean,
    onNotificationDaysError: (error: string) => void,
  ) => void;
}

enum PresetOption {
  // SAME_DAY = 0,
  D7 = 7,
  D14 = 14,
  D30 = 30,
  D60 = 60,
  NEVER = -1,
  OTHER = -100,
}

export default function NotificationSettingsScreen(
  props: NotificationSettingsScreenProps,
) {
  const initialOption = getOption(props.notificationDays);
  const [selected, setSelected] = useState(initialOption);
  const [other, setOther] = useState(props.notificationDays + "");
  const [otherError, setOtherError] = useState<string | undefined>();

  const [pushEnabled, setPushEnabled] = useState(
    props.pushNotificationsEnabled,
  );
  const [emailEnabled, setEmailEnabled] = useState(
    props.emailNotificationsEnabled,
  );

  const options = [
    // PresetOption.SAME_DAY,
    // PresetOption.D7,
    PresetOption.D14,
    PresetOption.D30,
    PresetOption.D60,
    PresetOption.NEVER,
    PresetOption.OTHER,
  ].map(getOption);

  return (
    <Screen
      noPadding
      headerTitle={translate("notifications.notifications")}
      stickyComponent={
        <>
          <div className={styles.subtitle}>
            {translate("notifications.subtitle")}
          </div>
        </>
      }
    >
      <div>
        <PickerOptionList
          options={options}
          onSelected={setSelected}
          selected={selected}
          mode={PickerOptionMode.RADIO}
          extraPadding
        />
        {selected.value === PresetOption.OTHER && (
          <div className={styles.otherInput}>
            <Input
              value={other}
              onChangeText={(newValue) => {
                setOther(newValue);
                setOtherError(undefined);
              }}
              type={"number"}
              maxValue={999}
              error={otherError}
            />
          </div>
        )}

        <Divider className={styles.divider} />

        <NotificationMethodPicker
          pushEnabled={pushEnabled}
          setPushEnabled={setPushEnabled}
          emailEnabled={emailEnabled}
          setEmailEnabled={setEmailEnabled}
        />

        <div className={styles.saveButton}>
          <Button
            title={translate("common.save")}
            onClick={() => {
              setOtherError(undefined);
              let days: number | undefined;
              if (selected.value !== PresetOption.OTHER) {
                days = selected.value;
              } else {
                const parsed = parseInt(other);
                if (parsed) {
                  days = parsed;
                }
              }
              props.onSave(days, pushEnabled, emailEnabled, setOtherError);
            }}
          />
        </div>
      </div>
    </Screen>
  );
}

function NotificationMethodPicker(props: {
  pushEnabled: boolean;
  setPushEnabled: (enabled: boolean) => void;
  emailEnabled: boolean;
  setEmailEnabled: (enabled: boolean) => void;
}) {
  return (
    <div className={styles.methodsPicker}>
      <MethodRow
        title={translate("notifications.pushNotifications")}
        value={props.pushEnabled}
        onChange={props.setPushEnabled}
      />
      <MethodRow
        title={translate("notifications.emailNotifications")}
        value={props.emailEnabled}
        onChange={props.setEmailEnabled}
      />
    </div>
  );
}

function MethodRow(props: {
  title: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
}) {
  return (
    <div
      className={styles.notificationMethod}
      onClick={() => props.onChange(!props.value)}
    >
      <div className={styles.methodTitle}>{props.title}</div>
      <Toggle enabled={props.value} onChange={props.onChange} />
    </div>
  );
}

function getOption(
  days: number | PresetOption,
): PickerOptionData<PresetOption> {
  const option = PresetOption[days] ? days : PresetOption.OTHER;

  return {
    value: option,
    text: getText(days),
    key: "" + days,
  };
}

function getText(option: PresetOption) {
  switch (option) {
    case PresetOption.D7:
    case PresetOption.D14:
    case PresetOption.D30:
    case PresetOption.D60:
      return translate("time.days", { count: option });
    // case PresetOption.SAME_DAY:
    //   return translate("time.sameDay");
    case PresetOption.NEVER:
      return translate("time.never");
    case PresetOption.OTHER:
      return translate("common.other");
  }
}
