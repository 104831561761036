import { useState } from "react";
import SearchBar from "../../components/basic/SearchBar/SearchBar";
import Screen from "../../components/basic/Screen/Screen";
import _ from "lodash";
import PickerOptionList from "../../components/picker/PickerOptionList/PickerOptionList";
import { PickerOptionData } from "../../components/picker/PickerOption/PickerOption";
import styles from "./LanguageScreen.module.css";
import { logWarning } from "../../utils/ErrorUtils";
import TextButton from "../../components/basic/TextButton";
import { translate, getTranslations } from "../../locale/Locale";

export interface LanguageScreenProps {
  locale: string;
  onLanguageChange: (newLocale: string) => void;
  onHelpTranslate: () => void;
}

export default function LanguageScreen(props: LanguageScreenProps) {
  const [searchText, setSearchText] = useState("");

  const currentLocale = props.locale;

  const options = _.keys(getTranslations())
    .map(getOptionFromLocale)
    .filter((option) =>
      option.key.toLocaleLowerCase().includes(searchText.toLowerCase()),
    )
    .sort((o1, o2) => o1.text.localeCompare(o2.text));

  return (
    <Screen
      noPadding
      headerTitle={translate("language.language")}
      stickyComponent={
        <SearchBar value={searchText} onTextChange={setSearchText} />
      }
    >
      <div className={styles.languageScreenContent}>
        <div className={styles.languagesList}>
          <PickerOptionList
            options={options}
            onSelected={(option) => props.onLanguageChange(option.value)}
            selected={getSelectedOption(currentLocale)}
            extraPadding
          />
        </div>
        <div className={styles.addTranslation}>
          {translate("language.addTranslation")}
          <div className={styles.addTranslationButton}>
            <TextButton
              title={translate("language.clickHere")}
              onClick={props.onHelpTranslate}
            />
          </div>
        </div>
      </div>
    </Screen>
  );
}

function getSelectedOption(currentLocale: string): PickerOptionData<string> {
  // So we don't confuse with "pt"
  const localeName =
    currentLocale === "pt-BR" ? currentLocale : currentLocale.substring(0, 2);

  if (!getTranslations()[localeName]) {
    // User locale is not available, falling back to English
    return getSelectedOption("en");
  }

  return getOptionFromLocale(localeName);
}

function getOptionFromLocale(locale: string): PickerOptionData<string> {
  // @ts-ignore
  let text = getTranslations()[locale]?.about?.thisLanguage as
    | string
    | undefined;
  // @ts-ignore
  let languageInEnglish = getTranslations()[locale]?.about
    ?.thisLanguageInEnglish as string | undefined;
  if (!text) {
    logWarning("No language name for " + locale);
    text = locale;
  }

  return {
    key: text + "#" + languageInEnglish,
    value: locale,
    text: text,
  };
}
