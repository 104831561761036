import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemById } from "../../store/items/Selectors";
import { useNavigate, useParams } from "react-router-dom";
import ReceiptScreen from "./ReceiptScreen";
import { IReceipt } from "@warranty-keeper/common";
import { removeReceipt } from "../../store/items/actions/RemoveReceiptAction";
import { RawMediaFile } from "../../utils/MediaFileUtils";
import { addReceiptToItem } from "../../store/items/actions/AddReceiptToItemAction";
import { AppRoutes } from "../../router/Routes";

export default function ReceiptScreenContainer() {
  const { itemId } = useParams<{ itemId: string }>();
  const item = useSelector(getItemById)(itemId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!itemId || !item) {
    navigate(AppRoutes.AllItems);
    return null;
  }

  const deleteReceipt = (receipt: IReceipt) => {
    const leftReceipts = item.receipts.length - 1;
    dispatch(removeReceipt(itemId, receipt));
    if (leftReceipts === 0) {
      navigate(-1);
    }
  };

  const addAnotherReceipt = (rawMediaFile: RawMediaFile) => {
    dispatch(addReceiptToItem(itemId, rawMediaFile));
  };

  return (
    <ReceiptScreen
      itemName={item.name}
      receipts={item.receipts}
      onDownload={(receipt) => window.open(receipt.receiptUrl)}
      onDelete={deleteReceipt}
      onAddAnotherReceipt={addAnotherReceipt}
    />
  );
}
