import { useState } from "react";
import styles from "./SocialSignInButton.module.scss";
import { ReactComponent as Google } from "../../assets/icons/google-sign-in.svg";
import { ReactComponent as Apple } from "../../assets/icons/apple-sign-in.svg";
import Spinner from "../basic/Spinner/Spinner";
import { Icon } from "../../utils/IconUtils";
import classNames from "classnames";
import { reportClick } from "../../firebase/FirebaseAnalytics";

export enum SocialSignInMethod {
  Google = "google",
  Apple = "apple",
}

export interface SocialSignInButtonProps {
  bigIcon?: boolean;
  method: SocialSignInMethod;
  onClick: (stopLoading: () => void) => void;
}

export default function SocialSignInButton(props: SocialSignInButtonProps) {
  const [isWaiting, setIsWaiting] = useState(false);

  function onClick() {
    if (isWaiting) {
      return;
    }
    setIsWaiting(true);
    props.onClick(() => setIsWaiting(false));
    reportClick("social." + props.method);
  }

  let SocialIcon: Icon = () => null;
  const circleClasses = [styles.iconCircle];
  const iconClasses = [];

  switch (props.method) {
    case "google":
      SocialIcon = Google;
      circleClasses.push(styles.google);
      break;
    case "apple":
      SocialIcon = Apple;
      circleClasses.push(styles.apple);
      iconClasses.push(styles.appleIcon);
      break;
  }

  if (isWaiting) {
    circleClasses.push(styles.waitingCircle);
  }

  return (
    <div
      onClick={onClick}
      className={classNames({
        [styles.container]: true,
        [styles.bigIcon]: props.bigIcon,
      })}
    >
      <div className={classNames(circleClasses)}>
        {isWaiting ? (
          <Spinner className={styles.spinner} />
        ) : (
          <SocialIcon className={classNames(iconClasses)} />
        )}
      </div>
    </div>
  );
}
