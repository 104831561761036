import React, { useContext } from "react";
import IconButton from "../../components/basic/IconButton";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as GeneralSettings } from "../../assets/icons/general-settings.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/settings-person.svg";
import { ReactComponent as LanguageIcon } from "../../assets/icons/language.svg";
import { ReactComponent as Notifications } from "../../assets/icons/notifications.svg";
import { ReactComponent as Feedback } from "../../assets/icons/feedback.svg";
import { ReactComponent as RateApp } from "../../assets/icons/rate-app.svg";
import { ReactComponent as Export } from "../../assets/icons/export.svg";
import { ReactComponent as About } from "../../assets/icons/about.svg";
import { ReactComponent as DarkMode } from "../../assets/icons/dark-mode.svg";
import { ReactComponent as SignOutIcon } from "../../assets/icons/sign-out.svg";
import styles from "./SettingsMenu.module.css";
import { Icon } from "../../utils/IconUtils";
import { AppRoutes } from "../../router/Routes";
import {
  PICKER_OPTION_HEIGHT,
  pickerOptionContainerClassName,
} from "../../components/picker/PickerOption/PickerOption";
import { IconColorField } from "../../components/basic/IconButton/IconButton";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Theme, ThemeContext } from "../../theme/ThemeHelper";
import { reportClick } from "../../firebase/FirebaseAnalytics";
import Toggle from "../../components/basic/Toggle/Toggle";
import { translate } from "../../locale/Locale";

export interface SettingsMenuProps {
  onRateApp: () => void;
  onSignOut: () => void;
  userHasItems: boolean;
}

export default function SettingsMenu(props: SettingsMenuProps) {
  const navigate = useNavigate();
  const { theme, setTheme } = useContext(ThemeContext);
  const isDarkMode = theme === Theme.DARK;

  const onThemeChange = (state: boolean) => {
    const newTheme = state ? Theme.DARK : Theme.LIGHT;
    setTheme(newTheme);
    reportClick("theme/" + newTheme);
  };

  return (
    <div>
      <div className={styles.title}>{translate("settings.settings")}</div>

      <SettingsOption
        label={translate("generalSettings.generalSettings")}
        icon={GeneralSettings}
        iconColorField={IconColorField.STROKE}
        onClick={() => navigate(AppRoutes.GeneralSettings)}
      />
      <SettingsOption
        testId={"languageSettings"}
        label={translate("language.language")}
        icon={LanguageIcon}
        iconColorField={IconColorField.STROKE}
        onClick={() => navigate(AppRoutes.Language)}
        rightSection={
          <div className={styles.languageRight}>
            <div className={styles.languageText}>
              {translate("about.thisLanguage")}
            </div>
            <Chevron />
          </div>
        }
      />
      <SettingsOption
        label={translate("account.account")}
        icon={PersonIcon}
        iconColorField={IconColorField.STROKE}
        onClick={() => navigate(AppRoutes.Account)}
      />
      <SettingsOption
        label={translate("notifications.notifications")}
        icon={Notifications}
        iconColorField={IconColorField.FILL}
        onClick={() => navigate(AppRoutes.Notifications)}
      />
      <SettingsOption
        label={translate("feedback.sendFeedback")}
        icon={Feedback}
        iconColorField={IconColorField.STROKE}
        onClick={() => navigate(AppRoutes.Feedback)}
      />
      <SettingsOption
        label={translate("settings.rateApp")}
        icon={RateApp}
        iconColorField={IconColorField.STROKE}
        onClick={props.onRateApp}
      />
      {props.userHasItems && (
        <SettingsOption
          label={translate("exportData.exportData")}
          icon={Export}
          iconColorField={IconColorField.STROKE}
          onClick={() => navigate(AppRoutes.ExportData)}
        />
      )}
      <SettingsOption
        label={translate("about.about")}
        icon={About}
        iconColorField={IconColorField.FILL}
        onClick={() => navigate(AppRoutes.About)}
      />
      <SettingsOption
        label={translate("settings.darkMode")}
        icon={DarkMode}
        iconColorField={IconColorField.STROKE}
        onClick={() => onThemeChange(!isDarkMode)}
        rightSection={<Toggle enabled={isDarkMode} onChange={onThemeChange} />}
      />

      <div className={styles.signOut}>
        <IconButton
          onClick={props.onSignOut}
          title={translate("settings.signOut")}
          icon={SignOutIcon}
          selected
          iconColorField={IconColorField.STROKE}
          textClassName={styles.signOutText}
          analyticTag={"signOut"}
        />
      </div>
    </div>
  );
}

function SettingsOption(props: {
  label: string;
  icon: Icon;
  iconColorField: IconColorField;
  onClick: () => void;
  rightSection?: React.ReactNode;
  testId?: string;
}) {
  return (
    <div
      data-test-id={props.testId}
      className={pickerOptionContainerClassName}
      style={{ height: PICKER_OPTION_HEIGHT }}
      onClick={props.onClick}
    >
      <div className={styles.optionName}>
        <div className={styles.icon}>
          <props.icon
            className={classNames({
              [styles.iconFill]: props.iconColorField === IconColorField.FILL,
              [styles.iconStroke]:
                props.iconColorField === IconColorField.STROKE,
            })}
          />
        </div>
        {props.label}
      </div>

      {props.rightSection || <Chevron />}
    </div>
  );
}

function Chevron() {
  return (
    <IconButton icon={ChevronRight} iconColorField={IconColorField.STROKE} />
  );
}
