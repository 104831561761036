import React from "react";
import styles from "./Divider.module.css";
import classNames from "classnames";

export interface DividerProps {
  text?: string;
  className?: string;
}

export default function Divider(props: DividerProps) {
  return (
    <div className={classNames(props.className, styles.dividerContainer)}>
      <div className={classNames(styles.divider)}>
        {props.text && <div className={styles.textContainer}>{props.text}</div>}
      </div>
    </div>
  );
}
