import { PickerOptionData } from "../PickerOption/PickerOption";
import InputWrapper from "../../basic/Input/InputWrapper";
import styles from "./DropMenuField.module.css";
import { useState } from "react";
import PickerModal from "../PickerModal/PickerModal";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";

export interface DropMenuFieldProps<T> {
  label: string;
  error?: string;

  options: PickerOptionData<T>[];
  selected?: PickerOptionData<T>;
  onSelected: (newValue: PickerOptionData<T>) => void;
}

export default function DropMenuField<T>(props: DropMenuFieldProps<T>) {
  const [methodPickerOpen, setMethodPickerOpen] = useState(false);

  return (
    <>
      <InputWrapper
        label={props.label}
        endIcon={<ChevronDown className={styles.chevron} />}
        showBigLabel={!props.selected}
        error={props.error}
      >
        <div
          className={styles.fieldContent}
          onClick={() => setMethodPickerOpen(true)}
          data-test-id={"DropMenuField-" + props.label}
        >
          {props.selected?.text}
        </div>
      </InputWrapper>

      <PickerModal
        open={methodPickerOpen}
        options={props.options}
        selected={props.selected}
        onSelected={props.onSelected}
        onClose={() => setMethodPickerOpen(false)}
      />
    </>
  );
}
