"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateFormat = exports.FirstDayOfWeek = exports.Variant = exports.SortMethod = exports.FileType = void 0;
var FileType;
(function (FileType) {
    FileType["Image"] = "Image";
    FileType["PDF"] = "PDF";
})(FileType = exports.FileType || (exports.FileType = {}));
var SortMethod;
(function (SortMethod) {
    SortMethod["Name"] = "Name";
    SortMethod["PurchaseDate"] = "PurchaseDate";
    SortMethod["ExpiryDate"] = "ExpiryDate";
    SortMethod["LastChange"] = "LastChange";
})(SortMethod = exports.SortMethod || (exports.SortMethod = {}));
var Variant;
(function (Variant) {
    Variant["Primary"] = "primary";
    Variant["Secondary"] = "secondary";
    Variant["Info"] = "info";
})(Variant = exports.Variant || (exports.Variant = {}));
// Values are saved in DB, do not change existing values
var FirstDayOfWeek;
(function (FirstDayOfWeek) {
    FirstDayOfWeek["Saturday"] = "Saturday";
    FirstDayOfWeek["Sunday"] = "Sunday";
    FirstDayOfWeek["Monday"] = "Monday";
})(FirstDayOfWeek = exports.FirstDayOfWeek || (exports.FirstDayOfWeek = {}));
// Values are saved in DB, do not change existing values
var DateFormat;
(function (DateFormat) {
    DateFormat["MMDDYYYY"] = "MMDDYYYY";
    DateFormat["DDMMYYYY"] = "DDMMYYYY";
})(DateFormat = exports.DateFormat || (exports.DateFormat = {}));
