import React from "react";
import FeedbackScreen from "./FeedbackScreen";
import * as UserFunctions from "../../firebase/UserFunctions";
import { useSelector } from "react-redux";
import { getEmail } from "../../store/user/Selectors";

export default function FeedbackScreenContainer() {
  const email = useSelector(getEmail);

  return (
    <FeedbackScreen sendFeedback={UserFunctions.sendFeedback} email={email} />
  );
}
