import { CategoryKey, ICategoryPreview } from "@warranty-keeper/common";
import { translate } from "../locale/Locale";

export function getCategoryPreview(
  categoryKey: CategoryKey,
  itemsCount: number,
  categoryIndex: number,
  isDarkMode: boolean,
): ICategoryPreview {
  return {
    categoryKey: categoryKey,
    categoryLabel: getCategoryName(categoryKey),
    categoryColor: getCategoryColor(categoryIndex, isDarkMode),
    itemsCount: itemsCount,
  };
}

export function getCategoryName(categoryKey: CategoryKey) {
  return translate("categories." + categoryKey.toLowerCase(), {
    defaultValue: categoryKey,
  });
}

const lightCategoryColors = [
  "#FF0099",
  "#FF3E3E",
  "#FF7A00",
  "#FFF500",
  "#9EFF00",
  "#58B9FF",
  "#5C58FF",
  "#8F00FF",
  "#BE54FF",
  "#CA9039",
];

const darkCategoryColors = [
  "#FB8AC7",
  "#F49B9F",
  "#FFF597",
  "#FDFBBF",
  "#CFFF9B",
  "#8BCDFF",
  "#A4A0FF",
  "#CC9CFF",
  "#D28AFE",
  "#CA9039",
];

function getCategoryColor(categoryIndex: number, isDarkMode: boolean) {
  if (isDarkMode) {
    return darkCategoryColors[categoryIndex % darkCategoryColors.length];
  }

  return lightCategoryColors[categoryIndex % lightCategoryColors.length];
}
