import { useNavigate } from "react-router-dom";
import { recoverFacebookAccount } from "../../firebase/UserFunctions";
import FacebookRecoverScreen from "./FacebookRecoverScreen";

export default function FacebookRecoverScreenContainer() {
  const navigate = useNavigate();

  return (
    <FacebookRecoverScreen
      goBack={() => navigate(-1)}
      onRevocerFacebookAccount={(email, onSuccess, setError) => {
        recoverFacebookAccount(email).then(onSuccess);
      }}
    />
  );
}
