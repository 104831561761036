import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
  UserCredential,
} from "firebase/auth";
import { logError, logWarning } from "../utils/ErrorUtils";
import { userLoggedOut } from "../store/user/actions/BasicUserActions";
import { auth } from "./FirebaseInitializer";
import { reportLogin, reportSignOut, reportSignUp } from "./FirebaseAnalytics";
import { IDispatch } from "../types";
import { translate } from "../locale/Locale";

export function isLoggedInWithEmailAndPassword() {
  return auth.currentUser?.providerData[0]?.providerId === "password";
}

export function signOut(dispatch: IDispatch) {
  auth
    .signOut()
    .then(() => dispatch(userLoggedOut()))
    .catch((e) => logError(e, "Error signing out"));
  reportSignOut();
}

export function signInWithEmail(
  email: string,
  password: string,
  emailError: (error: string) => void,
  passwordError: (error: string) => void,
) {
  reportLogin("email");
  signInWithEmailAndPassword(auth, email, password).catch((error) => {
    switch (error.code) {
      case "auth/invalid-email":
        emailError(translate("authenticationErrors.invalidEmail"));
        return;

      case "auth/wrong-password":
        passwordError(translate("authenticationErrors.wrongPassword"));
        return;

      case "auth/user-disabled":
        emailError(translate("authenticationErrors.invalidEmail"));
        return;

      case "auth/user-not-found":
        emailError(translate("authenticationErrors.invalidEmail"));
        return;

      case "auth/too-many-requests":
        emailError(translate("authenticationErrors.tooManyRequests"));
        return;

      case "auth/network-request-failed":
        emailError(translate("authenticationErrors.networkRequestFailed"));
        return;

      case "auth/internal-error":
        emailError(translate("authenticationErrors.tryAgain"));
        return;

      default:
        emailError(error.message);
        logWarning("Sing in error code without translation: " + error.code);
        return;
    }
  });
}

export const NEW_USER_NAME_LOCAL_STORAGE_KEY = "newUserNameKey";

export async function registerUser(
  name: string,
  email: string,
  password: string,
  emailError: (message: string) => void,
  passwordError: (message: string) => void,
) {
  localStorage.setItem(NEW_USER_NAME_LOCAL_STORAGE_KEY, name);
  reportSignUp("email");
  return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential: UserCredential) => {
      if (!userCredential.user) {
        throw new Error("Invalid user");
      }
    })
    .catch((error) => {
      switch (error.code) {
        case "auth/email-already-in-use":
          emailError(translate("authenticationErrors.emailInUse"));
          return;

        case "auth/invalid-email":
          emailError(translate("authenticationErrors.invalidEmail"));
          return;

        case "auth/weak-password":
          passwordError(translate("authenticationErrors.weakPassword"));
          return;

        case "auth/network-request-failed":
          passwordError(translate("authenticationErrors.networkRequestFailed"));
          return;

        case "auth/internal-error":
          emailError(translate("authenticationErrors.tryAgain"));
          return;

        default:
          emailError(error.message);
          logWarning("Register error code without translation: " + error.code);
          return;
      }
    });
}

export function resetPassword(
  email: string,
  onSuccess: () => void,
  onError: (message: string) => void,
) {
  sendPasswordResetEmail(auth, email)
    .then(onSuccess)
    .catch((error) => {
      switch (error.code) {
        case "auth/invalid-email":
        case "auth/missing-email":
          onError(translate("authenticationErrors.invalidEmail"));
          return;

        case "auth/user-not-found":
          onError(translate("authenticationErrors.userNotFound"));
          return;

        case "auth/too-many-requests":
          onError(translate("authenticationErrors.tooManyRequests"));
          return;

        case "auth/network-request-failed":
          onError(translate("authenticationErrors.networkRequestFailed"));
          return;

        case "auth/internal-error":
          onError(translate("authenticationErrors.tryAgain"));
          return;

        default:
          onError(error.message);
          logWarning(
            "Reset password error code without translation: " + error.code,
          );
          return;
      }
    });
}

export function updateUserPassword(
  oldPassword: string,
  newPassword: string,
  onSuccess: () => void,
  currentPasswordError: (message: string) => void,
  newPasswordError: (message: string) => void,
) {
  const user = auth.currentUser;

  if (user === null) {
    logWarning("Unauthenticated user can't change password");
    return;
  }

  const credential = EmailAuthProvider.credential(user.email!, oldPassword);

  reauthenticateWithCredential(user, credential)
    .then((u) =>
      updatePassword(u.user, newPassword)
        .then(onSuccess)
        .catch((error) => {
          switch (error.code) {
            case "auth/weak-password":
              newPasswordError(translate("authenticationErrors.weakPassword"));
              return;

            case "auth/invalid-verification-id":
              newPasswordError(translate("authenticationErrors.loginAgain"));
              return;

            default:
              newPasswordError(error.message);
              logWarning(
                "Update password error code without translation: " + error.code,
              );
              return;
          }
        }),
    )
    .catch(() => {
      currentPasswordError(translate("authenticationErrors.wrongPassword"));
    });
}

export function deleteFirebaseUser() {
  const user = auth.currentUser;
  return user?.delete().catch((e) => logError(e, "Error deleting user"));
}
