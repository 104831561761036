import * as Sentry from "@sentry/react";

export function logError(error: Error, message?: string) {
  if (process.env.NODE_ENV === "development") {
    console.error(error, message);
    Sentry.captureException(error);
    return;
  }

  if (message) {
    console.error(message, "; Error message: ", error.message);
  }

  if (shouldReportError(error)) {
    Sentry.captureException(error);
  }
}

export function logWarning(message: string) {
  if (process.env.NODE_ENV === "development") {
    console.warn(message);
  }
  Sentry.captureMessage(message, Sentry.Severity.Warning);
}

function shouldReportError(error: Error) {
  if (error.message.includes("Network request failed")) {
    return false;
  }
  if (error.message.includes("CONNECTION_FAILURE")) {
    return false;
  }
  if (error.message.includes("Couldn't get GCM token on device")) {
    return false;
  }

  return true;
}
