import { CategoryKey, StringUtils } from "@warranty-keeper/common";
import { getUser } from "../Selectors";
import { logWarning } from "../../../utils/ErrorUtils";
import * as UserFunctions from "../../../firebase/UserFunctions";
import * as actionTypes from "../ActionTypes";
import { IDispatch, IGetState } from "../../../types";
import { reportCustom } from "../../../firebase/FirebaseAnalytics";
import { translate } from "../../../locale/Locale";

export function updateUserCategories(categories: CategoryKey[]) {
  return {
    type: actionTypes.UPDATE_CATEGORIES,
    categories: categories,
  };
}

export function addCategory(
  categoryKey: CategoryKey,
  onSuccess: () => void,
  onError: (m: string) => void,
) {
  return function (dispatch: IDispatch, getState: IGetState) {
    if (StringUtils.isEmptyString(categoryKey)) {
      onError(translate("common.required"));
      return;
    }

    const newCategory = convertCategoryNameToDefaultKeyIfPossible(categoryKey);

    const user = getUser(getState());
    const categories = user.categories;

    const newCategoryLower = newCategory.trim().toLowerCase();
    for (const key in categories) {
      const existingCategory = categories[key].trim().toLowerCase();

      if (existingCategory === newCategoryLower) {
        logWarning(
          "User tried adding category that already exists: " + newCategory,
        );
        onError(
          translate("categories.categoryAlreadyExists", {
            categoryName: categoryKey,
          }),
        );
        return;
      }
    }

    const newCategories = categories.slice(); // Clone
    newCategories.push(newCategory);

    dispatch(updateUserCategories(newCategories));
    UserFunctions.updateUser({
      updatedCategories: newCategories,
    });
    reportCustom(`addedCategory`);
    onSuccess();
  };
}

const defaultCategoryKeys = [
  "electronics",
  "phones",
  "fashion",
  "home & kitchen",
  "jewelry",
  "kids & toys",
  "sport",
  "automotive",
];

function convertCategoryNameToDefaultKeyIfPossible(newCategory: string) {
  const trimmedNewCategory = newCategory.trim();
  const loweredNewCategory = trimmedNewCategory.toLowerCase();
  for (const key in defaultCategoryKeys) {
    const translation = translate("categories." + defaultCategoryKeys[key]);
    if (loweredNewCategory === defaultCategoryKeys[key]) {
      return defaultCategoryKeys[key];
    }
    if (loweredNewCategory === translation.toLowerCase()) {
      return defaultCategoryKeys[key];
    }
  }

  return trimmedNewCategory;
}
