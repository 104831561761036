import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";

// https://capacitorjs.com/docs/guides/deep-links
// TODO verify this still works after react-router-dom update
const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // Example url: https://app.warranty-keeper.com/#/category/Electronics
      // slug = /tabs/tab2
      console.log("appUrlOpen", event.url);
      const slug = event.url.split("warranty-keeper.com").pop();
      if (slug) {
        navigate(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, [navigate]);

  return null;
};

export default AppUrlListener;
