import { useEffect, useState } from "react";
import styles from "./SearchBar.module.scss";
import { ReactComponent as SearchBarIcon } from "../../../assets/icons/search-bar.svg";
import classNames from "classnames";
import X from "../X/X";
import { translate } from "../../../locale/Locale";

export interface SearchBarProps {
  value?: string;
  onTextChange: (newText: string) => void;
  onSearch?: (query: string) => void;
  className?: string;
  startFocused?: boolean;
}

export default function SearchBar(props: SearchBarProps) {
  const [focused, setFocused] = useState(!!props.startFocused);
  let textInput: HTMLInputElement | null = null;
  useEffect(() => {
    if (focused) {
      textInput?.focus();
    }
  }, [focused, textInput]);

  const onEnter = () =>
    props.value && props.onSearch && props.onSearch(props.value);

  if (!props.value && !focused) {
    return (
      <div
        className={classNames(styles.searchBarContainer, props.className)}
        onClick={() => {
          setFocused(true);
        }}
      >
        <div className={styles.placeholder}>
          <SearchBarIcon className={styles.searchIcon} />
          <div>{translate("search.search")}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.searchBarContainer}>
      <input
        data-test-id={"input-SearchBar"}
        ref={(i) => (textInput = i)}
        className={styles.searchInput}
        value={props.value}
        onChange={(e) => props.onTextChange(e.target.value)}
        onSubmit={onEnter}
        onBlur={() => setFocused(false)}
        maxLength={40}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            onEnter();
          }
        }}
      />

      {props.value && (
        <X
          onClick={() => props.onTextChange("")}
          containerClass={styles.clearButtonContainer}
          xClass={styles.clearX}
        />
      )}
    </div>
  );
}
