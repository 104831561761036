import { IItem, IItemsMap } from "@warranty-keeper/common";
import * as actionTypes from "../ActionTypes";
import { getItemsMap } from "../Selectors";
import { setItemsInCache } from "../../../firebase/LocalCache";
import { IDispatch, IGetState } from "../../../types";

export function setItems(items: IItemsMap, isFromCache?: boolean) {
  if (!isFromCache) {
    setItemsInCache(items);
  }

  return {
    type: actionTypes.SET_ITEMS,
    items,
  };
}

export function updateItemInMap(updatedItem: IItem) {
  return function (dispatch: IDispatch, getState: IGetState) {
    const itemsMap = getItemsMap(getState());
    itemsMap[updatedItem.id] = updatedItem;
    dispatch(setItems(itemsMap));
  };
}
