import { useNavigate } from "react-router-dom";
import { AuthenticationRoutes } from "../../router/Routes";
import EmailSignInScreen from "./EmailSignInScreen";
import { signInWithEmail } from "../../firebase/FirebaseAuthentication";
import {
  appleLoginSupported,
  socialSignIn,
} from "../../firebase/SocialAuthentication";
import { useContext } from "react";
import { LocaleContext } from "../../locale/LocaleContext";

export default function EmailSignInScreenContainer() {
  const navigate = useNavigate();
  const [locale] = useContext(LocaleContext);

  return (
    <EmailSignInScreen
      goToRegister={() => navigate(AuthenticationRoutes.Register)}
      goToResetPassword={() => navigate(AuthenticationRoutes.ResetPassword)}
      onSignIn={signInWithEmail}
      onClickSocial={(method, setError) =>
        socialSignIn(method, setError, locale)
      }
      enableAppleLogin={appleLoginSupported()}
    />
  );
}
