import { CategoryKey, FunctionsApi } from "@warranty-keeper/common";
import * as ItemFunctions from "../../../firebase/ItemFunctions";
import { updateItemInMap } from "./ItemsMapActions";
import { RawMediaFile } from "../../../utils/MediaFileUtils";
import * as ReceiptUtils from "../../../utils/ReceiptUtils";
import * as ItemImageUtils from "../../../utils/ItemImageUtils";
import { IDispatch } from "../../../types";

export interface SaveItemArguments {
  name: string;
  categoryKey: CategoryKey;
  purchaseDateMillis: number;
  warrantyMonths: number;
  notes: string;
  itemImage: RawMediaFile | undefined;
  receipts: RawMediaFile[];
}

export const saveNewItem = (
  {
    name,
    categoryKey,
    purchaseDateMillis,
    warrantyMonths,
    notes,
    itemImage,
    receipts,
  }: SaveItemArguments,
  onSuccess: (newId: string) => void,
) => {
  return async function (dispatch: IDispatch) {
    const newId = randomFirebaseLikeId();

    const itemImageUrlPromise = ItemImageUtils.getItemImageUrl(
      newId,
      itemImage,
    );
    const receiptsPromise = ReceiptUtils.uploadReceipts(newId, receipts);

    const request: FunctionsApi.CreateItemRequest = {
      newId,
      name,
      imageUrl: await itemImageUrlPromise,
      receipts: await receiptsPromise,
      categoryKey,
      purchaseDateMillis: purchaseDateMillis,
      warrantyMonths,
      notes,
    };

    const { savedItem } = await ItemFunctions.createItem(request);

    await dispatch(updateItemInMap(savedItem));

    onSuccess(newId);
  };
};

function randomFirebaseLikeId() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 20; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
