import { useState } from "react";
import Screen from "../../components/basic/Screen/Screen";
import styles from "./GeneralSettingsScreen.module.scss";
import {
  DateFormat,
  FirstDayOfWeek,
  GeneralSettings,
  SortMethod,
} from "@warranty-keeper/common";
import DropMenuField from "../../components/picker/DropMenuField";
import { PickerOptionData } from "../../components/picker/PickerOption/PickerOption";
import { Input } from "../../components/basic/Input";
import Button from "../../components/basic/Button";
import { translate } from "../../locale/Locale";

export interface GeneralSettingsScreenProps {
  sortingMethod: SortMethod;
  generalSettings: GeneralSettings;
  onSave: (
    firstDayOfWeek: FirstDayOfWeek,
    dateFormat: DateFormat,
    defaultWarrantyMonths: number,
    sortingMethod: SortMethod,
  ) => void;
}

export default function GeneralSettingsScreen(
  props: GeneralSettingsScreenProps,
) {
  const [sortingMethod, setSortingMethod] = useState(props.sortingMethod);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(
    props.generalSettings.firstDayOfWeek,
  );
  const [dateFormat, setDateFormat] = useState(
    props.generalSettings.dateFormat,
  );
  const [months, setMonths] = useState<number | "">(
    props.generalSettings.defaultWarrantyMonths,
  );
  const [monthsError, setMonthsError] = useState("");

  const sortingMethodOptions = [
    SortMethod.ExpiryDate,
    SortMethod.Name,
    SortMethod.PurchaseDate,
    SortMethod.LastChange,
  ].map(getSortingMethodOption);

  const firstDayOfWeekOptions = [
    FirstDayOfWeek.Saturday,
    FirstDayOfWeek.Sunday,
    FirstDayOfWeek.Monday,
  ].map(getFirstDayOfWeekOption);

  const dateFormatOptions = [DateFormat.DDMMYYYY, DateFormat.MMDDYYYY].map(
    getDateFormatOption,
  );

  const onSave = () => {
    if (!monthsError && months) {
      props.onSave(firstDayOfWeek, dateFormat, months, sortingMethod);
    }
  };

  return (
    <Screen headerTitle={translate("generalSettings.generalSettings")}>
      <div className={styles.screen}>
        <DropMenuField
          label={translate("sort.sortItemsBy")}
          options={sortingMethodOptions}
          selected={getSortingMethodOption(sortingMethod)}
          onSelected={(selected) => setSortingMethod(selected.value)}
        />
        <DropMenuField
          label={translate("generalSettings.firstDayOfWeek")}
          options={firstDayOfWeekOptions}
          selected={getFirstDayOfWeekOption(firstDayOfWeek)}
          onSelected={(selected) => setFirstDayOfWeek(selected.value)}
        />
        <DropMenuField
          label={translate("generalSettings.dateFormat")}
          options={dateFormatOptions}
          selected={getDateFormatOption(dateFormat)}
          onSelected={(selected) => setDateFormat(selected.value)}
        />
        <Input
          label={translate("generalSettings.defaultWarrantyMonths")}
          value={months + ""}
          type="number"
          maxValue={999}
          maxLength={3}
          error={monthsError}
          onChangeText={(value) => {
            if (value === "") {
              setMonths("");
              setMonthsError(translate("common.required"));
              return;
            }

            const number = parseInt(value);
            setMonths(number);
            setMonthsError("");
          }}
        />

        <Button
          title={translate("common.save")}
          onClick={onSave}
          disabled={!!monthsError}
        />
      </div>
    </Screen>
  );
}

function getSortingMethodOption(
  method: SortMethod,
): PickerOptionData<SortMethod> {
  return {
    text: translate("sort." + method),
    key: method,
    value: method,
  };
}

function getFirstDayOfWeekOption(
  firstDayOfWeek: FirstDayOfWeek,
): PickerOptionData<FirstDayOfWeek> {
  return {
    key: firstDayOfWeek,
    text: translate("generalSettings." + firstDayOfWeek),
    value: firstDayOfWeek,
  };
}

function getDateFormatOption(
  dateFormat: DateFormat,
): PickerOptionData<DateFormat> {
  let text = "";
  switch (dateFormat) {
    case DateFormat.DDMMYYYY:
      text = "DD/MM/YY";
      break;
    case DateFormat.MMDDYYYY:
      text = "MM/DD/YY";
      break;
    default:
      throw Error(
        "Cannot convert invalid date fromat to string: " + dateFormat,
      );
  }

  return {
    key: dateFormat,
    text,
    value: dateFormat,
  };
}
