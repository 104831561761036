import { useContext, useState } from "react";
import InputWrapper from "../Input/InputWrapper";
import DayPicker from "react-day-picker/DayPicker";
import "react-day-picker/lib/style.css";
import styles from "./DatePicker.module.scss";
import { DateFormat, DateUtils, FirstDayOfWeek } from "@warranty-keeper/common";
import { LocaleContext } from "../../../locale/LocaleContext";
import { DateTime } from "luxon";
import { translate } from "../../../locale/Locale";

export interface DatePickerProps {
  value?: DateTime;
  label?: string;
  firstDayOfWeek: FirstDayOfWeek;
  dateFormat: DateFormat;
  onChange: (date: DateTime) => void;
  error?: string;
  testId?: string;
}

const months = [
  translate("time.january"),
  translate("time.february"),
  translate("time.march"),
  translate("time.april"),
  translate("time.may"),
  translate("time.june"),
  translate("time.july"),
  translate("time.august"),
  translate("time.september"),
  translate("time.october"),
  translate("time.november"),
  translate("time.december"),
];

export default function DatePicker(props: DatePickerProps) {
  const [showModal, setShowModal] = useState(false);

  const onSelectDate = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    const newDate = DateTime.fromJSDate(date);
    props.onChange(newDate);
    setShowModal(false);
    console.log(newDate);
  };
  const [locale] = useContext(LocaleContext);

  return (
    <>
      <InputWrapper
        label={props.label}
        error={props.error}
        showBigLabel={!props.value}
      >
        <div
          className={styles.dateInput}
          onClick={() => setShowModal(true)}
          data-test-id={props.testId}
        >
          {DateUtils.formatDate(props.value, props.dateFormat)}
        </div>
      </InputWrapper>

      {showModal && (
        <div
          className={styles.background}
          onClick={() => setShowModal(false)}
        />
      )}

      {showModal && (
        <div className={styles.dateModal}>
          <DayPicker
            onDayClick={onSelectDate}
            locale={locale}
            selectedDays={props.value?.toJSDate()}
            months={months}
            weekdaysShort={getWeekdayShort(locale)}
            firstDayOfWeek={getFirstDayOfWeek(props.firstDayOfWeek)}
          />
        </div>
      )}
    </>
  );
}

function getWeekdayShort(locale: string) {
  if (locale.startsWith("he")) {
    return ["א", "ב", "ג", "ד", "ה", "ו", "ש"];
  }

  return undefined;
}

function getFirstDayOfWeek(firstDayOfWeek: FirstDayOfWeek) {
  switch (firstDayOfWeek) {
    case FirstDayOfWeek.Saturday:
      return 6;
    case FirstDayOfWeek.Sunday:
      return 0;
    case FirstDayOfWeek.Monday:
      return 1;
  }

  throw Error("Invalid first day of week");
}
