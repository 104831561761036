import React from "react";
import styles from "./Checkbox.module.css";
import classNames from "classnames";

export interface CheckboxProps {
  checked: boolean;
  onChange: (newValue: boolean) => void;
}

export default function Checkbox(props: CheckboxProps) {
  return (
    <div
      onClick={() => props.onChange(!props.checked)}
      className={classNames({
        [styles.checkbox]: true,
        [styles.checkboxChecked]: props.checked,
      })}
    >
      {props.checked && (
        <>
          <div className={classNames(styles.lineCommon, styles.smallLine)} />
          <div className={classNames(styles.lineCommon, styles.bigLine)} />
        </>
      )}
    </div>
  );
}
