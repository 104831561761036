import Screen from "../../components/basic/Screen/Screen";
import styles from "./AboutScreen.module.scss";
import TextButton from "../../components/basic/TextButton";
import Illustration from "../../components/basic/Illustration/Illustration";
import AboutLight from "../../assets/illustrations/about-light.svg";
import AboutDark from "../../assets/illustrations/about-dark.svg";
import { translate } from "../../locale/Locale";

export interface AboutScreenProps {
  version: string;
  onShowOpenSourceCredits: () => void;
}

export default function AboutScreen(props: AboutScreenProps) {
  return (
    <Screen headerTitle={translate("about.about")}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.illustration}>
            <Illustration lightPath={AboutLight} darkPath={AboutDark} />
          </div>

          <div>
            <div className={styles.appInfo}>{translate("about.title")}</div>
          </div>

          <Credit
            title={translate("about.productDesigner")}
            content={[
              "Neomi Shprintsin",
              "www.linkedin.com/in/neomi-shprintsin",
            ]}
          />

          <Credit
            title={translate("about.translationTo")}
            content={[translate("about.translatorName")]}
          />

          <Credit
            title={translate("about.icons")}
            content={["www.dribbble.com/wodziczka"]}
          />

          <Credit
            title={translate("about.spinner")}
            content={["www.tobiasahlin.com/spinkit"]}
          />

          <Credit
            title={translate("about.privacyPolicy")}
            content={["www.warranty-keeper.com/privacy-policy"]}
          />

          <Credit
            title={translate("about.termsAndConditions")}
            content={["www.warranty-keeper.com/terms-conditions"]}
          />

          <div className={styles.creditContent}>
            <TextButton
              title={translate("about.openSourceLibraries")}
              onClick={props.onShowOpenSourceCredits}
              className={styles.showButton}
            />
          </div>
        </div>

        <div className={styles.versionNumber}>
          {translate("settings.version") + " " + props.version}
        </div>
      </div>
    </Screen>
  );
}

function Credit(props: { title: string; content: string[] }) {
  return (
    <div className={styles.creditContent}>
      <div className={styles.creditTitle}>{props.title}</div>
      {props.content.map((x, index) => {
        if (x.startsWith("www")) {
          return (
            <div
              key={index}
              className={styles.link}
              onClick={() => window.open("https://" + x)}
            >
              {x}
            </div>
          );
        }
        return <div key={index}>{x}</div>;
      })}
    </div>
  );
}
