import { useState } from "react";
import CategoryList from "../../components/category/CategoryList";
import { CategoryKey, ICategoryPreview } from "@warranty-keeper/common";
import styles from "./CategoriesTab.module.css";
import AddCategoryPopup from "../../components/category/AddCategoryPopup/AddCategoryPopup";
import Fab from "../../components/basic/Fab/Fab";

export interface CategoriesTabProps {
  categories: ICategoryPreview[];
  onAddCategory: (
    categoryKey: CategoryKey,
    onSuccess: () => void,
    onError: (m: string) => void,
  ) => void;
  onClickCategory: (categoryKey: CategoryKey) => void;
  onDeleteCategory: (categoryKey: CategoryKey) => void;
}

export default function CategoriesTab(props: CategoriesTabProps) {
  // TODO pressing back while popup is displayed closes the app. It should close the popup.
  const [newCategoryPopup, setNewCategoryPopup] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <CategoryList
          categories={props.categories}
          onClickCategory={props.onClickCategory}
          onDeleteCategory={props.onDeleteCategory}
        />
      </div>

      <Fab onClick={() => setNewCategoryPopup(true)} />

      <AddCategoryPopup
        open={newCategoryPopup}
        close={() => setNewCategoryPopup(false)}
        addCategory={props.onAddCategory}
      />
    </>
  );
}
