import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Auth,
  browserLocalPersistence,
  getAuth,
  initializeAuth,
  onAuthStateChanged,
  User,
} from "firebase/auth";
import { getFirebaseConfig } from "./FirebaseConfig";
import {
  updateUserFromDb,
  userLoggedOut,
} from "../store/user/actions/BasicUserActions";
import { FunctionsApi, IItemsMap, IUser } from "@warranty-keeper/common";
import * as UserFunctions from "./UserFunctions";
import { getAppInfo, isNativePlatform } from "./UserFunctions";
import { setItems } from "../store/items/actions/ItemsMapActions";
import * as Sentry from "@sentry/react";
import {
  NEW_USER_NAME_LOCAL_STORAGE_KEY,
  signOut,
} from "./FirebaseAuthentication";
import { Analytics, getAnalytics } from "firebase/analytics";
import { clearCacheData, loadFromCache, setCacheData } from "./LocalCache";
import { initPushNotifications } from "../utils/PushNotificationUtils";
import { reportCustom, setAnalyticsUserId } from "./FirebaseAnalytics";
import { IDispatch } from "../types";

export let firebaseAnalytics: Analytics | undefined; // This is undefined in stories or tests
export let auth: Auth;

export function initFirebase() {
  const app = initializeApp(getFirebaseConfig());
  initAuth(app);
  firebaseAnalytics = getAnalytics();
  reportCustom("version." + getAppInfo().version);
  return app;
}

function initAuth(app: FirebaseApp) {
  if (isNativePlatform()) {
    auth = initializeAuth(app, {
      persistence: browserLocalPersistence, // This uses localStorage
    });
  } else {
    auth = getAuth(app);
  }
}

export enum LoginStatus {
  UNKNOWN,
  LOGGED_IN,
  LOGGED_OUT,
}

export function registerAuthChange(
  setLoginState: (isLoggedIn: LoginStatus) => void,
  setIsFetching: (isFetching: boolean) => void,
  setUpdateRequired: (required: boolean) => void,
  setUpdatesPopup: (updatesPopup?: FunctionsApi.UpdatesPopup) => void,
  dispatch: IDispatch,
) {
  onAuthStateChanged(auth, async (user) => {
    if (!user || !user.email) {
      clearCacheData();
      dispatch(userLoggedOut());
      setLoginState(LoginStatus.LOGGED_OUT);
      setIsFetching(false);

      // Check version for unauthenticated users
      const checkVersionResponse = await UserFunctions.checkVersion();
      if (checkVersionResponse.appUpdateRequired) {
        setUpdateRequired(true);
      }
      return;
    }

    const email = user.email;

    setAnalyticsUserId(user.uid);
    setEmailInReportingLibrary(email);
    setIsFetching(true);
    setLoginState(LoginStatus.LOGGED_IN);

    const setData =
      (skipCacheUpdate: boolean) =>
      (
        user: IUser,
        items: IItemsMap,
        updatesPopup?: FunctionsApi.UpdatesPopup,
      ) => {
        dispatch(updateUserFromDb(user, email, true));
        dispatch(setItems(items, true));
        setUpdatesPopup(updatesPopup);
        setIsFetching(false);
        if (!skipCacheUpdate) {
          setCacheData(user, items);
        }
      };

    let existingPushToken: string | undefined;
    if (isNewUser(user)) {
      const name =
        user.displayName ||
        localStorage.getItem(NEW_USER_NAME_LOCAL_STORAGE_KEY);
      const createUserResponse = await UserFunctions.createUser(name);
      setData(false)(
        createUserResponse.user,
        createUserResponse.items,
        createUserResponse.popup,
      );
      localStorage.removeItem(NEW_USER_NAME_LOCAL_STORAGE_KEY);
    } else {
      loadFromCache(setData(true));
      existingPushToken = await UserFunctions.loadInitialData(
        () => signOut(dispatch),
        setUpdateRequired,
        setData(false),
      );
    }

    initPushNotifications(existingPushToken);
  });
}

function setEmailInReportingLibrary(email: string) {
  Sentry.setUser({
    email,
  });
}

function isNewUser(user: User) {
  const creationTime = new Date(user.metadata.creationTime!);

  return new Date().getTime() - creationTime.getTime() < 20_000;
}
