export type ImageSize = {
  height: number;
  width: number;
};

const RESIZED_IMAGE_MAX_WIDTH = 1024;

export function getResizedImageSizes(
  maxWidth: number,
  originalWidth: number,
  originalHeight: number,
): ImageSize {
  if (originalWidth <= maxWidth) {
    return {
      width: originalWidth,
      height: originalHeight,
    };
  }

  const newWidth = maxWidth;
  const newHeight = newWidth * (originalHeight / originalWidth);
  return {
    width: newWidth,
    height: newHeight,
  };
}

// Resize source - https://embed.plnkr.co/oyaVFn/
export function encodeImageUri(imageUri: string, callback: any) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = new Image();
  img.onload = function () {
    const aux: any = this;

    const sizes = getResizedImageSizes(
      RESIZED_IMAGE_MAX_WIDTH,
      aux.width,
      aux.height,
    );
    canvas.width = sizes.width;
    canvas.height = sizes.height;
    ctx!.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      0,
      0,
      canvas.width,
      canvas.height,
    );
    const dataURL = canvas.toDataURL("image/jpeg");
    callback(dataURL);
  };
  img.src = imageUri;
}
