import { useContext, useEffect } from "react";
import {
  CategoryKey,
  ICategoryPreview,
  IItem,
  FunctionsApi,
} from "@warranty-keeper/common";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, getUserName } from "../../store/user/Selectors";
import { getCategoryItemsCount } from "../../store/items/Selectors";
import { getCategoryPreview } from "../../utils/CategoryUtil";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/Routes";
import { addCategory } from "../../store/user/actions/AddCategoryAction";
import { deleteCategory } from "../../store/user/actions/DeleteCategoryAction";
import { Theme, ThemeContext } from "../../theme/ThemeHelper";
import HomeScreen from "./HomeScreen";
import { getAllItems } from "../../store/items/Selectors";
import { getDateFormat, getSortingMethod } from "../../store/user/Selectors";
import { App as CapacitorApp } from "@capacitor/app";
import { registerBackHandler } from "../../utils/BackUtils";
import { reportClick } from "../../firebase/FirebaseAnalytics";
import { getAppInfo } from "../../firebase/UserFunctions";
import {
  APP_STORE_LINK,
  PLAY_STORE_LINK,
} from "../update/UpdateAppScreenContainer";
import { logWarning } from "../../utils/ErrorUtils";
import { signOut } from "../../firebase/FirebaseAuthentication";

interface HomeScreenContainerProps {
  updatesPopup?: FunctionsApi.UpdatesPopup;
  // TODO verify this still woks - closes the popup
  closeUpdatesPopup: (updates?: FunctionsApi.UpdatesPopup) => void;
}

export default function HomeScreenContainer(props: HomeScreenContainerProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const allItems = useSelector(getAllItems);
  const sortingMethod = useSelector(getSortingMethod);
  const userName = useSelector(getUserName);
  const dateFormat = useSelector(getDateFormat);
  const categories = useSelector(getCategories);
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const itemsCountByCategory = useSelector(getCategoryItemsCount);

  useEffect(() => {
    return registerBackHandler(() => CapacitorApp.exitApp());
  }, []);

  const onClickOnItem = (item: IItem) => {
    navigate(AppRoutes.Item + "/" + item.id);
    reportClick("homeScreen/item");
  };

  const categoryPreview = categories.map<ICategoryPreview>(
    (categoryKey, index) =>
      getCategoryPreview(
        categoryKey,
        itemsCountByCategory(categoryKey),
        index,
        theme === Theme.DARK,
      ),
  );

  const onAddCategory = (
    categoryKey: CategoryKey,
    onSuccess: () => void,
    onError: (m: string) => void,
  ) => dispatch(addCategory(categoryKey, onSuccess, onError));

  const rateApp = async () => {
    const appInfo = getAppInfo();
    reportClick("rateApp." + appInfo.os);
    switch (appInfo.os) {
      case "ios":
        window.open(APP_STORE_LINK);
        break;
      case "web":
      case "android":
        window.open(PLAY_STORE_LINK);
        break;
      default:
        logWarning("Unexpected os for rating: " + appInfo.os);
    }
  };

  return (
    <HomeScreen
      route={location.pathname}
      navigate={navigate}
      userName={userName}
      items={allItems}
      sortingMethod={sortingMethod}
      dateFormat={dateFormat}
      updatesPopup={props.updatesPopup}
      closeUpdatesPopup={props.closeUpdatesPopup}
      onAddItem={() => navigate(AppRoutes.NewItem)}
      onClickOnItem={onClickOnItem}
      categories={categoryPreview}
      onAddCategory={onAddCategory}
      onClickCategory={(categoryKey: CategoryKey) =>
        navigate(AppRoutes.Category + "/" + categoryKey)
      }
      onDeleteCategory={(categoryKey) => dispatch(deleteCategory(categoryKey))}
      rateApp={rateApp}
      onSignOut={() => signOut(dispatch)}
    />
  );
}
