import styles from "./Illustration.module.scss";
import { useContext } from "react";
import { Theme, ThemeContext } from "../../../theme/ThemeHelper";
import classNames from "classnames";

export interface IllustrationProps {
  lightPath: string;
  darkPath: string;
  className?: string;
  onClick?: () => void;
}

export default function Illustration(props: IllustrationProps) {
  const { theme } = useContext(ThemeContext);

  const image = theme === Theme.DARK ? props.darkPath : props.lightPath;

  return (
    <div
      className={classNames(styles.illustration, props.className)}
      onClick={props.onClick}
    >
      <img src={image} alt={"illustration"} />
    </div>
  );
}
