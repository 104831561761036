import { CategoryKey } from "@warranty-keeper/common";
import { getUser } from "../Selectors";
import { logWarning } from "../../../utils/ErrorUtils";
import * as UserFunctions from "../../../firebase/UserFunctions";
import { updateUserCategories } from "./AddCategoryAction";
import { IDispatch, IGetState } from "../../../types";
import { reportCustom } from "../../../firebase/FirebaseAnalytics";

export function deleteCategory(categoryKey: CategoryKey) {
  return function (dispatch: IDispatch, getState: IGetState) {
    const categories = getUser(getState()).categories;
    if (categories.length === 1) {
      logWarning("User tried deleting last category");
      return;
    }

    const newCategories = categories.filter((c) => c !== categoryKey);
    if (newCategories.length === categories.length) {
      logWarning("User tried deleting a category that does not exist");
      return;
    }

    dispatch(updateUserCategories(newCategories));
    UserFunctions.updateUser({
      updatedCategories: newCategories,
    });
    reportCustom(`deletedCategory`);
  };
}
