"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getItemImageName = exports.getLegacyItemImageName = exports.getPdfFullFileName = exports.getImageFullFileName = exports.getFileName = exports.getFileFullPath = void 0;
const types_1 = require("../types");
function getFileFullPath(email, fileName, fileType) {
    return `${email}/${getFileName(fileName, fileType)}`;
}
exports.getFileFullPath = getFileFullPath;
function getFileName(fileName, fileType) {
    switch (fileType) {
        case types_1.FileType.Image:
            return (0, exports.getImageFullFileName)(fileName);
        case types_1.FileType.PDF:
            return (0, exports.getPdfFullFileName)(fileName);
    }
}
exports.getFileName = getFileName;
const getImageFullFileName = (fileName) => fileName + ".png";
exports.getImageFullFileName = getImageFullFileName;
const getPdfFullFileName = (fileName) => fileName + ".pdf";
exports.getPdfFullFileName = getPdfFullFileName;
const getLegacyItemImageName = (itemId) => "itemImage." + itemId;
exports.getLegacyItemImageName = getLegacyItemImageName;
const getItemImageName = (itemId) => itemId + ".itemImage";
exports.getItemImageName = getItemImageName;
