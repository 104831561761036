import React from "react";
import styles from "./PickerModal.module.scss";
import {
  PICKER_OPTION_HEIGHT,
  PickerOptionData,
} from "../PickerOption/PickerOption";
import PickerOptionList from "../../picker/PickerOptionList/PickerOptionList";

export interface PickerModalProps<T> {
  open: boolean;
  onClose: () => void;
  options: PickerOptionData<T>[];
  selected?: PickerOptionData<T>;
  onSelected: (selectedOption: PickerOptionData<T>) => void;
  heightNumberOfRows?: number; // If present, fixes the height of the modal
  header?: React.ReactNode;
  noOptionsText?: string;
  hideDivider?: boolean;
}

export default function PickerModal<T>(props: PickerModalProps<T>) {
  let height = getModalHeight(props);

  return (
    <>
      {props.open && (
        <div
          className={styles.modalBackground}
          onClick={() => props.onClose()}
        />
      )}

      <div className={styles.modal} style={{ height: props.open ? height : 0 }}>
        {props.header && (
          <div className={styles.modalHeader}>
            {props.header}
            {!props.hideDivider && <div className={styles.divider} />}
          </div>
        )}

        <div className={styles.optionsList}>
          <PickerOptionList
            options={props.options}
            onSelected={(option) => {
              props.onSelected(option);
              props.onClose();
            }}
            selected={props.selected}
            noOptionsText={props.noOptionsText}
          />
        </div>
      </div>
    </>
  );
}

function getModalHeight<T>(props: PickerModalProps<T>) {
  let height: number;
  if (props.heightNumberOfRows) {
    height = props.heightNumberOfRows * PICKER_OPTION_HEIGHT;
  } else if (props.options.length > 0) {
    height = props.options.length * PICKER_OPTION_HEIGHT;
  } else {
    height = PICKER_OPTION_HEIGHT;
  }

  if (props.header) {
    height += PICKER_OPTION_HEIGHT;
  }
  return height;
}
