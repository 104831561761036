import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemById } from "../../store/items/Selectors";
import { useNavigate, useParams } from "react-router-dom";
import ItemScreen from "./ItemScreen";
import { AppRoutes } from "../../router/Routes";
import { deleteItem } from "../../store/items/actions/DeleteItemAction";
import { RawMediaFile } from "../../utils/MediaFileUtils";
import { addReceiptToItem } from "../../store/items/actions/AddReceiptToItemAction";
import { getDateFormat } from "../../store/user/Selectors";

export default function ItemScreenContainer() {
  const { itemId } = useParams<{ itemId: string }>();
  const item = useSelector(getItemById)(itemId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateFormat = useSelector(getDateFormat);

  if (!itemId || !item) {
    navigate(-1);
    return null;
  }

  const onDeleteItem = () => {
    dispatch(deleteItem(itemId)); // TODO verify this goes back
  };

  const addReceipt = (rawMediaFile: RawMediaFile | undefined) => {
    if (!rawMediaFile) {
      return;
    }

    dispatch(addReceiptToItem(itemId, rawMediaFile));
  };

  return (
    <ItemScreen
      item={item}
      dateFormat={dateFormat}
      onDeleteItem={onDeleteItem}
      onEdit={() => navigate(AppRoutes.EditItem + "/" + itemId)}
      onViewReceiptButton={() => navigate(AppRoutes.Receipt + "/" + itemId)}
      onAddReceipt={addReceipt}
      onItemImageClicked={() => navigate(AppRoutes.ItemImage + "/" + itemId)}
    />
  );
}
