import { useSelector } from "react-redux";
import { getItemById } from "../../store/items/Selectors";
import { useNavigate, useParams } from "react-router-dom";
import ItemImageScreen from "./ItemImageScreen";

export default function ItemImageScreenContainer() {
  const { itemId } = useParams<{ itemId: string }>();
  const item = useSelector(getItemById)(itemId);
  const navigate = useNavigate();

  if (!itemId || !item) {
    navigate(-1);
    return null;
  }

  return <ItemImageScreen item={item} />;
}
