import { useState } from "react";
import styles from "./FacebookRecoverScreen.module.scss";
import authStyles from "./Authentication.module.css";
import { Input } from "../../components/basic/Input";
import Button from "../../components/basic/Button";
import Screen from "../../components/basic/Screen/Screen";
import { ReactComponent as EnvelopeIcon } from "../../assets/icons/envelope.svg";
import Illustration from "../../components/basic/Illustration/Illustration";
import LockLight from "../../assets/illustrations/lock-light.svg";
import LockDark from "../../assets/illustrations/lock-dark.svg";
import ResetEmailSuccessLight from "../../assets/illustrations/reset-password-success-light.svg";
import ResetEmailSuccessDark from "../../assets/illustrations/reset-password-success-dark.svg";
import { translate } from "../../locale/Locale";

export interface FacebookRecoverScreenProps {
  onRevocerFacebookAccount: (
    email: string,
    onSuccess: () => void,
    setError: (message: string) => void,
  ) => void;
  goBack: () => void;
}

export default function FacebookHelpScreen(props: FacebookRecoverScreenProps) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onEmailError = (emailError: string) => {
    setError(emailError);
    setIsLoading(false);
    setSuccess(false);
  };

  const onSuccess = () => {
    setIsLoading(false);
    setSuccess(true);
  };

  const onRecoverAccount = () => {
    const e = email.trim();
    if (!e) {
      onEmailError(translate("authenticationErrors.invalidEmail"));
      return;
    }

    setIsLoading(true);
    props.onRevocerFacebookAccount(e, onSuccess, onEmailError);
  };

  if (success) {
    return (
      <div className={styles.successScreenContainer}>
        <Illustration
          lightPath={ResetEmailSuccessLight}
          darkPath={ResetEmailSuccessDark}
        />

        <div className={styles.successTitle}>
          {translate("signIn.checkEmail")}
        </div>
        <div className={styles.successSubtitle}>
          {translate("facebookHelp.weSentInstructions")}
        </div>

        <Button
          className={styles.continueButton}
          title={translate("common.goBack")}
          onClick={props.goBack}
        />
      </div>
    );
  }

  return (
    <Screen headerTitle={translate("error.oops")}>
      <div className={authStyles.screenContainer}>
        <div className={styles.subtitle}>
          {translate("facebookHelp.explanation")}
        </div>

        <div className={styles.image}>
          <Illustration lightPath={LockLight} darkPath={LockDark} />
        </div>

        <div className={styles.subtitle}>
          {translate("facebookHelp.emailInstructions")}
        </div>
        <div className={styles.content}>
          <Input
            label={translate("common.email")}
            value={email}
            onChangeText={(email: string) => {
              setEmail(email);
              setError("");
              setSuccess(false);
            }}
            error={error}
            onSubmit={onRecoverAccount}
            type="email"
            icon={<EnvelopeIcon className={styles.icon} />}
          />

          <Button
            title={translate("common.send")}
            onClick={onRecoverAccount}
            loading={isLoading}
            analyticTag={"recoverFacebookAccount"}
          />
        </div>
      </div>
    </Screen>
  );
}
