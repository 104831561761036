import AboutScreen from "./AboutScreen";
import packageJson from "../../../package.json";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/Routes";

export default function AboutScreenContainer() {
  const navigate = useNavigate();
  return (
    <AboutScreen
      version={packageJson.version}
      onShowOpenSourceCredits={() => navigate(AppRoutes.Credits)}
    />
  );
}
