import { getPlatform } from "../firebase/UserFunctions";
import { App as CapacitorApp } from "@capacitor/app";

export function registerBackHandler(onBack: () => void) {
  if (getPlatform() === "android") {
    CapacitorApp.addListener("backButton", onBack);
    return () => {
      CapacitorApp.removeAllListeners();
    };
  } else {
    return () => {};
  }
}
