import _ from "lodash";
import { getItemsMap } from "../Selectors";
import * as ItemFunctions from "../../../firebase/ItemFunctions";
import { setItems } from "./ItemsMapActions";
import { IDispatch, IGetState } from "../../../types";

export function deleteItem(itemId: string) {
  return async function (dispatch: IDispatch, getState: IGetState) {
    const itemsMap = getItemsMap(getState());
    const updatedItems = _.omit(itemsMap, itemId);
    dispatch(setItems(updatedItems));

    await ItemFunctions.deleteItem(itemId);
  };
}
