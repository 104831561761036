import {
  applyMiddleware,
  combineReducers,
  createStore as reduxCreateStore,
} from "redux";
import thunkMiddleware from "redux-thunk";
import items from "./items/Reducer";
import user from "./user/Reducer";

const combinedReducers = combineReducers({
  items,
  user,
});

export const store = reduxCreateStore(
  combinedReducers,
  applyMiddleware(thunkMiddleware),
);
