import { useNavigate } from "react-router-dom";
import ResetPasswordScreen from "./ResetPasswordScreen";
import { resetPassword } from "../../firebase/FirebaseAuthentication";

export default function ResetPasswordScreenContainer() {
  const navigate = useNavigate();

  return (
    <ResetPasswordScreen
      goBack={() => navigate(-1)}
      onResetPassword={resetPassword}
    />
  );
}
