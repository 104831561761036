import React from "react";
import styles from "./PickerOptionList.module.scss";
import PickerOption, {
  PickerOptionData,
  PickerOptionMode,
} from "../PickerOption/PickerOption";

export interface PickerOptionListProps<T> {
  options: PickerOptionData<T>[];
  onSelected: (option: PickerOptionData<T>) => void;
  selected?: PickerOptionData<T>;
  noOptionsText?: string;
  mode?: PickerOptionMode.V | PickerOptionMode.RADIO;
  extraPadding?: boolean;
}

export default function PickerOptionList<T>(props: PickerOptionListProps<T>) {
  if (props.options.length === 0) {
    return <div className={styles.noOptions}>{props.noOptionsText}</div>;
  }

  return (
    <div className={styles.options}>
      {props.options.map((option) => (
        <PickerOption
          key={option.key}
          option={option}
          onSelected={() => props.onSelected(option)}
          selected={option.value === props.selected?.value}
          mode={props.mode}
          extraPadding={props.extraPadding}
        />
      ))}
    </div>
  );
}
