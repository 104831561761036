import React from "react";
import styles from "./style.module.scss";
import classNames from "classnames";

type InputWrapperProps = {
  children: React.ReactNode;
  label?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  error?: string;
  extraLabel?: string;
  showBigLabel?: boolean;
};

export default function InputWrapper({
  children,
  label,
  startIcon,
  endIcon,
  error,
  extraLabel,
  showBigLabel,
}: InputWrapperProps) {
  return (
    <div className={styles.inputContainer}>
      <div
        className={classNames({
          [styles.inputContent]: true,
          [styles.inputBorder]: true,
          [styles.inputBorderError]: !!error,
        })}
      >
        {startIcon && <div className={styles.startIcon}>{startIcon}</div>}

        <div className={styles.labelAndContent}>
          <div
            className={classNames({
              [styles.label]: true,
              [styles.bigLowerLabel]: showBigLabel,
            })}
          >
            {label}
          </div>

          {children}
          {endIcon && <div> {endIcon}</div>}
        </div>
      </div>

      <div className={classNames(styles.bottomText)}>{extraLabel}</div>

      <div className={classNames(styles.bottomText, styles.error)}>{error}</div>
    </div>
  );
}
