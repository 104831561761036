export enum AuthenticationRoutes {
  SignIn = "/signIn",
  EmailSignIn = "/emailSignIn",
  Register = "/register",
  ResetPassword = "/resetPassword",
  FacebookHelp = "/facebookAccountRecover",
}

export enum AppRoutes {
  Loading = "loading",

  Home = "/",
  AllItems = "/allItems",
  Category = "/category",
  Categories = "/categories",
  Search = "/search",
  Item = "/item",
  ItemImage = "/image",
  Receipt = "/receipt",
  EditItem = "/edit",
  NewItem = "/new",
  Success = "/success",
  Settings = "/settings",
  Account = "/account",
  GeneralSettings = "/general",
  Language = "/language",
  Notifications = "/notifications",
  Feedback = "/feedback",
  ExportData = "/export",
  About = "/about",
  UpdateApp = "/updateApp",
  Credits = "/credits",
}
