import classnames from "classnames";
import { AppRoutes } from "../../../router/Routes";
import styles from "./Tabs.module.scss";

export interface TabsProps {
  route: AppRoutes;
  tabs: { title: string; route: AppRoutes; children: React.ReactNode }[];
  onTabPressed: (route: AppRoutes) => void;
}

export default function Tabs(props: TabsProps) {
  let selectedTab = props.tabs.filter((x) => x.route === props.route)[0];
  if (!selectedTab) {
    selectedTab = props.tabs[0];
  }

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {props.tabs.map((tab) => (
          <div
            key={tab.route}
            className={classnames(
              styles.tab,
              tab.route === selectedTab.route && styles.selectedTab,
            )}
            onClick={() => props.onTabPressed(tab.route)}
          >
            {tab.title}

            <div
              className={classnames(
                styles.border,
                tab.route === selectedTab.route && styles.selectedTabBorder,
              )}
            />
          </div>
        ))}
      </div>
      <div className={styles.tabContent}>
        {/* Internal div is needed to make sure the content is not wider than the screen */}
        <div className={styles.tabInnerContent}>{selectedTab.children}</div>
      </div>
    </div>
  );
}
