import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

export enum MediaFileMethod {
  SEARCH_ONLINE = "SEARCH_ONLINE",
  TAKE_PHOTO = "TAKE_PHOTO",
  UPLOAD_IMAGE = "UPLOAD_IMAGE",
  UPLOAD_PDF = "UPLOAD_PDF",
  PREVIOUSLY_SELECTED = "PREVIOUSLY_SELECTED",
}

export type RawMediaFile = {
  localUri: string;
  method: MediaFileMethod;
};

export async function getRawImageFile(
  method: MediaFileMethod,
): Promise<RawMediaFile | undefined> {
  switch (method) {
    case MediaFileMethod.PREVIOUSLY_SELECTED:
    case MediaFileMethod.SEARCH_ONLINE:
      break;

    case MediaFileMethod.TAKE_PHOTO:
    case MediaFileMethod.UPLOAD_IMAGE:
      const uri = await getImage(method);
      if (!uri) {
        return undefined;
      }

      return {
        localUri: uri,
        method: method,
      };

    case MediaFileMethod.UPLOAD_PDF:
      throw Error("Unsupported method in raw media file picker");
  }

  return undefined;
}

async function getImage(
  method: MediaFileMethod.TAKE_PHOTO | MediaFileMethod.UPLOAD_IMAGE,
): Promise<string | undefined> {
  try {
    let source: CameraSource;

    const permissions = await Camera.checkPermissions();
    if (method === MediaFileMethod.TAKE_PHOTO) {
      source = CameraSource.Camera;
      if (permissions.camera !== "granted") {
        await Camera.requestPermissions();
      }
    } else {
      source = CameraSource.Photos;
      if (permissions.photos !== "granted") {
        await Camera.requestPermissions();
      }
    }

    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: source,
      allowEditing: true,
    });

    return cameraPhoto.webPath;
  } catch (e) {
    console.error("Error getting image by", method, e);
    return undefined;
  }
}
