import React from "react";
import styles from "./Toggle.module.css";
import classNames from "classnames";

export interface ToggleProps {
  enabled: boolean;
  onChange: (newValue: boolean) => void;
}

export default function Toggle(props: ToggleProps) {
  return (
    <div
      onTouchEnd={(e) => {
        props.onChange(!props.enabled);
        // This will stop the parent div to also handle the onClick
        e.preventDefault();
      }}
      className={classNames({
        [styles.toggleContainer]: true,
        [styles.toggleContainerEnabled]: props.enabled,
      })}
    >
      <div
        className={classNames({
          [styles.toggleCircle]: true,
          [styles.toggleEnabled]: props.enabled,
        })}
      />
    </div>
  );
}
