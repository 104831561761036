import { useState } from "react";
import Screen from "../../components/basic/Screen/Screen";
import styles from "./ExportDataScreen.module.css";
import Button from "../../components/basic/Button";
import { Variant } from "@warranty-keeper/common";
import Illustration from "../../components/basic/Illustration/Illustration";
import Popup from "../../components/basic/Popup";
import ExportDataLight from "../../assets/illustrations/export-data-light.svg";
import ExportDataDark from "../../assets/illustrations/export-data-dark.svg";
import ExportSuccessLight from "../../assets/illustrations/export-success-light.svg";
import ExportSuccessDark from "../../assets/illustrations/export-success-dark.svg";
import { Input } from "../../components/basic/Input";
import {
  isPrivateAppleUser,
  isValidEmail,
} from "../../firebase/AuthenticationUtils";
import { translate } from "../../locale/Locale";

export interface ExportDataScreenProps {
  email: string;
  sendExportDataEmail: (curatedEmail?: string) => void;
  goBack: () => void;
}

export default function ExportDataScreen(props: ExportDataScreenProps) {
  const [open, setOpen] = useState(false);
  const [curatedEmail, setCuratedEmail] = useState("");

  const isPrivateEmail = isPrivateAppleUser(props.email);

  const buttonEnabled = !isPrivateEmail || isValidEmail(curatedEmail.trim());

  return (
    <Screen headerTitle={translate("exportData.exportData")}>
      <div>
        <div className={styles.title}>{translate("exportData.subtitle")}</div>

        <Illustration
          lightPath={ExportDataLight}
          darkPath={ExportDataDark}
          className={styles.illustration}
        />

        {isPrivateEmail ? (
          <Input
            value={curatedEmail}
            onChangeText={setCuratedEmail}
            label={translate("common.myEmail")}
          />
        ) : (
          <div className={styles.email}>
            {translate("common.email")}:{" "}
            <span className={styles.emailAddress}>{props.email}</span>
          </div>
        )}

        <Button
          title={translate("exportData.emailMe")}
          variant={Variant.Primary}
          onClick={() => {
            props.sendExportDataEmail(curatedEmail.trim());
            setOpen(true);
          }}
          disabled={!buttonEnabled}
          analyticTag={"exportData"}
        />

        <Popup
          open={open}
          title={translate("exportData.emailSentTitle")}
          content={translate("exportData.emailSentContent")}
          onClose={props.goBack}
          illustration={
            <Illustration
              lightPath={ExportSuccessLight}
              darkPath={ExportSuccessDark}
              className={styles.popupIllustration}
            />
          }
          buttons={[
            {
              label: translate("saveSuccess.okButton"),
              onClick: props.goBack,
              variant: Variant.Secondary,
            },
          ]}
        />
      </div>
    </Screen>
  );
}
