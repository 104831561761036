import React, { useContext } from "react";
import LanguageScreen from "./LanguageScreen";
import { LocaleContext } from "../../locale/LocaleContext";
import { useNavigate } from "react-router";

export default function LanguageScreenContainer() {
  const [locale, setLocale] = useContext(LocaleContext);
  const navigate = useNavigate();
  return (
    <LanguageScreen
      locale={locale}
      onLanguageChange={(newLocale) => {
        setLocale(newLocale);
        navigate(-1);
      }}
      onHelpTranslate={() => window.open("https://forms.gle/skhhc2e7LBJCuvnL8")}
    />
  );
}
