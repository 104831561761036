"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecoverFacebookAccountFunction = exports.SendFeedbackFunction = exports.ExportDataFunction = exports.UpdateUserFunction = exports.UpdatePushTokenFunction = exports.CreateNewUserFunction = exports.SearchOnlineImagesFunction = exports.RemoveReceiptFunction = exports.AddReceiptFunction = exports.UpdateItemFunction = exports.DeleteItemFunction = exports.CreateItemFunction = exports.DeleteAccountFunctionName = exports.LoadInitialDataFunction = exports.CheckVersionFunction = void 0;
exports.CheckVersionFunction = "checkVersion";
exports.LoadInitialDataFunction = "loadInitialData";
exports.DeleteAccountFunctionName = "deleteAccount";
/*
 * Items
 */
exports.CreateItemFunction = "createItem";
exports.DeleteItemFunction = "deleteItem";
exports.UpdateItemFunction = "updateItem";
exports.AddReceiptFunction = "addReceipt";
exports.RemoveReceiptFunction = "removeReceipt";
exports.SearchOnlineImagesFunction = "searchOnlineImages";
/*
 * User
 */
exports.CreateNewUserFunction = "createNewUser";
exports.UpdatePushTokenFunction = "updatePushToken";
exports.UpdateUserFunction = "updateUser";
exports.ExportDataFunction = "exportData";
exports.SendFeedbackFunction = "sendFeedback";
exports.RecoverFacebookAccountFunction = "recoverFacebookAccount";
