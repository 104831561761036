import React from "react";
import styles from "./Header.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../assets/icons/chevron-left.svg";
import IconButton from "../IconButton";
import { IconColorField } from "../IconButton/IconButton";
import classNames from "classnames";

export interface HeaderProps {
  title?: string;
  hideBack?: boolean;
  onBack?: () => void;
  rightButton?: React.ReactNode;
  leftButton?: React.ReactNode;
}

export default function Header(props: HeaderProps) {
  const navigate = useNavigate();

  if (!props.title) {
    return null;
  }

  return (
    <div className={styles.header}>
      {props.leftButton ? (
        props.leftButton
      ) : props.hideBack ? (
        <div />
      ) : (
        <IconButton
          testId={"headerBack"}
          icon={BackIcon}
          className={classNames(styles.button)}
          onClick={props.onBack ? props.onBack : () => navigate(-1)}
          iconColorField={IconColorField.STROKE}
        />
      )}

      <div className={styles.titleContainer}>
        <div className={styles.title}>{props.title}</div>
      </div>

      {props.rightButton ? (
        <div className={classNames(styles.button, styles.headerButton)}>
          {props.rightButton}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
