import { useState } from "react";
import styles from "./CustomImage.module.css";
import classNames from "classnames";
import Spinner from "../Spinner/Spinner";
import { ReactComponent as MissingImageIcon } from "../../../assets/icons/missing-image.svg";

export interface CustomImageProps {
  imageUrl: string;
  isLoading?: boolean;
  onClick?: () => void;
  className?: string;
  testId?: string;
}

export const CustomImage: React.FC<CustomImageProps> = (props) => {
  const [fetchingImage, setFetchingImage] = useState(true);
  const [error, setError] = useState(false);

  if (!props.imageUrl || error) {
    return (
      <div
        className={styles.imageContainer}
        onClick={props.onClick}
        data-test-id={props.testId}
      >
        <div
          className={classNames(props.className, styles.missingImage)}
          data-test-id="missing-image"
        >
          <MissingImageIcon className={styles.missingImageIcon} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.imageContainer} onClick={props.onClick}>
      <img
        alt={""}
        className={classNames(props.className, styles.image)}
        src={props.imageUrl}
        onLoad={() => setFetchingImage(false)}
        onError={() => {
          setError(true);
          setFetchingImage(false);
        }}
        referrerPolicy={"no-referrer"} // Without this some images return error 403
        data-test-id={props.testId}
      />
      <Overlay fetchingImage={fetchingImage} {...props} />
    </div>
  );
};

function Overlay(props: CustomImageProps & { fetchingImage: boolean }) {
  if (props.isLoading || props.fetchingImage) {
    return (
      <div className={classNames(props.className, styles.overlay)}>
        <Spinner className={styles.spinner} />
      </div>
    );
  }

  return null;
}
