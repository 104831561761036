import { useContext, useState } from "react";
import styles from "./CategoryPreview.module.scss";
import {
  CategoryKey,
  ICategoryPreview,
  Variant,
} from "@warranty-keeper/common";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import classNames from "classnames";
import Popup from "../../basic/Popup";
import Illustration from "../../basic/Illustration/Illustration";
import DeleteCategoryLight from "../../../assets/illustrations/delete-category-light.svg";
import DeleteCategoryDark from "../../../assets/illustrations/delete-category-dark.svg";
import SwippableComponent from "../../basic/SwippableComponent/SwippableComponent";
import { reportClick } from "../../../firebase/FirebaseAnalytics";
import { LocaleContext } from "../../../locale/LocaleContext";
import { isRTL, translate } from "../../../locale/Locale";

export interface CategoryPreviewProps {
  category: ICategoryPreview;
  onClick: (category: CategoryKey) => void;
  onDelete: (category: CategoryKey) => void;
  editMode: boolean;
  startEditMode: (category: CategoryKey) => void;
  endEditMode: (category: CategoryKey) => void;
  isTheOnlyCategory: boolean;
}

export default function CategoryPreview(props: CategoryPreviewProps) {
  const [locale] = useContext(LocaleContext);

  const category = props.category;
  const deletable = !props.isTheOnlyCategory && category.itemsCount === 0;
  const onClick = props.editMode
    ? () => {}
    : () => props.onClick(category.categoryKey);

  const categoryClasses = [styles.category];
  if (!props.editMode) {
    categoryClasses.push(styles.enabledCategory);
  }

  // TODO support edit category name.
  return (
    <>
      <div className={styles.container}>
        <SwippableComponent
          className={classNames(categoryClasses)}
          onClick={onClick}
          onSwipeLeft={() =>
            isRTL(locale)
              ? props.endEditMode(category.categoryKey)
              : props.startEditMode(category.categoryKey)
          }
          onSwipeRight={() =>
            isRTL(locale)
              ? props.startEditMode(category.categoryKey)
              : props.endEditMode(category.categoryKey)
          }
        >
          <div className={styles.left}>
            <div
              className={styles.circle}
              style={{ backgroundColor: category.categoryColor }}
            />
            <div className={styles.categoryText}>{category.categoryLabel}</div>
          </div>

          {category.itemsCount > 0 && (
            <div className={styles.itemsCount}>{category.itemsCount}</div>
          )}
        </SwippableComponent>

        <DeleteCategoryButton
          editMode={props.editMode}
          deletable={deletable}
          category={category}
          onDelete={props.onDelete}
        />
      </div>

      {/* This is a hack to start edit mode from integration tests, as swiping isn't really working */}
      <div
        data-test-id={"categoryPreviewSwipe-" + category.categoryKey}
        className={styles.testButton}
        onClick={() =>
          props.editMode
            ? props.endEditMode(category.categoryKey)
            : props.startEditMode(category.categoryKey)
        }
      />
    </>
  );
}

function DeleteCategoryButton(props: {
  editMode: boolean;
  deletable: boolean;
  category: ICategoryPreview;
  onDelete: (category: CategoryKey) => void;
}) {
  const [popupVisible, setPopupVisible] = useState(false);

  const classes = {
    [styles.deleteButton]: true,
    [styles.enabledDelete]: props.deletable,
    [styles.disabledDelete]: !props.deletable,
    [styles.deleteButtonShown]: props.editMode,
  };

  const onClick = () => {
    if (props.deletable) {
      setPopupVisible(true);
    }
    reportClick("deleteCategory/" + props.category.categoryKey);
  };

  return (
    <>
      <div onClick={onClick} className={classNames(classes)}>
        <TrashIcon />
      </div>

      <Popup
        open={popupVisible}
        title={translate("common.deletingSomething", {
          something: props.category.categoryLabel,
        })}
        content={translate("common.areYouSure")}
        onClose={() => setPopupVisible(false)}
        illustration={
          <Illustration
            lightPath={DeleteCategoryLight}
            darkPath={DeleteCategoryDark}
          />
        }
        buttons={[
          {
            label: translate("common.cancel"),
            variant: Variant.Info,
            onClick: () => setPopupVisible(false),
          },
          {
            label: translate("common.delete"),
            onClick: () => props.onDelete(props.category.categoryKey),
          },
        ]}
      />
    </>
  );
}
