import React, { useEffect, useState } from "react";
import SplashScreen from "./SplashScreen";
import packageJson from "../../../package.json";

export default function SplashScreenContainer() {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (!navigator.onLine) {
        setShowError(true);
      }
    }, 1500);
  });

  return (
    <SplashScreen showNetworkError={showError} version={packageJson.version} />
  );
}
