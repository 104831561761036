import { FileType, PathUtils } from "@warranty-keeper/common";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { reportCustom } from "./FirebaseAnalytics";
import _ from "lodash";
import { encodeImageUri } from "../utils/ImageUtils";
import { auth } from "./FirebaseInitializer";

export async function uploadPdf(
  fileName: string,
  fileLocalUri: string,
): Promise<string> {
  const email = auth.currentUser?.email;
  if (!email) {
    throw Error("Invalid email");
  }

  let fileFullPath = PathUtils.getFileFullPath(email, fileName, FileType.PDF);
  const storage = getStorage();
  const fileRef = ref(storage, fileFullPath);

  const blob = await fetch(fileLocalUri).then((r) => r.blob());

  const snapshot = await uploadBytes(fileRef, blob);
  reportFileSize(snapshot.metadata.size, FileType.PDF);
  return (await getDownloadURL(snapshot.ref)) as string;
}

export async function uploadImage(
  fileName: string,
  imageURI: string,
): Promise<string> {
  const email = auth.currentUser?.email;
  if (!email) {
    throw Error("Invalid email");
  }

  let fileFullPath = PathUtils.getFileFullPath(email, fileName, FileType.Image);
  const storage = getStorage();
  const imageRef = ref(storage, fileFullPath);

  const image64 = await new Promise<string>((resolve) => {
    encodeImageUri(imageURI, resolve);
  });

  const snapshot = await uploadString(imageRef, image64, "data_url");
  reportFileSize(snapshot.metadata.size, FileType.Image);
  return (await getDownloadURL(snapshot.ref)) as string;
}

function reportFileSize(totalBytes: number, fileType: FileType) {
  const sizeKb = _.round(totalBytes / 1024);
  console.log(fileType, "file size:", sizeKb, "KB");

  const prefix = "UploadedFileSize/" + fileType + "/";

  const limit = 5000;
  const bucketSize = 300;
  for (let i = 200; i <= limit; i += bucketSize) {
    if (sizeKb < i) {
      reportCustom(prefix + i);
      return;
    }
  }

  reportCustom(prefix + "Over" + limit);
}
