import { firebaseDevConfig } from "@warranty-keeper/common";

const isProductionEnvironment = process.env.REACT_APP_PRODUCTION_FIREBASE;

export function isProduction() {
  return isProductionEnvironment === "true";
}
export function getFirebaseConfig() {
  if (isProduction()) {
    return firebaseProdConfig;
  } else {
    console.log("Using dev firebase");
    return firebaseDevConfig;
  }
}

const firebaseProdConfig = {
  apiKey: "AIzaSyDWcxrVAJwZ7ob3ZiV7vo0B3HBr1Eq0sDk",
  authDomain: "warranty-keeper.firebaseapp.com",
  databaseURL: "https://warranty-keeper.firebaseio.com",
  projectId: "warranty-keeper",
  storageBucket: "warranty-keeper.appspot.com",
  messagingSenderId: "764079413829",
  appId: "1:764079413829:web:9a9d78263ca6ab8cfca12c",
  measurementId: "G-5V58C5S9P6",
};
