import {
  CategoryKey,
  DateFormat,
  DateUtils,
  FirstDayOfWeek,
} from "@warranty-keeper/common";
import { PickerOptionData } from "../../components/picker/PickerOption/PickerOption";
import { SaveItemArguments } from "../../store/items/actions/SaveNewItemAction";
import EditItemFields from "./EditItemFields";
import { translate } from "../../locale/Locale";

export interface NewItemScreenProps {
  initialCategoryKey?: CategoryKey;
  defaultWarrantyMonths: number;
  categoryOptions: PickerOptionData<CategoryKey>[];
  dateFormat: DateFormat;
  firstDayOfWeek: FirstDayOfWeek;
  onSave: (args: SaveItemArguments) => void;
}

export default function NewItemScreen(props: NewItemScreenProps) {
  return (
    <EditItemFields
      headerTitle={translate("editItem.addNewItem")}
      name={""}
      purchaseDateMillis={DateUtils.Today().toMillis()}
      category={props.initialCategoryKey}
      warrantyMonths={props.defaultWarrantyMonths}
      itemImageUrl={undefined}
      notes={""}
      showReceiptsField={true}
      categoryOptions={props.categoryOptions}
      firstDayOfWeek={props.firstDayOfWeek}
      dateFormat={props.dateFormat}
      onSave={props.onSave}
    />
  );
}
