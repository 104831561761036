import styles from "./X.module.css";
import classNames from "classnames";
import React from "react";

interface XProps {
  onClick?: () => void;
  containerClass?: string;
  xClass?: string;
}

export default function X(props: XProps) {
  return (
    <div>
      <div
        className={classNames(
          styles.clearButtonContainer,
          props.containerClass,
        )}
        onClick={props.onClick}
      >
        <div className={classNames(styles.clearButton, props.xClass)} />
        <div
          className={classNames(
            styles.clearButton2,
            styles.clearButton,
            props.xClass,
          )}
        />
      </div>
    </div>
  );
}
