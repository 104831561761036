import { CategoryKey, IItem } from "@warranty-keeper/common";
import { useSelector } from "react-redux";
import { getItemsByCategory } from "../../store/items/Selectors";
import CategoryScreen from "./CategoryScreen";
import { useNavigate, useParams } from "react-router-dom";
import { getCategoryName } from "../../utils/CategoryUtil";
import { getDateFormat, getSortingMethod } from "../../store/user/Selectors";
import { AppRoutes } from "../../router/Routes";
import { reportClick } from "../../firebase/FirebaseAnalytics";

export default function CategoryScreenContainer() {
  const { categoryKey } = useParams<{ categoryKey: CategoryKey }>();
  const navigate = useNavigate();
  const categoryItems = useSelector(getItemsByCategory)(categoryKey);
  const sortingMethod = useSelector(getSortingMethod);
  const dateFormat = useSelector(getDateFormat);
  if (!categoryKey) {
    navigate(AppRoutes.Categories);
    return null;
  }

  const onClickOnItem = (item: IItem) => {
    navigate(AppRoutes.Item + "/" + item.id);
    reportClick(`category/${categoryKey}/item`);
  };

  return (
    <CategoryScreen
      categoryLabel={getCategoryName(categoryKey)}
      items={categoryItems}
      dateFormat={dateFormat}
      sortingMethod={sortingMethod}
      onAddItemFromCategory={() =>
        navigate(AppRoutes.NewItem + "/" + categoryKey)
      }
      onClickOnItem={onClickOnItem}
    />
  );
}
