import React, { useState } from "react";
import { CategoryKey, Variant } from "@warranty-keeper/common";
import Popup from "../../basic/Popup";
import { Input } from "../../basic/Input";
import Illustration from "../../basic/Illustration/Illustration";
import NewCategoryLight from "../../../assets/illustrations/new-category-light.svg";
import NewCategoryDark from "../../../assets/illustrations/new-category-dark.svg";
import { translate } from "../../../locale/Locale";

export interface AddCategoryPopupProps {
  open: boolean;
  close: () => void;
  addCategory: (
    categoryKey: CategoryKey,
    onSuccess: () => void,
    onError: (m: string) => void,
  ) => void;
}

export default function AddCategoryPopup(props: AddCategoryPopupProps) {
  const [categoryName, setCategoryName] = useState<CategoryKey>("");
  const [error, setError] = useState("");

  const onClose = () => {
    setCategoryName("");
    setError("");
    props.close();
  };

  const onAdd = () => {
    props.addCategory(categoryName.trim(), onClose, setError);
  };

  return (
    <Popup
      open={props.open}
      title={translate("categories.addNewCategory")}
      onClose={onClose}
      illustration={
        <Illustration lightPath={NewCategoryLight} darkPath={NewCategoryDark} />
      }
      buttons={[
        {
          label: translate("common.cancel"),
          variant: Variant.Info,
          onClick: onClose,
        },
        {
          label: translate("common.add"),
          variant: Variant.Secondary,
          onClick: onAdd,
        },
      ]}
    >
      <Input
        value={categoryName}
        onChangeText={setCategoryName}
        maxLength={30}
        onSubmit={onAdd}
        label={translate("categories.categoryName")}
        error={error}
      />
    </Popup>
  );
}
