import SocialSignInButton, { SocialSignInMethod } from "./SocialSignInButton";
import styles from "./SocialSignInButton.module.scss";
import React, { useState } from "react";

export interface SocialSignInButtonsProps {
  bigIcons?: boolean;
  onClickSocial: (
    method: SocialSignInMethod,
    setError: (message: string[]) => void,
  ) => void;
  enableAppleLogin: boolean;
}

export default function SocialSignInButtons(props: SocialSignInButtonsProps) {
  const [error, setError] = useState<string[] | undefined>();

  const onClick = (method: SocialSignInMethod) => (stopLoading: () => void) => {
    setError(undefined);
    props.onClickSocial(method, (e) => {
      setError(e);
      stopLoading();
    });
  };

  return (
    <div>
      <div className={styles.buttonsRow}>
        <SocialSignInButton
          method={SocialSignInMethod.Google}
          onClick={onClick(SocialSignInMethod.Google)}
          bigIcon={props.bigIcons}
        />
        {props.enableAppleLogin && (
          <SocialSignInButton
            method={SocialSignInMethod.Apple}
            onClick={onClick(SocialSignInMethod.Apple)}
            bigIcon={props.bigIcons}
          />
        )}
      </div>
      <div className={styles.error}>
        {error?.map((e) => <div key={e}>{e}</div>)}
      </div>
    </div>
  );
}
