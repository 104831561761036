import Popup from "../basic/Popup";
import Illustration from "../basic/Illustration/Illustration";
import DeleteReceiptLight from "../../assets/illustrations/delete-receipt-light.svg";
import DeleteReceiptDark from "../../assets/illustrations/delete-receipt-dark.svg";
import { Variant } from "@warranty-keeper/common";
import { translate } from "../../locale/Locale";

export interface DeleteReceiptPopupProps {
  popupOpen: boolean;
  setPopupOpen: (open: boolean) => void;
  itemName: string;
  onDelete: () => void;
}

export default function DeleteReceiptPopup(props: DeleteReceiptPopupProps) {
  return (
    <Popup
      open={props.popupOpen}
      title={translate("receipt.deletingReceipt", {
        itemName: props.itemName,
      })}
      content={translate("common.areYouSure")}
      illustration={
        <Illustration
          lightPath={DeleteReceiptLight}
          darkPath={DeleteReceiptDark}
        />
      }
      buttons={[
        {
          label: translate("common.cancel"),
          variant: Variant.Info,
          onClick: () => props.setPopupOpen(false),
        },
        {
          label: translate("common.delete"),
          variant: Variant.Primary,
          onClick: props.onDelete,
        },
      ]}
      onClose={() => props.setPopupOpen(false)}
    />
  );
}
