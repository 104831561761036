import React from "react";
import styles from "./Spinner.module.css";
import classNames from "classnames";

export interface SpinnerProps {
  className?: string;
}

export default function Spinner(props: SpinnerProps) {
  return (
    <div className={classNames(styles.ldsRing, props.className)}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
