import { MediaFileMethod, RawMediaFile } from "./MediaFileUtils";
import * as Storage from "../firebase/Storage";
import { PathUtils } from "@warranty-keeper/common";

export async function getItemImageUrl(
  newId: string,
  itemImage: RawMediaFile | undefined,
): Promise<string> {
  if (!itemImage) {
    return "";
  }

  switch (itemImage.method) {
    case MediaFileMethod.SEARCH_ONLINE:
    case MediaFileMethod.PREVIOUSLY_SELECTED:
      return itemImage.localUri;

    case MediaFileMethod.TAKE_PHOTO:
    case MediaFileMethod.UPLOAD_IMAGE:
      return await Storage.uploadImage(
        PathUtils.getItemImageName(newId),
        itemImage.localUri,
      );

    case MediaFileMethod.UPLOAD_PDF:
  }
  throw Error("Can't set item image as " + itemImage.method);
}
