import InputWrapper from "../../basic/Input/InputWrapper";
import { ReactComponent as AddIcon } from "../../../assets/icons/circle-plus.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import styles from "./MediaField.module.scss";
import { useEffect, useState } from "react";
import {
  PickerOptionData,
  PickerOptionMode,
} from "../../picker/PickerOption/PickerOption";
import { MediaFileMethod, RawMediaFile } from "../../../utils/MediaFileUtils";
import _ from "lodash";
import IconButton from "../../basic/IconButton";
import Popup from "../../basic/Popup";
import MultiplePickerOptionList from "../../picker/MultiplePickerOptionList/MultiplePickerOptionList";
import { Variant } from "@warranty-keeper/common";
import { IconColorField } from "../../basic/IconButton/IconButton";
import MediaPickerModal from "./MediaPickerModal";
import { translate } from "../../../locale/Locale";

export interface MediaFieldProps {
  label: string;
  value: RawMediaFile[];
  setValue: (newValue: RawMediaFile[]) => void;
  maxItems?: number;
  hintText?: string; // Probably the item name, to be used by the online image selector
  methods: MediaFileMethod[];
}

export default function MediaField(props: MediaFieldProps) {
  const [error, setError] = useState("");
  const [methodPickerOpen, setMethodPickerOpen] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const hasPdfFilePicked = !!_.find(
    props.value,
    (x) => x.method === MediaFileMethod.UPLOAD_PDF,
  );

  // A second item can only be an image
  const methods =
    props.value.length > 0
      ? props.methods.filter((x) => x !== MediaFileMethod.UPLOAD_PDF)
      : props.methods;
  const maxItems = props.maxItems || 1;

  const onAdd = () => {
    if (props.value.length === maxItems || hasPdfFilePicked) {
      return;
    }
    setMethodPickerOpen(true);
  };

  const onDelete = () => {
    if (props.value.length === 1) {
      props.setValue([]);
      return;
    }

    setShowDeletePopup(true);
  };

  const addFile = (localFile: RawMediaFile | undefined) => {
    // User may cancel
    if (localFile) {
      props.setValue([...props.value, localFile]);
    }
  };

  return (
    <>
      <InputWrapper
        label={props.label}
        endIcon={getIcons(
          hasPdfFilePicked,
          props.value.length,
          maxItems,
          onAdd,
          onDelete,
        )}
        showBigLabel={props.value.length === 0}
        error={error}
      >
        <div className={styles.imageFieldInput} onClick={onAdd}>
          {getContentString(props.value, hasPdfFilePicked)}
        </div>
      </InputWrapper>

      {/*Method picker*/}
      <MediaPickerModal
        open={methodPickerOpen}
        onClose={() => setMethodPickerOpen(false)}
        methods={methods}
        onLocalFilePicked={addFile}
        setError={setError}
        hintText={props.hintText}
      />

      {/*Delete picker*/}
      <DeleteFilesPopup
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        files={props.value}
        onDelete={props.setValue}
      />
    </>
  );
}

function getContentString(value: RawMediaFile[], hasPdfFilePicked: boolean) {
  if (value.length === 0) {
    return "";
  }

  if (hasPdfFilePicked) {
    return translate("filePicker.file", { count: 1 });
  }

  return translate("filePicker.image", { count: value.length });
}

function getIcons(
  hasPdfFilePicked: boolean,
  itemsCount: number,
  maxItems: number,
  onAdd: () => void,
  onDelete: () => void,
) {
  if (itemsCount === 0) {
    return (
      <IconButton
        icon={AddIcon}
        selected={true}
        onClick={onAdd}
        iconColorField={IconColorField.FILL}
        analyticTag={"addMedia"}
        testId={"addMedia"}
      />
    );
  }
  if (hasPdfFilePicked || itemsCount === maxItems) {
    return (
      <IconButton
        icon={TrashIcon}
        selected={true}
        onClick={onDelete}
        iconColorField={IconColorField.STROKE}
        analyticTag={"removeMedia"}
        testId={"removeMedia"}
      />
    );
  }

  return (
    <div className={styles.iconRow}>
      <IconButton
        icon={AddIcon}
        selected={true}
        onClick={onAdd}
        iconColorField={IconColorField.FILL}
        analyticTag={"addMedia"}
      />
      <IconButton
        icon={TrashIcon}
        selected={true}
        onClick={onDelete}
        iconColorField={IconColorField.STROKE}
        analyticTag={"removeMedia"}
      />
    </div>
  );
}

function DeleteFilesPopup(props: {
  open: boolean;
  onClose: () => void;
  files: RawMediaFile[];
  onDelete: (remainingFiles: RawMediaFile[]) => void;
}) {
  const [selectedIndex, setSelectedIndex] = useState<number[]>([]);

  // Clear checked when closing popup
  useEffect(() => {
    if (!props.open) {
      setSelectedIndex([]);
    }
  }, [props.open, setSelectedIndex]);

  const toOption = (
    file: RawMediaFile,
    index: number,
  ): PickerOptionData<number> => ({
    text: translate("filePicker.fileNumber", { number: index + 1 }),
    key: index + file.localUri,
    value: index,
  });

  return (
    <Popup
      open={props.open}
      onClose={props.onClose}
      title={translate("filePicker.removeFiles")}
      buttons={[
        {
          label: translate("common.cancel"),
          onClick: props.onClose,
          variant: Variant.Info,
        },
        {
          label: translate("common.delete"),
          variant: Variant.Primary,
          onClick: () => {
            props.onClose();
            props.onDelete(
              props.files.filter(
                (file, index) => !selectedIndex.includes(index),
              ),
            );
          },
        },
      ]}
    >
      <MultiplePickerOptionList
        options={props.files.map(toOption)}
        selectedIndices={selectedIndex}
        setSelectedIndices={setSelectedIndex}
        mode={PickerOptionMode.CHECKBOX}
        noPadding
      />
    </Popup>
  );
}
