import React from "react";
import styles from "./Popup.module.css";
import { Variant } from "@warranty-keeper/common";
import Button from "../Button";
import X from "../X/X";

export type PopupProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  content?: string;
  buttons: PopupButton[];
  illustration?: React.ReactNode;
  children?: React.ReactNode;
};

export type PopupButton = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  variant?: Variant;
  analyticTag?: string;
};

export default function Popup(props: PopupProps) {
  if (!props.open) {
    return null;
  }

  return (
    <div className={styles.modalBackground} onClick={props.onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalContent}>
          <div className={styles.closeButton}>
            <X onClick={props.onClose} />
          </div>
          {props.illustration && (
            <div className={styles.illustration}>{props.illustration}</div>
          )}
          <div className={styles.title}>{props.title}</div>
          {props.content && (
            <div className={styles.content}>{props.content}</div>
          )}

          {props.children && (
            <div className={styles.children}>{props.children}</div>
          )}

          <div className={styles.buttons}>
            {props.buttons.map((button, index) => (
              <Button
                key={button.label + index}
                title={button.label}
                onClick={button.onClick}
                className={styles.button}
                variant={button.variant}
                analyticTag={button.analyticTag}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
