import { useNavigate } from "react-router-dom";
import RegisterScreen from "./RegisterScreen";
import { registerUser } from "../../firebase/FirebaseAuthentication";
import {
  appleLoginSupported,
  socialSignIn,
} from "../../firebase/SocialAuthentication";
import { useContext } from "react";
import { LocaleContext } from "../../locale/LocaleContext";
import { AuthenticationRoutes } from "../../router/Routes";

export default function RegisterScreenContainer() {
  const navigate = useNavigate();
  const [locale] = useContext(LocaleContext);

  return (
    <RegisterScreen
      goToSignIn={() =>
        navigate(AuthenticationRoutes.EmailSignIn, { replace: true })
      }
      onRegister={registerUser}
      onClickSocial={(method, setError) =>
        socialSignIn(method, setError, locale)
      }
      enableAppleLogin={appleLoginSupported()}
    />
  );
}
