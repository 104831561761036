import styles from "./CategoryScreen.module.css";
import { DateFormat, IItem, SortMethod } from "@warranty-keeper/common";
import Screen from "../../components/basic/Screen/Screen";
import ItemList from "../../components/item/ItemList/ItemList";
import Illustration from "../../components/basic/Illustration/Illustration";
import NoItemsLight from "../../assets/illustrations/no-items-in-category-light.svg";
import NoItemsDark from "../../assets/illustrations/no-items-in-category-dark.svg";
import { reportClick } from "../../firebase/FirebaseAnalytics";
import Fab from "../../components/basic/Fab/Fab";
import { translate } from "../../locale/Locale";

export interface CategoryScreenProps {
  items: IItem[];
  dateFormat: DateFormat;
  categoryLabel: string;
  onAddItemFromCategory: () => void;
  sortingMethod: SortMethod;
  onClickOnItem: (item: IItem) => void;
}

export default function CategoryScreen(props: CategoryScreenProps) {
  const noItems = props.items.length === 0;

  return (
    <Screen headerTitle={props.categoryLabel}>
      <div className={styles.content}>
        {noItems ? (
          <div
            className={styles.noItems}
            onClick={() => {
              props.onAddItemFromCategory();
              reportClick("emptyCategory/addItemFromBackground");
            }}
          >
            <div>
              <Illustration lightPath={NoItemsLight} darkPath={NoItemsDark} />
              <div>
                {translate("categories.noItems", {
                  categoryName: props.categoryLabel,
                })}
              </div>
              <div>{translate("allItems.addItemsTip")}</div>
            </div>
          </div>
        ) : (
          <ItemList
            sortingMethod={props.sortingMethod}
            items={props.items}
            dateFormat={props.dateFormat}
            onClickOnItem={props.onClickOnItem}
          />
        )}
      </div>

      <Fab onClick={props.onAddItemFromCategory} />
    </Screen>
  );
}
