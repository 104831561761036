import { useState } from "react";
import { CustomImage } from "../../components/basic/CustomImage";
import styles from "./ReceiptScreen.module.css";
import Screen from "../../components/basic/Screen/Screen";
import { FileType, IReceipt } from "@warranty-keeper/common";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/circle-plus.svg";
import IconButton from "../../components/basic/IconButton";
import { IconColorField } from "../../components/basic/IconButton/IconButton";
import Stepper from "../../components/basic/Stepper/Stepper";
import { MediaFileMethod, RawMediaFile } from "../../utils/MediaFileUtils";
import { Icon } from "../../utils/IconUtils";
import MediaPickerModal from "../../components/item/MediaField/MediaPickerModal";
import classNames from "classnames";
import DeleteReceiptPopup from "../../components/DeleteReceiptPopup/DeleteReceiptPopup";
import DownloadPdfLight from "../../assets/illustrations/download-pdf-light.svg";
import DownloadPdfDark from "../../assets/illustrations/download-pdf-dark.svg";
import Illustration from "../../components/basic/Illustration/Illustration";
import { translate } from "../../locale/Locale";

export interface ReceiptScreenProps {
  itemName: string;
  receipts: IReceipt[];
  onDelete: (receipt: IReceipt) => void;
  onDownload: (receipt: IReceipt) => void;
  onAddAnotherReceipt: (file: RawMediaFile) => void;
}

export const MAX_RECEIPTS_PER_ITEM = 4;

export default function ReceiptScreen(props: ReceiptScreenProps) {
  const [methodPickerOpen, setMethodPickerOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [error, setError] = useState("");
  const receipt = props.receipts[index];

  // This can happen right after deleting a receipt, before .goBack() takes action
  if (!receipt?.receiptUrl) {
    return null;
  }

  const onAddAnother = async (rawMediaFile: RawMediaFile | undefined) => {
    if (!rawMediaFile) {
      return;
    }
    props.onAddAnotherReceipt(rawMediaFile);
  };

  return (
    <Screen headerTitle={translate("item.receipt")} noPadding noBottomPadding>
      <div className={styles.container}>
        {receipt.receiptType === FileType.PDF ? (
          <Illustration
            lightPath={DownloadPdfLight}
            darkPath={DownloadPdfDark}
            className={styles.downloadPdfIllustration}
            onClick={() => props.onDownload(receipt)}
          />
        ) : (
          <TransformWrapper>
            <TransformComponent
              wrapperClass={styles.transformerWrapper}
              contentStyle={{ padding: 20 }}
            >
              <CustomImage
                imageUrl={receipt.receiptUrl}
                className={styles.image}
              />
            </TransformComponent>
          </TransformWrapper>
        )}

        {props.receipts.length > 1 && (
          <div className={styles.stepper}>
            <Stepper
              steps={props.receipts.length}
              activeStep={index}
              onNext={() => setIndex(index + 1)}
              onPrevious={() => setIndex(index - 1)}
            />
          </div>
        )}

        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.actionButtons}>
          <ActionButton
            disabled={
              receipt.receiptType === FileType.PDF ||
              props.receipts.length >= MAX_RECEIPTS_PER_ITEM
            }
            Icon={PlusIcon}
            iconColorField={IconColorField.FILL}
            label={translate("receipt.addAnother")}
            onClick={() => setMethodPickerOpen(true)}
            analyticTag="addAnother"
          />
          <ActionButton
            Icon={DownloadIcon}
            iconColorField={IconColorField.STROKE}
            label={translate("common.download")}
            onClick={() => props.onDownload(receipt)}
            analyticTag="download"
          />
          <ActionButton
            Icon={TrashIcon}
            iconColorField={IconColorField.STROKE}
            label={translate("common.delete")}
            onClick={() => setPopupOpen(true)}
            analyticTag="delete"
          />
        </div>

        <DeleteReceiptPopup
          popupOpen={popupOpen}
          setPopupOpen={setPopupOpen}
          itemName={props.itemName}
          onDelete={() => {
            setPopupOpen(false);
            if (index > 0) setIndex(index - 1);
            props.onDelete(receipt);
          }}
        />

        <MediaPickerModal
          open={methodPickerOpen}
          onClose={() => setMethodPickerOpen(false)}
          methods={[MediaFileMethod.TAKE_PHOTO, MediaFileMethod.UPLOAD_IMAGE]}
          onLocalFilePicked={onAddAnother}
          setError={setError}
        />
      </div>
    </Screen>
  );
}

function ActionButton(props: {
  Icon: Icon;
  iconColorField: IconColorField;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  analyticTag: string;
}) {
  const classes = [styles.actionButton];
  if (props.disabled) {
    classes.push(styles.disabledActionButton);
  } else {
    classes.push(styles.activeActionButton);
  }
  return (
    <div
      className={classNames(classes)}
      onClick={props.disabled ? undefined : props.onClick}
    >
      <div className={styles.icon}>
        <IconButton
          icon={props.Icon}
          onClick={props.onClick}
          iconColorField={props.iconColorField}
          disabled={props.disabled}
          analyticTag={"receipt/" + props.analyticTag}
        />
      </div>
      <div>{props.label}</div>
    </div>
  );
}
