import styles from "./SignInScreen.module.scss";
import Screen from "../../components/basic/Screen/Screen";
import { SocialSignInMethod } from "../../components/SocialSignIn/SocialSignInButton";
import Button from "../../components/basic/Button";
import { Variant } from "@warranty-keeper/common";
import Illustration from "../../components/basic/Illustration/Illustration";
import SignInLight from "../../assets/illustrations/sign-in-light.svg";
import SignInDark from "../../assets/illustrations/sign-in-dark.svg";
import classNames from "classnames";
import SocialSignInButtons from "../../components/SocialSignIn/SocialSignInButtons";
import Divider from "../../components/basic/Divider/Divider";
import { translate } from "../../locale/Locale";
import TextButton from "../../components/basic/TextButton";
import PickerModal from "../../components/picker/PickerModal/PickerModal";
import { useState } from "react";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook-sign-in.svg";
import { IconColorField } from "../../components/basic/IconButton/IconButton";
import { ReactComponent as ContactIcon } from "../../assets/icons/feedback.svg";

export interface SignInScreenProps {
  isProd: boolean;
  onClickSocial: (
    method: SocialSignInMethod,
    setError: (message: string[]) => void,
  ) => void;
  goToSignInWithEmail: () => void;
  goToRegister: () => void;
  gotToFacebookHelpPage: () => void;
  contactSupport: () => void;
  enableAppleLogin: boolean;
}

export default function SignInScreen(props: SignInScreenProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const welcomeClasses = [styles.welcomeText];
  if (!props.isProd) {
    welcomeClasses.push(styles.welcomeTextDev);
  }

  return (
    <Screen
      stickyComponent={
        <div className={classNames(welcomeClasses)}>
          <div>{translate("signIn.welcome")}</div>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.subtitle}>{translate("signIn.subtitle")}</div>

        <div className={styles.illustration}>
          <Illustration lightPath={SignInLight} darkPath={SignInDark} />
        </div>

        <div className={styles.signInWith}>
          {translate("signIn.signInWith")}:
        </div>
        <SocialSignInButtons
          onClickSocial={props.onClickSocial}
          enableAppleLogin={props.enableAppleLogin}
        />

        <Divider text={translate("common.or")} className={styles.divider} />

        <div className={styles.buttonsContainer}>
          <Button
            title={translate("signIn.login")}
            onClick={props.goToSignInWithEmail}
            variant={Variant.Secondary}
            className={styles.button}
            analyticTag={"signIn/login"}
          />
          <Button
            title={translate("signIn.signUp")}
            onClick={props.goToRegister}
            variant={Variant.Primary}
            className={styles.button}
            analyticTag={"signIn/signUp"}
          />
          <TextButton
            title={translate("signIn.help")}
            onClick={() => setModalOpen(true)}
            variant={Variant.Info}
            // analyticTag={"signIn/signUp"}
          />
          <PickerModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            options={[
              {
                text: translate("signIn.contact"),
                key: "contact",
                value: "contact",
                icon: ContactIcon,
                iconColorField: IconColorField.STROKE,
              },
              {
                text: translate("signIn.cantLoginWithFacebook"),
                key: "facebook",
                value: "facebook",
                icon: FacebookIcon,
                iconColorField: IconColorField.FILL,
              },
            ]}
            onSelected={(selected) => {
              switch (selected.value) {
                case "contact":
                  props.contactSupport();
                  return;
                case "facebook":
                  props.gotToFacebookHelpPage();
                  return;
              }
            }}
          />
        </div>
      </div>
    </Screen>
  );
}
