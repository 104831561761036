import { getDirection, readLocaleFromMemory, selectLocale } from "./Locale";
import React, { useEffect, useState } from "react";
import { LocaleContext } from "./LocaleContext";

export default function LocaledComponent(props: {
  externalLocale?: string;
  children: React.ReactNode;
}) {
  const [locale, setLocale] = useState(readLocaleFromMemory());
  useEffect(() => {
    if (props.externalLocale) {
      setLocale(props.externalLocale);
      selectLocale(props.externalLocale, true);
    }
  }, [setLocale, props.externalLocale]);

  const onLocaleChange = (newLocale: string) => {
    if (locale !== newLocale) {
      setLocale(newLocale);
      selectLocale(newLocale, false);
    }
  };

  const direction = getDirection(locale);

  return (
    <div dir={direction}>
      <LocaleContext.Provider value={[locale, onLocaleChange]}>
        {props.children}
      </LocaleContext.Provider>
    </div>
  );
}
